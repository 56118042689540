export const USER_ROLES = {
  CRM_TEAM: 1,
  LD: 2,
  REPAIR_TEAM: 3,
  ADMIN: 4,
  SUPER_ADMIN: 5
};


export const TEAM_STATUS = {
  CUSTOMER_RELATIONSHIP_MANAGEMENT_TEAM: 1,
  LOGISTIC_DEPARTMENT_TEAM: 2,
  REPAIR_TEAM: 3,
};

export const actions = {
  SIGN_IN: 'SIGN_IN',
  SET_USER: 'SET_USER',
  SIGN_IN_SIMS: 'SIGN_IN_SIMS',
  SET_USER_SIMS: 'SET_USER_SIMS',
  SIGN_OUT: 'SIGN_OUT',
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  UPDATE_USER_DATA: 'UPDATE_USER_DATA',
  SET_USER_LAST_READ_NOTIFICATION: 'SET_USER_LAST_READ_NOTIFICATION',
  CALL_SNACKBAR_DATA: "CALL_SNACKBAR_DATA",
  SET_NOTIFICATION_DATA: "SET_NOTIFICATION_DATA",
  NOTIFICATION_READ: "NOTIFICATION_READ",

  FETCH_DATA_STATUS_LODING: "FETCH_DATA_STATUS_LODING",
  FETCH_DATA_STATUS_DATA: "FETCH_DATA_STATUS_DATA",
  FETCH_DATA_STATUS_Filters: "FETCH_DATA_STATUS_Filters",
  FETCH_DATA_COUNT_STATUS_Filters: "FETCH_DATA_COUNT_STATUS_Filters",


}

export const NOTIFICATION_TYPE = {
  general: "general",
  lead: "lead",
};

export const SNACK_BAR_VARIETNS = {
  error: "error",
  suceess: "success",
  info: "info",
  warning: "warning",
};

export const DAY_WEEK_MONTH = {
  DAY: "day",
  WEEK: "week"
}


export const SOLVED_STATUS = [{ label: "inPending", value: 0 }, { label: "inProcess", value: 1 }, { label: "inCompleted", value: 2 }]

export const SOLVED_STATUS_LABLE = {
  "inPending": 0,
  "inProcess": 1,
  "inCompleted": 2
}

export const PROBLEMS = [{ label: "Device not switching on", value: 0 }, { label: "Lower sound of any side of OX", value: 1 }, { label: "Connectivity issue", value: 2 }, { label: "Mic not working", value: 3 }, { label: "Beep sound", value: 4 }, { label: "Battery discharging", value: 5 }, { label: "Battery ", value: 6 }, { label: "Other", value: 7 }]

export const PROBLEMS_LABLE = { "Device not switching on": 0, "Lower sound of any side of OX": 1, "Connectivity issue": 2, "Mic not working": 3, "Beep sound": 4, "Battery discharging": 5, "Battery ": 6, "Other": 7 }

export const SOLUTIONS = {
  CALL: 1,
  Videao_Call: 2,
  REPAIR: 3,
}

export const CANCEL_PAYMENT = {
  YES: 1,
  NO: 2,
}

export const DEVICES_BACK = {
  YES: "YES",
  NO: "NO"
}

export const REPLACEMENT_BOX = {
  NEW_BOX: "NEW_BOX",
  HEAR_NU_BAND: "HEAR_NU_BAND",
  HEAD_SET: "HEAD_SET"
}
export const USER_LOG = {
  CUSTOMER_INFORMATION: 1,
  USER_LOGS: 2
}
export const STATUS = {
  YET_TO_RECEIVED: 1,
  EVALUTION: 2,
  SEND_TO_CRM: 3,
  START_EVALUTION: 4,
  SEND_TO_REPAIR: 5,
  WAITING_FOR_PAYMENT: 6,
  NOT_DISPATCH: 7,
  DISPATCH: 8,
  REPAIR_START: 9,
  COMPLETED: 10,
  REPAIR_COMPLETED: 11,
  REPLACEMENT: 12,
  DEVICE_RECEIVED: 13,
  PAYMENT_RECEIVED: 14,
  REPAIR_COMPLETE: 15,
  REPAIR_REPLACEMENT: 16,
  REPLACEMENT_COMPLETE: 17,
  DEMO: 18
}

export const BUTTON_TYPE = {
  pending: 1,
  Work_In_Progress: 2,
  Solved: 3,
}


export const PAYMENT_METHOD = {
  PAYMENT_RECEIVED: "PAYMENT_RECEIVED",
  CANCEL_PAYMENT: "CANCEL_PAYMENT",
  RETURN_DEVICE: "RETURN_DEVICE"
}



export const STATUS_COLOR_TYPE = (currentstatus) => {

  switch (currentstatus) {
    case true:
      return "#1D013B"
    default:
      return "white"
  }
}

export const BUTTON_STATUS_COLOR_TYPE = (currentstatus) => {

  switch (currentstatus) {
    case true:
      return "contained"
    default:
      return "outlined"
  }
}