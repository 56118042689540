import { Box, Button, ButtonGroup, Grid, IconButton, Paper, Skeleton, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import AddIcon from '@mui/icons-material/Add';
import DataTable from '../../components/tables/DataTable';
import CustomDialog from '../../components/layouts/common/CustomDialog';
import { FilterTitleBox, FiltersBox } from '../../components/layouts/OneViewBox';
import PaddingBoxInDesktop from '../../components/layouts/PaddingBoxDesktop';
import { useDispatch } from 'react-redux';
import { openModal } from '../../store/actions/modalAction';

import AsyncSearchBar from '../../components/inputs/AsyncSearchBar';
import { BUTTON_TYPE, STATUS } from '../../utils/constants';
import ButtonComponentsUi from './ButtonComponentsUi';
import { titleCase } from '../../utils/main';

const DashboardPageUI = ({ loadingCount, Count, CountStatus, setSTATUSWiseData, STATUSWiseData, filters, setFilters, createTiket, setList, loading, list, columns, setAdminstatusButton, AdminstatusButton }) => {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"))
  const issmSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"))

  return (
    <>
      <Box  >
        <Paper sx={{ width: "100%", height: "85vh", overflow: isSmallScreen ? "auto" : "hidden", padding: 6 }}>
          <Box   >
            <FilterTitleBox >
              <Box mt={2} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} width={"100%"}>
                <Box >
                  <Typography variant="h3" color={"primary"}>
                    Dashboard
                  </Typography>
                </Box>

              </Box>
            </FilterTitleBox>
          </Box>

          {loadingCount ?
            <Box mt={4} sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', height: "15vh" }}>
              {[0, 1, 2, 3].map((row) => (
                <Skeleton
                  key={row}
                  variant="rectangular"
                  width={!issmSmallScreen ? "24%" : "24%"}
                  height={120}
                  sx={{ borderRadius: "8px", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}
                />
              ))}
            </Box>
            : <Grid mt={4} md={12} xs={12} sm={12} container>
              <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", flexDirection: !issmSmallScreen ? "row" : "column" }}>
                <Box width={!issmSmallScreen ? "24%" : "100%"} >

                  <ButtonComponentsUi onSubmit={() => { setAdminstatusButton(BUTTON_TYPE.pending); }}
                    count={((CountStatus?.response[0]?.count ?? 0) + (CountStatus?.response[2]?.count ?? 0) ?? 0)} STATUSTypeData={BUTTON_TYPE.pending} colorType={AdminstatusButton == BUTTON_TYPE.pending} />
                </Box>
                <Box width={!issmSmallScreen ? "24%" : "100%"} >


                  <ButtonComponentsUi onSubmit={() => { setAdminstatusButton(BUTTON_TYPE.Work_In_Progress); }}
                    count={((CountStatus?.response[1]?.count ?? 0) + (CountStatus?.response[4]?.count ?? 0) + (CountStatus?.response[7]?.count ?? 0) + (CountStatus?.response[5]?.count ?? 0) ?? 0)} STATUSTypeData={BUTTON_TYPE.Work_In_Progress} colorType={AdminstatusButton == BUTTON_TYPE.Work_In_Progress} />
                </Box>
                <Box width={!issmSmallScreen ? "24%" : "100%"} >


                  <ButtonComponentsUi onSubmit={() => { setAdminstatusButton(BUTTON_TYPE.Solved); }}
                    count={((CountStatus?.response[6]?.count ?? 0) + (CountStatus?.response[15]?.count ?? 0) + (CountStatus?.response[14]?.count ?? 0) + (CountStatus?.response[9]?.count ?? 0) ?? 0)}
                    STATUSTypeData={BUTTON_TYPE.Solved} colorType={AdminstatusButton == BUTTON_TYPE.Solved} />
                </Box>

                <Box width={!issmSmallScreen ? "24%" : "100%"} >

                  <ButtonComponentsUi
                    count={((CountStatus?.response[0]?.count ?? 0) + (CountStatus?.response[2]?.count ?? 0) + (CountStatus?.response[15]?.count ?? 0) + (CountStatus?.response[14]?.count ?? 0) + (CountStatus?.response[3]?.count ?? 0) + (CountStatus?.response[1]?.count ?? 0) + (CountStatus?.response[4]?.count ?? 0) + (CountStatus?.response[5]?.count ?? 0) + (CountStatus?.response[7]?.count ?? 0) + (CountStatus?.response[6]?.count ?? 0) + (CountStatus?.response[9]?.count ?? 0) ?? 0)}
                    Total={"Total"}
                    STATUSTypeData
                  />
                </Box>
              </Box>
            </Grid>}


          <Box mt={4} sx={{ width: "100%", display: "flex", justifyContent: "flex-end", flexDirection: !isSmallScreen ? "row" : "column" }}>

            <PaddingBoxInDesktop
              my={3}
              sx={{ display: "flex", width: !issmSmallScreen ? "20%" : !isSmallScreen ? "50%" : "100%" }}
              pl={3}>
              <AsyncSearchBar
                fullWidth
                title="Search By Name "
                size="small"
                placeholder={"Search By Name"}
                defaultValue={filters.search}
                onChange={(changedVal) => {
                  setFilters({ ...filters, search: changedVal });
                }}
              />
            </PaddingBoxInDesktop>
          </Box>
          <Box overflow={"auto"} height={"60vh"}>
            <DataTable key={AdminstatusButton} customRows={true} columns={columns} rows={list?.result ? list?.result : []} count={list?.total ?? 0} filters={filters} setFilters={setFilters} loading={loading} />
          </Box>
        </Paper >
      </Box >


    </>
  )
}

export default DashboardPageUI