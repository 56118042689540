import { Box, Button, IconButton, Paper, Typography } from '@mui/material'
import React from 'react'
import AddIcon from '@mui/icons-material/Add';
import DataTable from '../../components/tables/DataTable';
import CustomDialog from '../../components/layouts/common/CustomDialog';
import { FilterTitleBox, FiltersBox } from '../../components/layouts/OneViewBox';
// import PaddingBoxInDesktop from '../../components/layouts/PaddingBoxDesktop';
// import AsyncSearchBar from '../../components/inputs/AsyncSearchBar';
import { useDispatch } from 'react-redux';
import { openModal } from '../../store/actions/modalAction';
import AsyncSearchBar from '../../components/inputs/AsyncSearchBar';
import PaddingBoxInDesktop from '../../components/layouts/PaddingBoxDesktop';
import CreateProblemController from './CreateProblemController';


const ProblemListUi = ({ columns, list, filters, setFilters, loading,callBack }) => {
    const dispatch = useDispatch()
    const id = 'problem';
    const onCreateBtnClick = () => {
        dispatch(openModal(
            <CreateProblemController
                callBack={callBack}
            />, "sm", false, id))
    }
    return (
        <Box mb={3}>
            <Paper elevation={2} sx={{ width: "100%", padding: 6 }}>
                <Box mb={4} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"flex-end"}>
                    <FilterTitleBox>
                        <Box mt={2} >
                            <Typography variant="h3" color={"#000"}>
                                {/* {title} */}Problem List
                            </Typography>
                        </Box>

                        <Box mb={2} sx={{ display: "flex" }}>
                            <Box mr={2} />
                        </Box>
                        <Box>
                            <Button onClick={onCreateBtnClick} sx={{ width: "100%", height: "6vh", display: "flex", alignItems: "center", justifyContent: "center" }} variant='contained'>
                                <AddIcon /> &nbsp;
                                <Typography variant='h5' sx={{ display: "flex" }}>
                                    Add Problem
                                </Typography>
                            </Button>
                        </Box>
                    </FilterTitleBox>

                    <FiltersBox mt={4}>
                        <PaddingBoxInDesktop
                            mb={4}
                            sx={{ display: "flex", width: "20vw", justifyContent: "flex-end" }}
                            pl={3}
                        >
                            <AsyncSearchBar
                                fullWidth
                                title="Search By Problem Name "
                                size="small"
                                placeholder={"Search By Problem Name"}
                                defaultValue={filters.search}
                                onChange={(changedVal) => {
                                    setFilters({ ...filters, search: changedVal });
                                }}
                            />
                        </PaddingBoxInDesktop>
                    </FiltersBox>
                </Box>

                <Box sx={{ minHeight: "40vh" }}>
                    <DataTable columns={columns} rows={list?.result ? list?.result : []} count={list?.total ?? 0} filters={filters} setFilters={setFilters} loading={loading} />
                </Box>
            </Paper>
        </Box>)
}

export default ProblemListUi