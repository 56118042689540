
import { memo, useEffect, useLayoutEffect, useMemo, useState } from "react";
import useValidate from "../../store/hooks/useValidator";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { closeModal } from "../../store/actions/modalAction";
import { useParams } from "react-router-dom";
import { PROBLEMS_LABLE, SNACK_BAR_VARIETNS, SOLUTIONS, STATUS, USER_ROLES } from "../../utils/constants";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { CreateRepairingTicketApi, getRepairingTicketStatusByIdApi, RepairingTicketCreateApi, RepairingUpdateTicketApi, RepairingTicketStatusCountChangeApi } from "../../apis/repairing.api";
import axios from "axios";
import UpdateCreateQueryUi from "./UpdateCreateQueryUi";
import { statusActionFilters, statusCountActionFilters } from "../../store/actions/statusAction";
import { FetchProblemApi } from "../../apis/problem.api";
import { fetchNotificationAction } from "../../store/actions/notificationAction";

const UpdateCreateQueryController = ({ statusPass, fieldTicket, setTickets, id }) => {
  const [fields, setFields] = useState({
    id: id,
    err: "",
    createdAt: fieldTicket?.createdAt ? fieldTicket?.createdAt : "",
    ticket_no: fieldTicket?.ticketNo ? fieldTicket?.ticketNo : "",
    product_id: "",
    box_id: "",
    customer_phone: "",
    customer_name: "",
    customer_email: "",
    source_id: "",
    trakingId: "",
    problems: [],
    solved_in: "",
    customer_address: "",
    device_is: "",
    repairable_part: "",
    repairable_remarks: "",
    replace_remarks: "",
    other: "",
    conclusion: "",
    physical_damage: {
      damage_part: "",
      repairing_amount: null,
      remarks: ""
    },
    problem_solve_by_crm: {
      type_of_problem: "",
      remarks: ""
    },
    repairing_amount_status: "",
    repairing_amount: null,
    });

  const [validation, setvalidation] = useState(false);

  const [loading, setLoading] = useState(false);
  const validate = useValidate();
  const dispatch = useDispatch();
  const modalKey = 'Query';
  const title = "Query";
  const [arrr, setArrr] = useState([])
  console.log(" ")
  const validationSchemaForUpdate = useMemo(
    () => [
      {
        required: true,
        value: fields.ticket_no,
        field: "ticket_no",
      },
      {
        required: true,
        value: fields.createdAt,
        field: "createdAt",
      },
      {
        required: true,
        value: fields.product_id,
        field: "product_id",
      },
      {
        // required: true,
        value: fields.customer_email,
        field: "Email",
      },
      {
        // required: true,
        value: fields.box_id,
        field: "Box Id",
      },
      {
        required: true,
        value: fields.customer_address,
        field: "customer_address",
      },
      {
        // required: true,
        value: fields.other,
        field: "other",
      },
      {
        required: true,
        value: fields.customer_phone,
        field: "Phone",
      },
      {
        required: true,
        value: fields.customer_name,
        field: "Customer Name",
      },
      {
        required: true,
        value: fields.source_id,
        field: "Source",
      },
      {
        required: true,
        value: fields.problems,
        field: "Problems",
      },
      {
        // required: true,
        value: fields.solved_in,
        field: "solved_in",
      },
      {
        // required: true,
        value: fields.conclusion,
        field: "conclusion",
      },
      {
        required: statusPass === STATUS.REPAIR_START && true,
        value: fields.device_is,
        field: "device_is",
      },
      {
        required: validation && true,
        value: fields.repairing_amount,
        field: "repairing amount",
      },
      {
        required: (statusPass === STATUS.REPAIR_START && fields.device_is === "Repairable") && true,
        value: fields?.repairable_part,
        field: "repairable_part",
      },
      {
        required: (statusPass === STATUS.REPAIR_START && fields.device_is === "Repairable") && true,
        value: fields?.repairable_remarks,
        field: "repairable_remarks",
      },
      {
        required: (statusPass === STATUS.REPAIR_START && fields.device_is === "Replace") && true,
        value: fields?.replace_remarks,
        field: "replace_remarks",
      },

      {
        required: (statusPass === STATUS.REPAIR_START && fields.device_is === "physical_damage") && true,
        value: fields.physical_damage.damage_part,
        field: "damage_part",
      },

      {
        required: (statusPass === STATUS.REPAIR_START && fields.device_is === "physical_damage") && true,
        value: fields.physical_damage.repairing_amount,
        field: "repairing_amount",
      },
      {
        required: (statusPass === STATUS.REPAIR_START && fields.device_is === "physical_damage") && true,
        value: fields.physical_damage.remarks,
        field: " physical_damage remarks",
      },
      // {
      //   required: fields.conclusion === "problem_solved_By_CRM" && true,
      //   value: fields.problem_solve_by_crm.type_of_problem,
      //   field: "name",
      // },
      // {
      //   required: fields.conclusion === "problem_solved_By_CRM" && true,
      //   value: fields.problem_solve_by_crm.remarks,
      //   field: "problem_solve_by_crm remarks",
      // },
      // {
      //   required: statusPass === STATUS.WAITING_FOR_PAYMENT && true,
      //   value: fields.repairing_amount_status,
      //   field: "repairing_amount_status",
      // },

    ],
    [fields]
  );
console.log("first fields repair" ,fields)
  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ['customer_name'],
    role: "",
    sort: "createdAt",
    sortDirection: -1,

    // ...statusData.statusReducer.filters
  });
  const onSubmit = async (e) => {
    e.preventDefault();
    const validationResponse = validate(validationSchemaForUpdate)
    if (validationResponse === true) {
      setLoading(true)
      dispatch(callApiAction(
        async () => await RepairingUpdateTicketApi({ ...fields, product_id: fields.product_id?._id, source_id: fields.source_id?._id }),
        (response) => {

          dispatch(fetchNotificationAction());
          dispatch(closeModal("UpdateQuery"))
          dispatch(callSnackBar("Updated successfully", SNACK_BAR_VARIETNS.suceess));
          dispatch(statusActionFilters(filters));
          dispatch(statusCountActionFilters(-1));
          setLoading(false)
        }, (err) => {
          setFields({ ...fields, err });
          setLoading(false);
        }
      ))
    } else {
      setFields({ ...fields, err: validationResponse })
      setLoading(false)
    }
  };

  const fetchById = (id) => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await getRepairingTicketStatusByIdApi({ id }),
        async (response) => {
          setFields({ ...fields, ...response });

          setLoading(false);
        },
        (err) => {
          setFields({ ...fields, err });
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    if (id) fetchById(id);
  }, [id]);

  const [listProblem, setlistProblem] = useState()

  const fetchList = () => {
    // setLoading(true);
    dispatch(
      callApiAction(
        async () => await FetchProblemApi({ ...filters }),
        (response) => {
          setlistProblem(response?.result);
          // setLoading(false);
        },
        (err) => {
          // setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchList()
  }, [filters])

{console.log("repairing_amount ",validation)}
  return (
    <UpdateCreateQueryUi
      statusPass={statusPass}
      listProblem={listProblem}
      // isRolePredefined={isRolePredefined}
      modalKey={modalKey}
      title={title}
      // isUpdate={id}
      loading={loading}
      fields={fields}
      onSubmit={onSubmit}
      setFields={setFields}
      validation={validation}
      setvalidation={setvalidation}
    />
  );
};
export default memo(UpdateCreateQueryController);
