import { memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, openModal } from "../../store/actions/modalAction";
import { callApiAction } from "../../store/actions/commonAction";
import { Delete, Edit } from "@mui/icons-material";
import { Box, Chip, IconButton, Typography } from "@mui/material";

import MessageDilog from "../../components/texts/MessageDilog";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { SNACK_BAR_VARIETNS } from "../../utils/constants";
import ProductAccessoriesListUi from "./ProductAccessoriesListUi";
import CreateProductAccessoriesController from "./CreateProductAccessoriesController";
import { DeleteProductAccessoriesApi, getProductAccessoriesApi } from "../../apis/productAccessories.api";

const ActionComponent = memo(({ params, setParams, deleteApi, callBack }) => {
  const dispatch = useDispatch();
  const modalkey = "delete";
  const [loading, setLoading] = useState(false);

  const onEdit = () => {
    dispatch(
      openModal(
        <CreateProductAccessoriesController
          id={params._id}
          // callBack={(response, updatedData) => {
          //   setParams({ ...params, ...updatedData });
          // }}
          callBack={callBack}
        />,
        "sm",
        false,
        "update"
      )
    );
  };

  const deleteFun = async (e) => {
    e.preventDefault()
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await deleteApi({ id: params._id }),
        (response) => {
          setParams({});
          setLoading(false);
          dispatch(closeModal("messagedialogdelete"));
          dispatch(callSnackBar(`${params?.product_accessories?.map((item) => item?.name).join(", ")}` + " Deleted Successfully", SNACK_BAR_VARIETNS.suceess))

        },
        (err) => {
          setLoading(false);
        }
      )

    );


  };

  const onDelete = () => {
    dispatch(
      openModal(
        <MessageDilog
          onSubmit={(e) => deleteFun(e)}
          title="Alert!"
          modalId="messagedialogdelete"
          message={`Are you sure to delete "${params?.product_accessories?.map((item) => item?.name).join(", ")}" ?`}
        />,
        "sm",
        false,
        "messagedialogdelete"
      )
    );
  };

  // const onDelete = () => {
  //     dispatch
  // }

  return (
    <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
      <IconButton size="inherit" onClick={onEdit}>
        <Edit color="info" fontSize="inherit" />
      </IconButton>
      <IconButton disabled={loading} size="inherit" onClick={onDelete}>
        <Delete color="error" fontSize="inherit" />
      </IconButton>
    </Box>
  );
});

const ProductAccessoriesController = () => {
  const dispatch = useDispatch();
  const { settings } = useSelector((state) => state)
  const title = "Problem ";
  const deleteApi = DeleteProductAccessoriesApi;
  const [loading, setLoading] = useState(false);
  const [list, setlist] = useState()
  const columns = useMemo(
    () => [
      {
        id: 1,
        fieldName: "product",
        label: "Product Name",
        align: "left",
        sort: true,
        renderValue: (params, setParams) => (
          <Typography textTransform="capitalize">{params?.product}</Typography>
        ),
      },
      {
        id: 2,
        fieldName: "product_accessories",
        label: "Product Accessories",
        align: "left",
        sort: true,
        renderValue: (params, setParams) => (params?.product_accessories?.map((item, index) => (<Chip sx={{ ml: 2 }} key={index} label={item?.name} />)))
      },
      {
        id: 5,
        fieldName: "",
        label: "Action",
        align: "right",
        renderValue: (params, setParams) => (
          <ActionComponent
            deleteApi={deleteApi}
            params={params}
            setParams={setParams}
            callBack={fetchList}
          />
        ),
      },
    ],
    [dispatch]
  );

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ["product"],
    sort: "",
    sortDirection: -1,
  });


  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await getProductAccessoriesApi({ ...filters }),
        (response) => {
          setLoading(false);
          setlist(response);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, [filters])

  return (
    <>
      <ProductAccessoriesListUi
        setlist={setlist}
        list={list}
        title={title}
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        columns={columns}
        callBack={fetchList}
      />
    </>
  );
};
export default ProductAccessoriesController;
