import React, { memo, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import DashboardPageUI from './DashboardPageUI'
import { CreateRepairingTicketApi, getRepairingTicketApi, RepairingTicketStatusCountChangeApi, StatusCountChangeApi } from '../../apis/repairing.api'
import { openModal } from '../../store/actions/modalAction';
import { useDispatch, useSelector } from 'react-redux';
import { callApiAction } from '../../store/actions/commonAction';
import moment from 'moment';
import MessageDilog from "../../components/texts/MessageDilog"
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import { Edit, Info } from '@mui/icons-material';
import CustomerInformation from './CustomerInformation';
// import RepairingStatusChange from './RepairingStatusChange';
import { BUTTON_TYPE, STATUS, USER_ROLES } from '../../utils/constants';
import UpdateCreateQueryController from './UpdateCreateQueryController';
import { statusAction, statusActionFilters, statusCountActionFilters } from '../../store/actions/statusAction';
import { fetchNotificationAction } from '../../store/actions/notificationAction';

const ActionComponent = memo(({ params, setParams, statusPass }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const onEdit = () => {
    dispatch(openModal(<UpdateCreateQueryController id={params._id} statusPass={statusPass}
    />, "md", false, "UpdateQuery"))
  }

  const onInfo = () => {
    dispatch(
      openModal(
        <CustomerInformation params={params} statusPass={statusPass} />
        ,
        "md",
        false,
        "CustomerInformation"
      )
    );
  };

  return <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
    <Tooltip title={"Customer Information"}>
      <IconButton disabled={loading} size="inherit" onClick={onInfo}>
        <Info color="info" fontSize="inherit" />
      </IconButton>
    </Tooltip>

    {((statusPass === STATUS.DEVICE_RECEIVED) || (statusPass === STATUS.REPAIR_START) || (statusPass === STATUS.PAYMENT_RECEIVED)) && <IconButton size="inherit" onClick={onEdit}>
      <Edit color="info" fontSize="inherit" />
    </IconButton>}
  </Box>
})

// const UpdateStatusComponent = memo(({ params, setParams }) => {
//   const dispatch = useDispatch()
//   const onEdit = () => {
//     dispatch(openModal(<RepairingStatusChange params={params}
//     />, "sm", false, "UpdateQuery"))
//   }

//   return <Tooltip onClick={onEdit} title={"Repairing status Update"}>
//     <Typography variant="h6"
//       sx={{
//         textDecoration: "underline",
//         marginLeft: "10px",
//         cursor: "pointer"
//       }}>
//       {params?.logs?.length >= 0 ? params?.logs[params?.logs?.length - 1]?.solvedProblem : null}
//     </Typography>
//   </Tooltip>

// })

const DashboardPageControllerRepair = () => {
  const dispatch = useDispatch();
  const [RepairstatusButton, setRepairstatusButton] = useState(BUTTON_TYPE.Work_In_Progress)
  const [STATUSWiseData, setSTATUSWiseData] = useState(STATUS.DEVICE_RECEIVED)
  const [Count, setCount] = useState()
  const statusPass = useRef();
  statusPass.current = STATUSWiseData

  const LeadTime = (date) => {
    const CurrentDates = moment();
    const UpdateDate = moment(date?.updatedAt);

    const momentDate = moment(date?.lead_time ? date?.lead_time : CurrentDates);
    if (!momentDate.isValid()) {
      return "";
    }
    const LeadTimeDate = momentDate.startOf("day");
    const UpdateLeadTimeDate = UpdateDate.startOf("day");
    const CurrentDate = moment().startOf("day");
    const daysDifference = LeadTimeDate.diff(RepairstatusButton === BUTTON_TYPE.Solved ? UpdateLeadTimeDate : CurrentDate, "days");
    return daysDifference > -10 ? "" : "red";
  };


  const columns = useMemo(
    () => [

      {
        id: 2,
        fieldName: "createdAt",
        label: "Query Create Date",
        align: "left",
        sort: true,
        renderValue: (params, setParams) => <Typography sx={{ color: LeadTime(params) }}>{moment(params.createdAt).format("DD/MM/YYYY")}</Typography>
      },
      {
        id: 8,
        fieldName: "updatedAt",
        label: "Query Update Date",
        align: "left",
        sort: true,
        renderValue: (params, setParams) => <Typography sx={{ color: LeadTime(params) }}>{moment(params.updatedAt).format("DD/MM/YYYY")}</Typography>
      },
      {
        id: 1,
        fieldName: "ticket_no",
        label: "Ticket No",
        align: "left",
        sort: true,
        renderValue: (params, setParams) => <Typography sx={{ color: LeadTime(params) }}>{params?.ticket_no}</Typography>
      },
      {
        id: 3,
        fieldName: "customer_name",
        label: "Customer Name",
        align: "left",
        sort: true,
        renderValue: (params, setParams) => <Typography sx={{ color: LeadTime(params) }}>{params?.customer_name}</Typography>
      },
      {
        id: 5,
        fieldName: "customer_phone",
        label: "Phone",
        align: "left",
        sort: true,
        renderValue: (params, setParams) => <Typography sx={{ color: LeadTime(params) }}>{params?.customer_phone}</Typography>
      },
      {
        id: 4,
        fieldName: "box_id",
        label: "Box Id",
        align: "left",
        sort: true,
        renderValue: (params, setParams) => <Typography sx={{ color: LeadTime(params) }}>{params?.box_id}</Typography>
      },
      STATUSWiseData === STATUS.REPLACEMENT_COMPLETE && {
        id: 6,
        fieldName: "new_box_id",
        label: "New Box Id",
        align: "left",
        sort: true,
        renderValue: (params, setParams) => <Typography sx={{ color: LeadTime(params) }}>{params.new_box_id ? params.new_box_id : "NA"}</Typography>
      },


      // {
      //   id: 6,
      //   fieldName: "repairing",
      //   label: "Repairing status",
      //   align: "left",
      //   sort: true,
      //   renderValue: (params, setParams) =>
      //     <UpdateStatusComponent
      //       params={params}
      //       setParams={setParams}
      //     />
      // },
      {
        id: 7,
        fieldName: "",
        label: "Action",

        align: "right",
        renderValue: (params, setParams) => (
          <ActionComponent
            params={params}
            setParams={setParams}
            statusPass={statusPass.current}
          // id={params._id}
          />
        ),
      },
    ],
    [dispatch, STATUSWiseData]
  );

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ['customer_name', 'box_id', 'customer_phone', 'ticket_no'],
    role: "",
    sort: "createdAt",
    sortDirection: -1,

    // ...statusData.statusReducer.filters
  });

  const [loadingCount, setLoadingCount] = useState(false);
  const [loading, setLoading] = useState(false);
  const statusData = useSelector((state) => state)
  const list = useRef();
  list.current = statusData.statusReducer.status_data

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await getRepairingTicketApi({ ...filters, status_role: STATUSWiseData, }),
        (response) => {
          dispatch(statusAction(response));
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };



  const fetchCountData = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await RepairingTicketStatusCountChangeApi({ role: USER_ROLES.REPAIR_TEAM }),
        async (response) => {
          setCount({ response });

          setLoading(false);
        },
        (err) => {
          setCount({ err });
          setLoading(false);
        }
      )
    );
  };

  const [CountStatus, setCountStatus] = useState()


  const fetchCountDataAll = () => {
    setLoadingCount(true);
    dispatch(
      callApiAction(
        async () => await StatusCountChangeApi({ role: USER_ROLES.REPAIR_TEAM }),
        async (response) => {
          setCountStatus({ response });

          setLoadingCount(false);
        },
        (err) => {
          setCountStatus({ err });
          setLoadingCount(false);
        }
      )
    );
  };

  useEffect(() => {
    if (JSON.stringify(filters) === JSON.stringify(statusData.statusReducer.filters)) {
      fetchList();
    } else {
      dispatch(statusActionFilters(filters));
    }
  }, [STATUSWiseData, statusData.statusReducer.filters, filters]);

  useEffect(() => {
    if (statusData.statusReducer.Count !== -1) {
      fetchCountDataAll();
    } else {
      dispatch(statusCountActionFilters(1));

    }
  }, [statusData.statusReducer.Count])

  return (
    <DashboardPageUI
      CountStatus={CountStatus}
      columns={columns}
      list={list.current}
      loading={loading}
      loadingCount={loadingCount}
      filters={filters}
      setFilters={setFilters}
      RepairstatusButton={RepairstatusButton}
      setRepairstatusButton={setRepairstatusButton}
      setSTATUSWiseData={setSTATUSWiseData}
      STATUSWiseData={STATUSWiseData}
      Count={Count}
    />
  )
}

export default DashboardPageControllerRepair