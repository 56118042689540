import { Autocomplete, Box, Button, Checkbox, Chip, CircularProgress, FormControlLabel, FormGroup, FormLabel, Grid, ListItem, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { memo, useEffect, useRef, useState } from "react";
import { useSelector } from 'react-redux';
import CustomInput from "../../components/inputs/CustomInputs";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import { PROBLEMS, SOLVED_STATUS, SELECT_PRODUCT_LABLE, SOLUTIONS, SOLVED_STATUS_LABLE, PROBLEMS_LABLE, STATUS, USER_ROLES, PAYMENT_METHOD, CANCEL_PAYMENT } from "../../utils/constants";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import moment from "moment";
import { findObjectKeyByValue, titleCase } from "../../utils/main";
import { getProdutApi } from "../../apis/product.api";
import { StyledSearchBar } from "../../components/inputs/SearchBar"
import AsyncDropDown from "../../components/inputs/AsyncDropDown";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxDesktop";
import { getsourceApi } from "../../apis/source.api";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
// import { getTicketProblemsFetchApi } from "../../apis/repairing.api";
import { getBoxIdApi } from "../../apis/boxid.api";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import FileInput from "../../components/upload/FileInput";


const UpdateCreateQueryUi = ({ listProblem, statusPass, setArrr, arrr, title, isOtp, productAccessories, modalKey, isUpdate, fields, setFields, loading, onSubmit, setVariant, variant1 }) => {
    const user = useSelector(state => state.user)
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const selectedDispatch = useRef();

    let isAlready = false;

    const todayDate = moment();
    const warrantyDate = moment(fields?.warrenty_date);

    const isLessThanOneYear = warrantyDate.isValid() && Math.trunc(((new Date(todayDate) - (new Date(warrantyDate))) / (1000 * 60 * 60 * 24))) / 365 <= 1;
    const isLessThanThreeYear = warrantyDate.isValid() && Math.trunc(((new Date(todayDate) - (new Date(warrantyDate))) / (1000 * 60 * 60 * 24))) / 365 <= 3;

    console.log("unbox_photo", fields.unbox_photo)

    return (
        <>
            {!isOtp && <CustomDialog
                id={`UpdateQuery`}
                loading={loading}
                err={fields.err}
                onSubmit={onSubmit}
                title={` Update ${title}`}
                closeText="Close"
                confirmText={`Update`}
            >
                {loading ? (
                    <CenteredBox><CircularProgress /></CenteredBox>
                ) : (
                    <>
                        <Box width={"90%"} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                            <Box display={"flex"} flexDirection={"column"}>
                                <Typography>
                                    Ticket Number :{fields?.ticket_no}
                                </Typography>
                            </Box>
                            <Box display={"flex"} flexDirection={"column"}>
                                <Typography>
                                    Date :{moment(fields?.createdAt).format("DD/MM/YYYY")}
                                </Typography>
                            </Box>
                        </Box>
                        <Box mt={3} width={"100%"}>
                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                                <AsyncDropDown
                                    disabled={user.data.role !== USER_ROLES.ADMIN}
                                    defaultVal={
                                        fields.product_id?._id ? {
                                            _id: fields.product_id?._id,
                                            name: fields.product_id?.name,
                                        } : null
                                    }
                                    lazyFun={async (para) => await getProdutApi({ ...para, allStatus: true })}
                                    OptionComponent={({ option, ...rest }) => {
                                        return <ListItem {...rest}>{option.name}</ListItem >
                                    }}
                                    onChange={async (changedVal) => {
                                        setFields({ ...fields, product_id: changedVal ? changedVal._id : null, })
                                    }}
                                    titleKey={'name'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <CustomInput placeholder={"Select Product"} {...params} margin="none" />}
                                />
                            </PaddingBoxInDesktop>

                            <Box mt={3}>
                                <Typography variant="h4">
                                    Customer Details :
                                </Typography>
                            </Box>
                            <CustomInput
                                disabled={user.data.role !== USER_ROLES.ADMIN}
                                value={fields.box_id}
                                onChange={(e) => setFields({ ...fields, err: '', box_id: e.target.value })}
                                type="text"
                                label={" Box Id.*"}
                            />
                            <Box mt={1} gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                <CustomInput
                                    autoFocus={true}
                                    disabled={user.data.role !== USER_ROLES.ADMIN}
                                    value={fields.customer_name}
                                    onChange={(e) => setFields({ ...fields, err: '', customer_name: e.target.value })}
                                    type="text"
                                    label={"Customer Name*"}
                                />
                                <CustomInput
                                    // autoFocus={true}
                                    disabled={user.data.role !== USER_ROLES.ADMIN}
                                    value={fields.customer_email}
                                    onChange={(e) => setFields({ ...fields, err: '', customer_email: e.target.value })}
                                    type="text"
                                    label={"Email"}
                                />

                            </Box>
                            <Box gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                <CustomInput
                                    // autoFocus={true}
                                    disabled={user.data.role !== USER_ROLES.ADMIN}
                                    value={fields.customer_phone}
                                    onChange={(e) => setFields({ ...fields, err: '', customer_phone: e.target.value })}
                                    type="number"
                                    label={" Phone No.*"}
                                />

                                <CustomInput
                                    // autoFocus={true}
                                    disabled={user.data.role !== USER_ROLES.ADMIN}
                                    value={fields.customer_address}
                                    onChange={(e) => setFields({ ...fields, err: '', customer_address: e.target.value })}
                                    type="text"
                                    label={"Address*"}
                                />
                            </Box>
                            <DesktopDatePicker
                                label="Manufacturer Date"
                                disableFuture
                                inputFormat="DD/MM/yyyy"
                                renderInput={(props) => <CustomInput {...props} />}
                                autoFocus={true}
                                disabled={user.data.role !== USER_ROLES.ADMIN}
                                value={fields?.manufacturer_date ? moment(fields?.manufacturer_date) : null}
                                onChange={(val) => {
                                    if (val && val.isValid()) {
                                        const isoString = val.toISOString();
                                        setFields({
                                            ...fields,
                                            err: "",
                                            manufacturer_date: isoString,
                                        });
                                    } else {
                                        setFields({
                                            ...fields,
                                            err: "",
                                            manufacturer_date: "",
                                        });
                                    }
                                }}
                            />
                            <PaddingBoxInDesktop mt={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end", width: "100%" }}  >
                                <AsyncDropDown
                                    disabled={user.data.role !== USER_ROLES.ADMIN}

                                    defaultVal={
                                        fields.source_id?._id ? {
                                            _id: fields.source_id?._id,
                                            title: fields.source_id?.title,
                                        } : null
                                    }
                                    lazyFun={async (para) => await getsourceApi({ ...para, allStatus: true })}
                                    OptionComponent={({ option, ...rest }) => {
                                        return <ListItem {...rest}>{option.title}</ListItem >
                                    }}
                                    onChange={async (changedVal) => {
                                        setFields({ ...fields, source_id: changedVal ? changedVal._id : null, })
                                    }}
                                    titleKey={'title'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <CustomInput placeholder={"Source*"} {...params} margin="none" />}
                                />
                            </PaddingBoxInDesktop>
                            <Box gap={3} mt={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                <Autocomplete
                                    disabled={statusPass !== STATUS.EVALUTION}
                                    value={listProblem?.filter(problem => fields.problems.includes(problem.name)) || []}
                                    sx={{ width: "100%", color: "primary.main" }}
                                    multiple
                                    onChange={(event, newValue) => {
                                        setFields({
                                            ...fields,
                                            err: "",
                                            problems: newValue.map((val) => val?.name),
                                        });
                                    }}
                                    options={listProblem ?? []}
                                    getOptionLabel={(option) => option.name}
                                    renderTags={(tagValue, getTagProps) =>
                                        tagValue.map((option, index) => (
                                            <Chip label={option.name} {...getTagProps({ index })} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            margin="dense"
                                            {...params}
                                            label="Problems*"
                                            variant="outlined"
                                            placeholder="Selected Problems"
                                        />
                                    )}
                                />

                                {fields?.problems?.map((item) =>
                                (item.toLowerCase() === "other" && <CustomInput
                                    disabled={statusPass !== STATUS.EVALUTION}
                                    value={fields.other}
                                    onChange={(e) => setFields({ ...fields, err: '', other: e.target.value })}
                                    type="text"
                                    label={" Other Problems"}
                                />
                                ))}
                            </Box>
                            {statusPass === STATUS.START_EVALUTION && <FormControl >
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={(e) => {
                                                    setFields({
                                                        ...fields,
                                                        err: "",
                                                        status: [
                                                            // ...fields.status,
                                                            {
                                                                status_role: STATUS.EVALUTION,
                                                                role: USER_ROLES.CRM_TEAM
                                                            },
                                                            {
                                                                status_role: STATUS.EVALUTION,
                                                                role: USER_ROLES.LD
                                                            }
                                                        ]
                                                    });
                                                }}
                                            />
                                        }
                                        label=" Start Evalution"
                                        labelPlacement="end"
                                    />
                                </FormGroup>
                            </FormControl>}

                            {statusPass === STATUS.EVALUTION && <>
                                <Box gap={3} mt={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                    <Autocomplete
                                        // disabled={statusPass !== STATUS.EVALUTION}
                                        value={productAccessories?.filter(accessories => fields.product_accessories.includes(accessories.name)) || []}
                                        sx={{ width: "100%", color: "primary.main" }}
                                        multiple
                                        onChange={(event, newValue) => {
                                            setFields({
                                                ...fields,
                                                err: "",
                                                product_accessories: newValue.map((val) => val?.name),
                                            });
                                        }}
                                        options={productAccessories ?? []}
                                        getOptionLabel={(option) => option.name}
                                        renderTags={(tagValue, getTagProps) =>
                                            tagValue.map((option, index) => (
                                                <Chip label={option.name} {...getTagProps({ index })} />
                                            ))
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                margin="dense"
                                                {...params}
                                                label="Product Accessories"
                                                variant="outlined"
                                                placeholder="Selected Product Accessories"
                                            />
                                        )}
                                    />
                                </Box>
                                <DesktopDatePicker
                                    label="Purchase Date"
                                    disableFuture
                                    inputFormat="DD/MM/yyyy"
                                    renderInput={(props) => <CustomInput {...props} />}
                                    autoFocus={true}
                                    disabled={loading}
                                    value={fields?.warrenty_date ? moment(fields?.warrenty_date) : null}
                                    onChange={(val) => {
                                        if (val && val.isValid()) {
                                            const isoString = val.toISOString();
                                            setFields({
                                                ...fields,
                                                err: "",
                                                warrenty_date: isoString,
                                            });
                                        } else {
                                            setFields({
                                                ...fields,
                                                err: "",
                                                warrenty_date: "",
                                            });
                                        }
                                    }}
                                />

                                <Box>
                                    <Grid xs={12} md={6} item>
                                        <Box>
                                            <FileInput
                                                multi
                                                onDelete={(filesToDelete) => {
                                                    setFields((prevFields) => ({
                                                        ...prevFields,
                                                        err: "",
                                                        unbox_photo: prevFields.unbox_photo.filter(file => !filesToDelete.includes(file))
                                                    }));
                                                }}
                                                onChange={(newFiles) => {
                                                    setFields((prevFields) => ({
                                                        ...prevFields,
                                                        err: "",
                                                        unbox_photo: [...prevFields.unbox_photo, ...newFiles]
                                                    }));
                                                }}
                                                defaults={fields?.unbox_photo ?? []}
                                                accept="image/*,.pdf"
                                                title="Upload Photo"
                                                subTitle="Only png, jpeg, jpg, pdf files are allowed! Less than 1 MB"
                                            />

                                        </Box>
                                    </Grid>
                                </Box>
                                <Box mt={3}>
                                    <FormControl >
                                        {/* <FormLabel component="legend">Conclusion</FormLabel> */}
                                        <FormGroup component="fieldset" row>

                                            {statusPass === STATUS.EVALUTION && <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={fields.conclusion === "send_for_repair"}
                                                        onChange={(e) => {
                                                            setFields({
                                                                ...fields,
                                                                err: "",
                                                                conclusion: e.target.name,
                                                                status: [
                                                                    // ...fields.status,
                                                                    {
                                                                        status_role: STATUS.DEVICE_RECEIVED,
                                                                        role: USER_ROLES.REPAIR_TEAM
                                                                    }
                                                                    , {
                                                                        status_role: STATUS.SEND_TO_REPAIR,
                                                                        role: USER_ROLES.CRM_TEAM
                                                                    }, {
                                                                        status_role: STATUS.SEND_TO_REPAIR,
                                                                        role: USER_ROLES.LD
                                                                    }
                                                                ]
                                                            });
                                                        }}
                                                        name="send_for_repair"
                                                    />
                                                }
                                                label="Send for Repair Team"
                                                labelPlacement="end"
                                            />}
                                        </FormGroup>
                                    </FormControl>
                                </Box>


                            </>}
                            {statusPass === STATUS.WAITING_FOR_PAYMENT && <Box mt={2}>
                                <DesktopDatePicker
                                    disableFuture

                                    inputFormat="DD/MM/yyyy"
                                    renderInput={(props) => <CustomInput {...props} />}
                                    autoFocus={true}
                                    disabled={user.data.role !== USER_ROLES.ADMIN}
                                    label="Purchase Date"
                                    value={fields?.warrenty_date ? moment(fields?.warrenty_date) : null}
                                    onChange={(val) => {
                                        if (val && val.isValid()) {
                                            const isoString = val.toISOString();
                                            setFields({
                                                ...fields,
                                                err: "",
                                                warrenty_date: isoString,
                                            });
                                        } else {
                                            setFields({
                                                ...fields,
                                                err: "",
                                                warrenty_date: "",
                                            });
                                        }
                                    }}
                                />
                                {fields.device_is === "physical_damage" && <Box mt={1} gap={3} mb={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                    <CustomInput
                                        // autoFocus={true}
                                        disabled={user.data.role !== USER_ROLES.ADMIN}
                                        value={fields?.physical_damage?.damage_part}
                                        onChange={(e) => setFields({ ...fields, err: '', physical_damage: { ...fields.physical_damage, damage_part: e.target.value } })}
                                        type="text"
                                        label={"Damage Part*"}
                                    />
                                    <CustomInput
                                        // autoFocus={true}
                                        disabled={user.data.role !== USER_ROLES.ADMIN}
                                        value={fields?.physical_damage?.repairing_amount}
                                        onChange={(e) => setFields({ ...fields, err: '', physical_damage: { ...fields.physical_damage, repairing_amount: e.target.value } })}
                                        type="text"
                                        label={"Cost*"}
                                    />
                                    <CustomInput
                                        // autoFocus={true}
                                        disabled={user.data.role !== USER_ROLES.ADMIN}
                                        value={fields?.physical_damage?.remarks}
                                        onChange={(e) => setFields({ ...fields, err: '', physical_damage: { ...fields.physical_damage, remarks: e.target.value } })}
                                        type="text"
                                        label={"Remarks*"}
                                    />

                                </Box>}

                                {fields.device_is === "Repairable" && <Box mt={1} mb={2} gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>

                                    <CustomInput
                                        autoFocus={true}
                                        disabled={user.data.role !== USER_ROLES.ADMIN}

                                        value={fields?.repairable_part}
                                        onChange={(e) => setFields({ ...fields, err: '', repairable_part: e.target.value })}
                                        type="text"
                                        label={"Which part you repair*"}
                                    />
                                    <CustomInput
                                        // autoFocus={true}
                                        disabled={user.data.role !== USER_ROLES.ADMIN}

                                        value={fields?.repairable_remarks}
                                        onChange={(e) => setFields({ ...fields, err: '', repairable_remarks: e.target.value })}
                                        type="text"
                                        label={"Repairable Remarks*"}
                                    />
                                    {fields.problems.map((item) => {

                                        if (isAlready) {
                                            return null;
                                        }
                                        return <>
                                            {(item.toLowerCase() === "battery" && !isLessThanOneYear) && (
                                                <> {isAlready = true}
                                                    <CustomInput
                                                        disabled={user.data.role !== USER_ROLES.ADMIN}

                                                        value={fields?.repairing_amount}
                                                        onChange={(e) => setFields({ ...fields, err: '', repairing_amount: e.target.value })}
                                                        type="number"
                                                        label={"Cost*"}
                                                    />
                                                </>
                                            )}
                                            {(item != "Battery" && !isLessThanThreeYear) && (
                                                <> {isAlready = true}
                                                    <CustomInput
                                                        disabled={user.data.role !== USER_ROLES.ADMIN}

                                                        value={fields?.repairing_amount}
                                                        onChange={(e) => setFields({ ...fields, err: '', repairing_amount: e.target.value })}
                                                        type="number"
                                                        label={"Cost*"}
                                                    />
                                                </>
                                            )}
                                        </>
                                    }

                                    )}

                                </Box>}
                                <FormControl fullWidth>
                                    <InputLabel>Pending Payment</InputLabel>
                                    <Select
                                        value={fields.repairing_amount_status}
                                        label="Pending Payment"
                                        onChange={(e) => {
                                            setFields({
                                                ...fields,
                                                err: '',
                                                repairing_amount_status: e.target.value,
                                                // status
                                            });
                                        }}
                                    >
                                        <MenuItem value={PAYMENT_METHOD.PAYMENT_RECEIVED}>{titleCase(PAYMENT_METHOD.PAYMENT_RECEIVED)}</MenuItem>
                                        <MenuItem value={PAYMENT_METHOD.CANCEL_PAYMENT}>{"Unable To Pay"}</MenuItem>
                                        <MenuItem value={PAYMENT_METHOD.RETURN_DEVICE}>{titleCase(PAYMENT_METHOD.RETURN_DEVICE)}</MenuItem>
                                    </Select>
                                </FormControl>

                                {fields.repairing_amount_status === PAYMENT_METHOD.PAYMENT_RECEIVED && <FormControl >
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onChange={(e) => {
                                                        setFields({
                                                            ...fields,
                                                            err: "",
                                                            status: [
                                                                {
                                                                    status_role: STATUS.PAYMENT_RECEIVED,
                                                                    role: USER_ROLES.REPAIR_TEAM
                                                                },
                                                                {
                                                                    status_role: STATUS.SEND_TO_REPAIR,
                                                                    role: USER_ROLES.CRM_TEAM
                                                                },
                                                                {
                                                                    status_role: STATUS.SEND_TO_REPAIR,
                                                                    role: USER_ROLES.LD
                                                                }

                                                            ]
                                                        });
                                                    }}
                                                />
                                            }
                                            label="Sent To Repair"
                                            labelPlacement="end"
                                        />
                                    </FormGroup>
                                </FormControl>}
                                {fields.repairing_amount_status === PAYMENT_METHOD.CANCEL_PAYMENT && <>

                                    <Box mt={2} display={"flex"} flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"} border={"1px solid black"} width={"100%"} height={"8vh"} borderRadius={"4px"} p={3}>

                                        <FormControl sx={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>
                                            <FormLabel id="demo-radio-buttons-group-label">Customer need Devices Back ? :</FormLabel>
                                            <RadioGroup
                                                row
                                                sx={{ marginLeft: "10px" }}
                                                value={selectedDispatch.current}
                                                onChange={(e) => {
                                                    const data = e.target.value

                                                    let statusdata = [];
                                                    if (data == CANCEL_PAYMENT.YES) {
                                                        statusdata = [
                                                            {
                                                                status_role: STATUS.DISPATCH,
                                                                role: USER_ROLES.LD,
                                                            },
                                                            {
                                                                status_role: STATUS.DISPATCH,
                                                                role: USER_ROLES.CRM_TEAM,
                                                            }

                                                        ];
                                                    }

                                                    if (data == CANCEL_PAYMENT.NO) {

                                                        statusdata = [
                                                            {
                                                                status_role: STATUS.NOT_DISPATCH,
                                                                role: USER_ROLES.CRM_TEAM,
                                                            },
                                                            {
                                                                status_role: STATUS.NOT_DISPATCH,
                                                                role: USER_ROLES.LD,
                                                            },
                                                            {
                                                                status_role: STATUS.NOT_DISPATCH,
                                                                role: USER_ROLES.REPAIR_TEAM,
                                                            }
                                                        ];
                                                    }

                                                    setFields({
                                                        ...fields,
                                                        err: "",
                                                        status: statusdata,
                                                    });
                                                }}
                                            >
                                                {Object.keys(CANCEL_PAYMENT).map((item) => (
                                                    <FormControlLabel
                                                        disabled={loading}
                                                        key={item}
                                                        value={CANCEL_PAYMENT[item]}
                                                        control={<Radio />}
                                                        label={titleCase(item)}
                                                    />
                                                ))}
                                            </RadioGroup>

                                        </FormControl>
                                    </Box>
                                    {selectedDispatch && (
                                        <CustomInput
                                            disabled={loading}
                                            value={fields.dispatch_remarks}
                                            onChange={(e) => setFields({ ...fields, err: '', dispatch_remarks: e.target.value })}
                                            type="text"
                                            label={"Remarks*"}
                                        />
                                    )}

                                </>}
                                {fields.repairing_amount_status === PAYMENT_METHOD.RETURN_DEVICE && <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={(e) => {
                                                    setFields({
                                                        ...fields,
                                                        err: "",
                                                        status: [
                                                            {
                                                                status_role: STATUS.PAYMENT_RECEIVED,
                                                                role: USER_ROLES.REPAIR_TEAM
                                                            },
                                                            {
                                                                status_role: STATUS.SEND_TO_REPAIR,
                                                                role: USER_ROLES.CRM_TEAM
                                                            },
                                                            {
                                                                status_role: STATUS.SEND_TO_REPAIR,
                                                                role: USER_ROLES.LD
                                                            }

                                                        ]
                                                    });
                                                }}
                                            />
                                        }
                                        label="Return Device"
                                        labelPlacement="end"
                                    />
                                </FormGroup>
                                }
                            </Box>
                            }
                            {statusPass === STATUS.COMPLETED && <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={(e) => {
                                                setFields({
                                                    ...fields,
                                                    err: "",
                                                    completed_status: "Device Received"
                                                });
                                            }}
                                        />
                                    }
                                    label="Device Delivered"
                                    labelPlacement="end"
                                />
                            </FormGroup>
                            }
                        </Box>
                    </>
                )}
            </CustomDialog >}
        </>
    );
}

export default memo(UpdateCreateQueryUi);