import axios from "axios";
import { getHeaders } from "../utils/main";
import endpoints from "./endpoints";

export const getProductAccessoriesApi = async params => {
    const callResponse = await axios({
        url: endpoints.productAccessoriesFetch,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};

export const CreateProductAccessoriesApi = async data => {
    const callResponse = await axios({
        url: endpoints.CreateproductAccessories,
        method: "post",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};

export const DeleteProductAccessoriesApi = async data => {
    const callResponse = await axios({
        url: endpoints.DeleteproductAccessories,
        method: "delete",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};

export const UpdateProductAccessoriesApi = async data => {
    const callResponse = await axios({
        url: endpoints.UpdateproductAccessories,
        method: "patch",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};

export const ProductAccessoriesFindByIdApi = async params => {
    const callResponse = await axios({
        url: endpoints.productAccessoriesById,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};