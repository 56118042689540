import axios from "axios";
import endpointsims from "./endpointsims.api";
import { getSimsHeaders } from "../utils/main";


export const FetchBoxIdformsims = async params => {
    const callResponse = await axios({
        url: endpointsims.fetchBoxIdformsims,
        method: "get",
        headers: getSimsHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};