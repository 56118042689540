import { Box, Button, ButtonGroup, Grid, IconButton, Paper, Skeleton, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import AddIcon from '@mui/icons-material/Add';
import DataTable from '../../components/tables/DataTable';
import CustomDialog from '../../components/layouts/common/CustomDialog';
import { FilterTitleBox, FiltersBox } from '../../components/layouts/OneViewBox';
import PaddingBoxInDesktop from '../../components/layouts/PaddingBoxDesktop';
import { useDispatch } from 'react-redux';
import { openModal } from '../../store/actions/modalAction';

import AsyncSearchBar from '../../components/inputs/AsyncSearchBar';
import { BUTTON_TYPE, STATUS } from '../../utils/constants';
import ButtonComponentsUi from './ButtonComponentsUi';
import { titleCase } from '../../utils/main';

const DashboardPageUI = ({ loadingCount, Count, CountStatus, setSTATUSWiseData, STATUSWiseData, filters, setFilters, createTiket, setList, loading, list, columns, setLDstatusButton, LDstatusButton }) => {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"))
  const issmSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"))

  return (
    <>
      <Box  >
        <Paper sx={{ width: "100%", height: "85vh", overflow: isSmallScreen ? "auto" : "hidden", padding: 6 }}>
          <Box   >
            <FilterTitleBox >
              <Box mt={2} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} width={"100%"}>
                <Box >
                  <Typography variant="h3" color={"primary"}>
                    Logistics Department Dashboard
                  </Typography>
                </Box>

              </Box>
            </FilterTitleBox>
          </Box>

          {loadingCount ?
            <Box mt={4} sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', height: "15vh" }}>
              {[0, 1, 2, 3].map((row) => (
                <Skeleton
                  key={row}
                  variant="rectangular"
                  width={!issmSmallScreen ? "24%" : "24%"}
                  height={120}
                  sx={{ borderRadius: "8px", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}
                />
              ))}
            </Box>
            : <Grid mt={4} md={12} xs={12} sm={12} container>
              <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", flexDirection: !issmSmallScreen ? "row" : "column" }}>
                <Box width={!issmSmallScreen ? "24%" : "100%"} >

                  <ButtonComponentsUi onSubmit={() => { setLDstatusButton(BUTTON_TYPE.pending); setSTATUSWiseData(STATUS.YET_TO_RECEIVED) }}
                    count={((CountStatus?.response[0]?.count ?? 0) + (CountStatus?.response[2]?.count ?? 0) ?? 0)} STATUSTypeData={BUTTON_TYPE.pending} colorType={LDstatusButton == BUTTON_TYPE.pending} />
                </Box>
                <Box width={!issmSmallScreen ? "24%" : "100%"} >


                  <ButtonComponentsUi onSubmit={() => { setLDstatusButton(BUTTON_TYPE.Work_In_Progress); setSTATUSWiseData(STATUS.EVALUTION) }}
                    count={((CountStatus?.response[1]?.count ?? 0) + (CountStatus?.response[7]?.count ?? 0) + (CountStatus?.response[4]?.count ?? 0) + (CountStatus?.response[5]?.count ?? 0) ?? 0)} STATUSTypeData={BUTTON_TYPE.Work_In_Progress} colorType={LDstatusButton == BUTTON_TYPE.Work_In_Progress} />
                </Box>
                <Box width={!issmSmallScreen ? "24%" : "100%"} >


                  <ButtonComponentsUi onSubmit={() => { setLDstatusButton(BUTTON_TYPE.Solved); setSTATUSWiseData(STATUS.COMPLETED) }}
                    count={((CountStatus?.response[6]?.count ?? 0) +(CountStatus?.response[15]?.count ?? 0)+ (CountStatus?.response[14]?.count ?? 0) + (CountStatus?.response[9]?.count ?? 0) ?? 0)} STATUSTypeData={BUTTON_TYPE.Solved} colorType={LDstatusButton == BUTTON_TYPE.Solved} />
                </Box>
                <Box width={!issmSmallScreen ? "24%" : "100%"} >

                  <ButtonComponentsUi
                    count={((CountStatus?.response[0]?.count ?? 0) + (CountStatus?.response[2]?.count ?? 0) +
                      (CountStatus?.response[1]?.count ?? 0) +(CountStatus?.response[15]?.count ?? 0)+ (CountStatus?.response[4]?.count ?? 0) + (CountStatus?.response[14]?.count ?? 0) + (CountStatus?.response[5]?.count ?? 0) + (CountStatus?.response[7]?.count ?? 0) + (CountStatus?.response[6]?.count ?? 0) + (CountStatus?.response[9]?.count ?? 0) ?? 0)}
                    Total={"Total"}
                    STATUSTypeData
                  />
                </Box>
              </Box>
            </Grid>}

          {loadingCount ?
            <Box mt={5} mb={4} sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', width: !issmSmallScreen ? "25%" : "100%" }}>
              {[0, 1, 2, 3].map((row) => (
                <Skeleton
                  key={row}
                  variant="rectangular"
                  width={!issmSmallScreen ? "25%" : "100%"}
                  height={50}
                  sx={{ borderRadius: "8px", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" }}
                />
              ))}
            </Box>
            : <Box mt={4} sx={{ width: "100%", display: "flex", justifyContent: "space-between", flexDirection: !isSmallScreen ? "row" : "column" }}>

              {LDstatusButton === BUTTON_TYPE.pending && <Box my={3}> <ButtonGroup >
                <ButtonComponentsUi onSubmit={() => { setSTATUSWiseData(STATUS.YET_TO_RECEIVED); setFilters({ ...filters, pageNo: 1 }) }} count={CountStatus?.response[0]?.count ?? 0} Title={STATUS.YET_TO_RECEIVED} STATUSWiseData={STATUSWiseData == STATUS.YET_TO_RECEIVED} />
                <ButtonComponentsUi onSubmit={() => { setSTATUSWiseData(STATUS.SEND_TO_CRM); setFilters({ ...filters, pageNo: 1 }) }} count={CountStatus?.response[2]?.count ?? 0} Title={STATUS.SEND_TO_CRM} STATUSWiseData={STATUSWiseData == STATUS.SEND_TO_CRM} />
              </ButtonGroup>
              </Box>}

              {LDstatusButton === BUTTON_TYPE.Work_In_Progress && <Box my={3}> <ButtonGroup sx={{ flexDirection: !issmSmallScreen ? "row" : "column", width: issmSmallScreen ? "100%" : "100%" }}>
                <ButtonComponentsUi onSubmit={() => { setSTATUSWiseData(STATUS.EVALUTION); setFilters({ ...filters, pageNo: 1 }) }} count={CountStatus?.response[1]?.count ?? 0} Title={STATUS.EVALUTION} STATUSWiseData={STATUSWiseData == STATUS.EVALUTION} />
                <ButtonComponentsUi onSubmit={() => { setSTATUSWiseData(STATUS.SEND_TO_REPAIR); setFilters({ ...filters, pageNo: 1 }) }} count={CountStatus?.response[4]?.count ?? 0} Title={STATUS.SEND_TO_REPAIR} STATUSWiseData={STATUSWiseData == STATUS.SEND_TO_REPAIR} />
                <ButtonComponentsUi onSubmit={() => { setSTATUSWiseData(STATUS.WAITING_FOR_PAYMENT); setFilters({ ...filters, pageNo: 1 }) }} count={CountStatus?.response[5]?.count ?? 0} Title={STATUS.WAITING_FOR_PAYMENT} STATUSWiseData={STATUSWiseData == STATUS.WAITING_FOR_PAYMENT} />
                {/* <ButtonComponentsUi onSubmit={() => setSTATUSWiseData(STATUS.REPAIR_COMPLETE)} count={CountStatus?.response[14]?.count ?? 0} Title={STATUS.REPAIR_COMPLETE} STATUSWiseData={STATUSWiseData == STATUS.REPAIR_COMPLETE} /> */}
                {/* <ButtonComponentsUi onSubmit={() => setSTATUSWiseData(STATUS.REPAIR_REPLACEMENT)} count={CountStatus?.response[15]?.count} Title={STATUS.REPAIR_REPLACEMENT} STATUSWiseData={STATUSWiseData == STATUS.REPAIR_REPLACEMENT} /> */}
                <ButtonComponentsUi onSubmit={() => { setSTATUSWiseData(STATUS.DISPATCH); setFilters({ ...filters, pageNo: 1 }) }} count={CountStatus?.response[7]?.count ?? 0} Title={STATUS.DISPATCH} STATUSWiseData={STATUSWiseData == STATUS.DISPATCH} />
              </ButtonGroup>  </Box>}

              {LDstatusButton === BUTTON_TYPE.Solved && <Box my={3}> <ButtonGroup sx={{ flexDirection: !issmSmallScreen ? "row" : "column", width: issmSmallScreen ? "100%" : "100%" }} >
                <ButtonComponentsUi onSubmit={() => { setSTATUSWiseData(STATUS.COMPLETED); setFilters({ ...filters, pageNo: 1 }) }} count={CountStatus?.response[9]?.count ?? 0} Title={STATUS.COMPLETED} STATUSWiseData={STATUSWiseData == STATUS.COMPLETED} />
                <ButtonComponentsUi onSubmit={() => { setSTATUSWiseData(STATUS.NOT_DISPATCH); setFilters({ ...filters, pageNo: 1 }) }} count={CountStatus?.response[6]?.count ?? 0} Title={STATUS.NOT_DISPATCH} STATUSWiseData={STATUSWiseData == STATUS.NOT_DISPATCH} />
                <ButtonComponentsUi onSubmit={() => { setSTATUSWiseData(STATUS.DEMO); setFilters({ ...filters, pageNo: 1 }) }} count={CountStatus?.response[15]?.count ?? 0} Title={STATUS.DEMO} STATUSWiseData={STATUSWiseData == STATUS.DEMO} />
                <ButtonComponentsUi onSubmit={() => { setSTATUSWiseData(STATUS.REPLACEMENT_COMPLETE); setFilters({ ...filters, pageNo: 1 }) }} count={CountStatus?.response[14]?.count ?? 0} Title={STATUS.REPLACEMENT_COMPLETE} STATUSWiseData={STATUSWiseData == STATUS.REPLACEMENT_COMPLETE} />
              </ButtonGroup>
              </Box>}

              <PaddingBoxInDesktop
                my={3}
                sx={{ display: "flex", width: !issmSmallScreen ? "20%" : !isSmallScreen ? "50%" : "100%" }}
                pl={3}>
                <AsyncSearchBar
                  fullWidth
                  title="Search "
                  size="small"
                  placeholder={" Search "}
                  defaultValue={filters.search}
                  onChange={(changedVal) => {
                    setFilters({ ...filters, search: changedVal });
                  }}
                />
              </PaddingBoxInDesktop>
            </Box>}


          <Box overflow={"auto"} height={"60vh"}>
            <DataTable key={STATUSWiseData} customRows={true} columns={columns} rows={list?.result ? list?.result : []} count={list?.total ?? 0} filters={filters} setFilters={setFilters} loading={loading} />
          </Box>
        </Paper >
      </Box >


    </>
  )
}

export default DashboardPageUI