import { USER_ROLES } from "./constants";
import MODULES from "./module.constant";

export const getDefaultRedirect = (modules) => {

     if(modules && Array.isArray(modules) && modules.includes(USER_ROLES.CRM_TEAM)){
          return '/crm-dashboard/'
       }
       if(modules && Array.isArray(modules) && modules.includes(USER_ROLES.LD)){
          return '/ld-dashboard/'
       }
       if(modules && Array.isArray(modules) && modules.includes(USER_ROLES.REPAIR_TEAM)){
          return '/repair-dashboard/'
       }
       if(modules && Array.isArray(modules) && modules.includes(USER_ROLES.ADMIN)){
         return '/admin-page/'
      }
     return '/'
}