import { Autocomplete, Box, Button, Checkbox, Chip, CircularProgress, FormControlLabel, FormGroup, FormLabel, IconButton, InputAdornment, ListItem, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import CustomInput from "../../components/inputs/CustomInputs";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import { PROBLEMS, SOLVED_STATUS, SELECT_PRODUCT_LABLE, SOLUTIONS, SOLVED_STATUS_LABLE, PROBLEMS_LABLE, STATUS, USER_ROLES, PAYMENT_METHOD, CANCEL_PAYMENT, DEVICES_BACK, REPLACEMENT_BOX } from "../../utils/constants";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import moment from "moment";
import { findObjectKeyByValue, titleCase } from "../../utils/main";
import { getProdutApi } from "../../apis/product.api";
import { StyledSearchBar } from "../../components/inputs/SearchBar"
import AsyncDropDown from "../../components/inputs/AsyncDropDown";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxDesktop";
import { getsourceApi } from "../../apis/source.api";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
// import { getTicketProblemsFetchApi } from "../../apis/repairing.api";
import { getBoxIdApi, getBoxnotallocatedboxIdApi } from "../../apis/boxid.api";
import { CheckBox, QrCodeScanner } from "@mui/icons-material";
import QrScannerPopup from "../../components/Scanner/QrScannerPopup";
import { closeModal, openModal } from "../../store/actions/modalAction";


const UpdateCreateQueryUi = ({ SetReplace, productAccessories, replace, statusPass, listProblem, title, setSelectedDispatch, selectedDispatch, modalKey, isUpdate, fields, setFields, loading, onSubmit }) => {

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const [isChecked, setIsChecked] = useState(false);
    // const arrr=[]
    // const selectedDispatch = useRef();


    const onCheckbox1 = (e) => {
        // setIsChecked(e);

        setFields({
            ...fields,
            err: "",
            status: fields.repairing_amount_status == "CANCEL_PAYMENT" ?
                [
                    {
                        status_role: STATUS.COMPLETED,
                        role: USER_ROLES.CRM_TEAM
                    },
                    {
                        status_role: STATUS.COMPLETED,
                        role: USER_ROLES.LD
                    }
                ]
                :
                fields.device_is === "Replace" ? [
                    {
                        status_role: STATUS.REPLACEMENT_COMPLETE,
                        role: USER_ROLES.CRM_TEAM
                    },
                    {
                        status_role: STATUS.REPLACEMENT_COMPLETE,
                        role: USER_ROLES.LD
                    },
                    {
                        status_role: STATUS.REPLACEMENT_COMPLETE,
                        role: USER_ROLES.REPAIR_TEAM
                    }
                    // {
                    //     status_role: STATUS.COMPLETED,
                    //     role: USER_ROLES.LD
                    // },
                    // {
                    //     status_role: STATUS.REPLACEMENT,
                    //     role: USER_ROLES.REPAIR_TEAM
                    // },
                ]
                    :
                    [
                        {
                            status_role: STATUS.COMPLETED,
                            role: USER_ROLES.CRM_TEAM
                        },
                        {
                            status_role: STATUS.COMPLETED,
                            role: USER_ROLES.LD
                        },

                        {
                            status_role: STATUS.REPAIR_COMPLETED,
                            role: USER_ROLES.REPAIR_TEAM
                        }
                    ]

        });
    }

    const onCheckbox2 = () => {

        setFields({
            ...fields,
            err: "",
            status: [
                {
                    status_role: STATUS.DEMO,
                    role: USER_ROLES.REPAIR_TEAM
                },
                {
                    status_role: STATUS.DEMO,
                    role: USER_ROLES.LD
                },
                {
                    status_role: STATUS.DEMO,
                    role: USER_ROLES.CRM_TEAM
                }

            ]
        });
    }

    const user = useSelector(state => state.user)
    const dispatch = useDispatch()

    const onScan = (data) => {
        setFields({ ...fields, err: '', new_box_id: data?.text })
    };
    const onQrBtnClick = () => {
        dispatch(
            openModal(
                <QrScannerPopup
                    setFields={setFields}
                    fields={fields}
                    onScan={onScan}
                    onClose={() => dispatch(closeModal("scanner"))}
                />,
                "sm",
                false,
                "scanner"
            )
        );
    };
    return (
        <>
            <CustomDialog
                id={`UpdateQuery`}
                loading={loading}
                err={fields.err}
                onSubmit={onSubmit}
                title={` Update ${title}`}
                closeText="Close"
                confirmText={`Update`}
            >
                {loading ? (
                    <CenteredBox><CircularProgress /></CenteredBox>
                ) : (
                    <>
                        <Box width={"90%"} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                            <Box display={"flex"} flexDirection={"column"}>
                                <Typography>
                                    Ticket Number :{fields?.ticket_no}
                                </Typography>
                            </Box>
                            <Box display={"flex"} flexDirection={"column"}>
                                <Typography>
                                    Date :{moment(fields?.createdAt).format("DD/MM/YYYY")}
                                </Typography>
                            </Box>
                        </Box>
                        <Box mt={3} width={"100%"}>

                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
                                <AsyncDropDown
                                    disabled={user.data.role !== USER_ROLES.ADMIN}

                                    defaultVal={
                                        fields.product_id?._id ? {
                                            _id: fields.product_id?._id,
                                            name: fields.product_id?.name,
                                        } : null
                                    }
                                    lazyFun={async (para) => await getProdutApi({ ...para, allStatus: true })}
                                    OptionComponent={({ option, ...rest }) => {
                                        return <ListItem {...rest}>{option.name}</ListItem >
                                    }}
                                    onChange={async (changedVal) => {
                                        console.log("first changedVal", changedVal)
                                        setFields({ ...fields, product_id: changedVal ? changedVal : null, })
                                    }}
                                    titleKey={'name'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <CustomInput placeholder={"Select Product"} {...params} margin="none" />}
                                />
                            </PaddingBoxInDesktop>

                            <Box mt={3}>
                                <Typography variant="h4">
                                    Customer Details :
                                </Typography>
                            </Box>

                            <Box>
                                <CustomInput
                                    // autoFocus={true}
                                    disabled={user.data.role !== USER_ROLES.ADMIN}

                                    value={fields.box_id}
                                    onChange={(e) => setFields({ ...fields, err: '', box_id: e.target.value })}
                                    type="text"
                                    label={" Box Id.*"}
                                />
                            </Box>

                            <Box mt={1} gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                <CustomInput
                                    autoFocus={true}
                                    disabled={user.data.role !== USER_ROLES.ADMIN}

                                    value={fields.customer_name}
                                    onChange={(e) => setFields({ ...fields, err: '', customer_name: e.target.value })}
                                    type="text"
                                    label={"Customer Name*"}
                                />
                                <CustomInput
                                    // autoFocus={true}
                                    disabled={user.data.role !== USER_ROLES.ADMIN}

                                    value={fields.customer_email}
                                    onChange={(e) => setFields({ ...fields, err: '', customer_email: e.target.value })}
                                    type="text"
                                    label={"Email"}
                                />

                            </Box>

                            <Box gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                <CustomInput
                                    // autoFocus={true}
                                    disabled={user.data.role !== USER_ROLES.ADMIN}

                                    value={fields.customer_phone}
                                    onChange={(e) => setFields({ ...fields, err: '', customer_phone: e.target.value })}
                                    type="number"
                                    label={" Phone No.*"}
                                />
                                <CustomInput
                                    // autoFocus={true}
                                    disabled={user.data.role !== USER_ROLES.ADMIN}

                                    value={fields.customer_address}
                                    onChange={(e) => setFields({ ...fields, err: '', customer_address: e.target.value })}
                                    type="text"
                                    label={"Address*"}
                                />

                            </Box>

                            <PaddingBoxInDesktop mt={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end", width: "100%" }}  >
                                <AsyncDropDown
                                    disabled={user.data.role !== USER_ROLES.ADMIN}


                                    defaultVal={
                                        fields.source_id?._id ? {
                                            _id: fields.source_id?._id,
                                            title: fields.source_id?.title,
                                        } : null
                                    }
                                    lazyFun={async (para) => await getsourceApi({ ...para, allStatus: true })}
                                    OptionComponent={({ option, ...rest }) => {
                                        return <ListItem {...rest}>{option.title}</ListItem >
                                    }}
                                    onChange={async (changedVal) => {
                                        setFields({ ...fields, source_id: changedVal ? changedVal._id : null, })
                                    }}
                                    titleKey={'title'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <CustomInput placeholder={"Source*"} {...params} margin="none" />}
                                />
                            </PaddingBoxInDesktop>

                            <Box gap={3} mt={4} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                <Autocomplete
                                    disabled={user.data.role !== USER_ROLES.ADMIN ? statusPass === STATUS.EVALUTION ? false : true : false}
                                    value={listProblem?.filter(problem => fields.problems.includes(problem.name)) || []}
                                    sx={{ width: "100%", color: "primary.main" }}
                                    multiple
                                    onChange={(event, newValue) => {
                                        setFields({
                                            ...fields,
                                            err: "",
                                            problems: newValue.map((val) => val?.name),
                                        });
                                    }}
                                    options={listProblem ?? []}
                                    getOptionLabel={(option) => option.name}
                                    renderTags={(tagValue, getTagProps) =>
                                        tagValue.map((option, index) => (
                                            <Chip label={option.name} {...getTagProps({ index })} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            margin="dense"
                                            {...params}
                                            label="Problems*"
                                            variant="outlined"
                                            placeholder="Selected Problems"
                                        />
                                    )}
                                />
                                {fields.other && <CustomInput
                                    // autoFocus={true}
                                    disabled={user.data.role !== USER_ROLES.ADMIN}

                                    value={fields.other}
                                    onChange={(e) => setFields({ ...fields, err: '', other: e.target.value })}
                                    type="text"
                                    label={" Other Problems"}
                                />}
                            </Box>

                            <Box mt={2}>
                                {statusPass === STATUS.YET_TO_RECEIVED &&
                                    <FormControl >
                                        <FormGroup row>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={(e) => {
                                                            setFields({
                                                                ...fields,
                                                                err: "",
                                                                status: [
                                                                    // ...fields.status,
                                                                    {
                                                                        status_role: STATUS.START_EVALUTION,
                                                                        role: USER_ROLES.CRM_TEAM
                                                                    },
                                                                    {
                                                                        status_role: STATUS.SEND_TO_CRM,
                                                                        role: USER_ROLES.LD
                                                                    }
                                                                ],
                                                                lead_time: moment()
                                                            });
                                                        }}
                                                    />
                                                }
                                                label="Device Arrived"
                                                labelPlacement="end"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                }
                            </Box>

                            {(statusPass === STATUS.DISPATCH) && <>

                                <Box mt={2} mb={4} display={"flex"} flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"} border={"1px solid black"} width={"100%"} height={"8vh"} borderRadius={"4px"} p={3}>

                                    <FormControl sx={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>
                                        <FormLabel id="demo-radio-buttons-group-label">Customer need Devices Back ? :</FormLabel>
                                        <RadioGroup
                                            row
                                            sx={{ marginLeft: "10px" }}
                                            onChange={(e) => {
                                                setSelectedDispatch(e.target.value);
                                                e.target.value == DEVICES_BACK.YES ? onCheckbox1(true) : onCheckbox2()
                                            }}
                                        >
                                            {Object.keys(DEVICES_BACK).map((item) => (
                                                <FormControlLabel
                                                    disabled={loading}
                                                    key={item}
                                                    value={DEVICES_BACK[item]}
                                                    control={<Radio />}
                                                    label={titleCase(item)}
                                                />
                                            ))}
                                        </RadioGroup>

                                    </FormControl>
                                </Box>
                            </>}
                            {(selectedDispatch == DEVICES_BACK.YES) && (fields.product_id?.name === "HearNU") && fields.device_is === "Replace" && <Box mt={2} mb={4} display={"flex"} flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"} border={"1px solid black"} width={"100%"} height={"8vh"} borderRadius={"4px"} p={3}>

                                <FormControl sx={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>
                                    {/* <FormLabel id="demo-radio-buttons-group-label"> ? :</FormLabel> */}
                                    <RadioGroup
                                        row
                                        sx={{ marginLeft: "10px" }}
                                        onChange={(e) => {
                                            SetReplace(e.target.value);
                                            setFields({ ...fields, err: '' })
                                        }}
                                    >
                                        {Object.keys(REPLACEMENT_BOX).map((item) => (
                                            <FormControlLabel
                                                disabled={loading}
                                                key={item}
                                                value={REPLACEMENT_BOX[item]}
                                                control={<Radio />}
                                                label={titleCase(item)}
                                            />
                                        ))}
                                    </RadioGroup>

                                </FormControl>

                            </Box>}

                            {fields.device_is === "Replace" && (selectedDispatch == DEVICES_BACK.YES) && ((replace === REPLACEMENT_BOX.NEW_BOX) || (fields.product_id?.name !== "HearNU")) &&

                                <PaddingBoxInDesktop sx={{ display: "flex", flex: 1, justifyContent: "flex-end", gap: "10px" }}  >

                                    <Box width={"100%"}>
                                        <FormControl color="primary" fullWidth variant="outlined">
                                            <InputLabel color="primary" htmlFor="outlined-adornment-boxid">
                                                New Box Id*
                                            </InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-boxid"
                                                value={fields?.new_box_id || ""}
                                                onChange={(e) => setFields({ ...fields, err: '', new_box_id: e.target.value })}
                                                type="text"
                                                autoFocus={true}
                                                disabled={loading}
                                                endAdornment={<>
                                                    {loading && <CircularProgress size={30} />}
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={onQrBtnClick} edge="end">
                                                            <QrCodeScanner />
                                                        </IconButton>
                                                    </InputAdornment>
                                                </>}
                                                color="primary"
                                                label="New Box Id*"
                                            />
                                        </FormControl>
                                    </Box>
                                    <AsyncDropDown
                                        defaultVal={
                                            fields.new_box_id ? {

                                                _id: fields._id,
                                                new_box_id: fields.new_box_id,
                                            }
                                                : null
                                        }
                                        lazyFun={async (para) => await getBoxnotallocatedboxIdApi({ ...para, allStatus: true, searchable: ["boxId", "sold.cphone"] })}
                                        OptionComponent={({ option, ...rest }) => {
                                            return <ListItem {...rest}>{option.boxId}</ListItem >
                                        }}
                                        onChange={async (changedVal) => {
                                            setFields({ ...fields, err: '', new_box_id: changedVal ? changedVal.boxId : null })
                                        }}
                                        titleKey={'boxId'}
                                        valueKey={"_id"}
                                        InputComponent={(params) => <CustomInput placeholder={"New Box Id*"} {...params} margin="none" />}
                                    />
                                </PaddingBoxInDesktop>}
                            {fields.device_is === "Replace" && replace === REPLACEMENT_BOX.HEAR_NU_BAND && <>
                                <Box gap={3} mt={2} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>

                                    <CustomInput
                                        disabled={loading}
                                        value={fields.band_box_id}
                                        onChange={(e) => setFields({ ...fields, err: '', band_box_id: e.target.value })}
                                        type="text"
                                        label={"Old Band Id.*"}
                                    />
                                    <CustomInput
                                        disabled={loading}
                                        value={fields.new_band_box_id}
                                        onChange={(e) => setFields({ ...fields, err: '', new_band_box_id: e.target.value })}
                                        type="text"
                                        label={"New Band Id.*"}
                                    />
                                </Box></>}
                            {fields.device_is === "Replace" && replace === REPLACEMENT_BOX.HEAD_SET && <>
                                <Box gap={3} mt={2} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                    <CustomInput
                                        disabled={loading}
                                        value={fields.head_set_id}
                                        onChange={(e) => setFields({ ...fields, err: '', head_set_id: e.target.value })}
                                        type="text"
                                        label={"New Head Set Id *"}
                                    />
                                </Box></>}
                            {(selectedDispatch == DEVICES_BACK.YES) && <>
                                <Autocomplete
                                    // disabled={statusPass !== STATUS.EVALUTION}
                                    value={productAccessories?.filter(accessories => fields?.product_accessories?.includes(accessories?.name)) ?? []}
                                    sx={{ width: "100%", color: "primary.main" }}
                                    multiple
                                    onChange={(event, newValue) => {
                                        setFields({
                                            ...fields,
                                            err: "",
                                            product_accessories: newValue.map((val) => val?.name),
                                        });
                                    }}
                                    options={productAccessories ?? []}
                                    getOptionLabel={(option) => option.name}
                                    renderTags={(tagValue, getTagProps) =>
                                        tagValue.map((option, index) => (
                                            <Chip label={option.name} {...getTagProps({ index })} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            margin="dense"
                                            {...params}
                                            label="Product Accessories"
                                            variant="outlined"
                                            placeholder="Selected Product Accessories"
                                        />
                                    )}
                                />
                                <Box gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>

                                    <CustomInput
                                        disabled={loading}
                                        value={fields.courier_partner_nmae}
                                        onChange={(e) => setFields({ ...fields, err: '', courier_partner_nmae: e.target.value })}
                                        type="text"
                                        label={" Courier Partner Name*"}
                                    />
                                    <CustomInput
                                        disabled={loading}
                                        value={fields.tracking_id}
                                        onChange={(e) => setFields({ ...fields, err: '', tracking_id: e.target.value })}
                                        type="text"
                                        label={" Tracking Id*"}
                                    />
                                </Box>

                            </>}
                        </Box>
                    </>
                )}
            </CustomDialog >
        </>
    );
}

export default memo(UpdateCreateQueryUi);


