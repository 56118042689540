import { Box, Button, ButtonGroup, Card, Chip, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import CustomDialog from '../../components/layouts/common/CustomDialog'
import moment from 'moment'
import { SOLUTIONS, STATUS, TEAM_STATUS, USER_LOG, USER_ROLES } from '../../utils/constants'
import { findObjectKeyByValue, titleCase, toTitleCase } from '../../utils/main'
import ButtonComponentsUi from './ButtonComponentsUi'

const CustomerInformation = ({ params, statusPass }) => {
    const [STATUSWiseData, setSTATUSWiseData] = useState(USER_LOG.CUSTOMER_INFORMATION);
    return (
        <>
            <CustomDialog
                id={"CustomerInformation"}
                title={`Customer Information`}
            >
                <ButtonGroup sx={{ flexDirection: "row", width: "100%", mt: 4, mb: 2 }} >
                    <Button
                        onClick={() => { setSTATUSWiseData(USER_LOG.CUSTOMER_INFORMATION); }}
                        variant={STATUSWiseData == USER_LOG.CUSTOMER_INFORMATION ? "contained" : "outlined"}
                    >
                        <Typography variant="body2" color={STATUSWiseData == USER_LOG.CUSTOMER_INFORMATION ? "white" : "primary"}>
                            {titleCase(findObjectKeyByValue(USER_LOG.CUSTOMER_INFORMATION, USER_LOG))}
                        </Typography>
                    </Button>
                    <Button
                        onClick={() => { setSTATUSWiseData(USER_LOG.USER_LOGS); }}
                        variant={STATUSWiseData == USER_LOG.USER_LOGS ? "contained" : "outlined"}
                    >
                        <Typography variant="body2" color={STATUSWiseData == USER_LOG.USER_LOGS ? "white" : "primary"}>
                            {titleCase(findObjectKeyByValue(USER_LOG.USER_LOGS, USER_LOG))}
                        </Typography>
                    </Button>
                </ButtonGroup>
                {STATUSWiseData == USER_LOG.CUSTOMER_INFORMATION && <>
                    <Box sx={{ minHeight: "27vh", width: "100%", border: "2px solid #1D013B", borderRadius: "10px" }}>
                        <Box p={2} sx={{ borderBottom: "1px solid #1D013B", backgroundColor: "rgba(29, 1, 59, 0.1)" }}>
                            <Typography variant='h4' color={"#1D013B"}>Customer Details :</Typography>
                        </Box>
                        <Grid container p={3} md={12}>

                            <Grid item mt={3} md={3} xs={3} sm={3} >
                                <Typography fontWeight="bold" variant="h6">Customer Name: </Typography>
                            </Grid>

                            <Grid item mt={3} md={3} xs={3} sm={3}>
                                <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                    {params.customer_name ? params?.customer_name : "NA"}
                                </Typography>
                            </Grid>

                            <Grid item mt={3} md={3} xs={3} sm={3} >
                                <Typography fontWeight="bold" variant="h6">Query Created Date: </Typography>
                            </Grid>

                            <Grid item mt={3} md={3} xs={3} sm={3}>
                                <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                    {moment(params?.createdAt).format("DD/MM/YYYY")}
                                </Typography>
                            </Grid>

                            {params?.ticket_no && <><Grid item mt={3} md={3} xs={3} sm={3}>
                                <Typography fontWeight="bold" variant="h6">Customer Ticket No : </Typography>
                            </Grid>

                                <Grid item mt={3} md={3} xs={3} sm={3}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {params?.ticket_no ? params?.ticket_no : "NA"}
                                    </Typography>
                                </Grid></>
                            }

                            <Grid item mt={3} md={3} xs={3} sm={3}>
                                <Typography fontWeight="bold" variant="h6">Customer Phone Number : </Typography>
                            </Grid>

                            <Grid item mt={3} md={3} xs={3} sm={3}>
                                <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                    {params?.customer_phone ? params?.customer_phone : "NA"}
                                </Typography>
                            </Grid>

                            <Grid item mt={3} md={3} xs={3} sm={3} >
                                <Typography fontWeight="bold" variant="h6">Customer Email: </Typography>
                            </Grid>

                            <Grid item mt={3} md={3} xs={3} sm={3}>
                                <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                    {params?.customer_email ? params?.customer_email : "NA"}
                                </Typography>
                            </Grid>

                            <Grid item mt={3} md={3} xs={3} sm={3} >
                                <Typography fontWeight="bold" variant="h6">Product Source: </Typography>
                            </Grid>

                            <Grid item mt={3} md={3} xs={3} sm={3}>
                                <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                    {params?.source_id?.title ? params?.source_id?.title : "NA"}
                                </Typography>
                            </Grid>

                        </Grid>

                    </Box>

                    <Box mt={2} sx={{ minHeight: "29vh", maxHeight: "60vh", width: "100%", border: "2px solid #1D013B", borderRadius: "10px" }}>
                        <Box p={2} sx={{ borderBottom: "1px solid #1D013B", backgroundColor: "rgba(29, 1, 59, 0.1)" }}>
                            <Typography variant='h4' color={"#1D013B"}>Product Problem Details :</Typography>
                        </Box>
                        <Grid container p={3} md={12}>

                            <Grid item mt={3} md={3} xs={3} sm={3} >
                                <Typography fontWeight="bold" variant="h6">  Name: </Typography>
                            </Grid>

                            <Grid item mt={3} md={3} xs={3} sm={3}>
                                <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                    {params?.product_id?.name ? params?.product_id?.name : "NA"}
                                </Typography>
                            </Grid>

                            <Grid item mt={3} md={3} xs={3} sm={3}>
                                <Typography fontWeight="bold" variant="h6">Device BoxId : </Typography>
                            </Grid>

                            <Grid item mt={3} md={3} xs={3} sm={3} mb={2}>
                                <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                    {params?.box_id ? params?.box_id : "NA"}
                                </Typography>
                            </Grid>
                            {params?.solved_in && <><Grid item md={3} xs={3} sm={3}>
                                <Typography fontWeight="bold" variant="h6">Mode Of Query Solving : </Typography>
                            </Grid>

                                <Grid item md={3} xs={3} sm={3}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {params?.solved_in === SOLUTIONS.CALL || params?.solved_in === SOLUTIONS.Videao_Call ?
                                            `Solved In ${toTitleCase(findObjectKeyByValue(params?.solved_in, SOLUTIONS))}` :
                                            params?.device_is === "Replace" ? "Replace Device" : statusPass === STATUS.REPAIR_COMPLETED || statusPass === STATUS.COMPLETED ? "Repairing Completed" : `In ${toTitleCase(findObjectKeyByValue(params?.solved_in, SOLUTIONS))}`}
                                    </Typography>
                                </Grid></>}
                            {<><Grid item md={3} xs={3} sm={3}>
                                <Typography fontWeight="bold" variant="h6">Device Manufacturer Date: </Typography>
                            </Grid>

                                <Grid item md={3} xs={3} sm={3}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {params?.manufacturer_date ? moment(params?.manufacturer_date).format("DD/MM/YYYY") : "NA"}
                                    </Typography>
                                </Grid></>}
                            {params?.warrenty_date && <><Grid item mt={3} md={3} xs={3} sm={3}>
                                <Typography fontWeight="bold" variant="h6">Device Purchase Date: </Typography>
                            </Grid>

                                <Grid item mt={3} md={3} xs={3} sm={3}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {params?.warrenty_date ? moment(params?.warrenty_date).format("DD/MM/YYYY") : "NA"}
                                    </Typography>
                                </Grid></>}
                        </Grid>
                        <Grid container ml={3} md={12}>
                            <Grid item md={3} xs={3} sm={3}>
                                <Typography fontWeight="bold" variant="h6">Customer problems : </Typography>
                            </Grid>

                            <Grid item md={9} xs={9} sm={9}>
                                <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                    {params?.problems.map((item) =>
                                        <Typography variant='h6' sx={{ border: "1px solid grey", borderRadius: "5px", backgroundColor: "rgba(29, 1, 59, 0.1)", padding: "5px", marginRight: 2, }}>
                                            {item}
                                        </Typography>)}
                                </Typography>
                            </Grid>

                            {params?.other && <><Grid item mt={3} md={3} xs={3} sm={3}>
                                <Typography fontWeight="bold" variant="h6">Customer problems Other: </Typography>
                            </Grid>

                                <Grid item mt={3} md={3} xs={3} sm={3}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {params?.other ? params?.other : "NA"}
                                    </Typography>
                                </Grid></>}
                        </Grid>
                    </Box>

                    <Box mt={2} sx={{ minHeight: "10vh", maxHeight: "80vh", width: "100%", border: "2px solid #1D013B", borderRadius: "10px" }}>
                        <Box p={2} sx={{ borderBottom: "1px solid #1D013B", backgroundColor: "rgba(29, 1, 59, 0.1)" }}>
                            <Typography variant='h4' color={"#1D013B"}>{statusPass === STATUS.NOT_DISPATCH ? "Dispatch Details" : (params?.device_is === "Repairable" || params?.device_is === "physical_damage") ? "Repair Details" : params?.device_is === "Replace" ? " Replace Details" : "Details View"}</Typography>
                        </Box>
                        <Grid container p={3} md={12}>


                            {params?.status && <><Grid item mt={3} md={3} xs={3} sm={3}>
                                <Typography fontWeight="bold" variant="h6">Query Status : </Typography>
                            </Grid>

                                <Grid item mt={3} md={9} xs={9} sm={9}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {params?.status[0].status_role === STATUS.START_EVALUTION ? "Pending Evaluation" : toTitleCase(findObjectKeyByValue(params?.status[0].status_role, STATUS))} ({toTitleCase(findObjectKeyByValue(params?.status[0].role, TEAM_STATUS))})
                                    </Typography>
                                </Grid></>}
                            {params?.product_accessories?.length > 0 && (
                                <>
                                    <Grid item md={3} xs={3} sm={3} display={"flex"} alignItems={"center"}>
                                        <Typography fontWeight="bold" variant="h6">Product Accessories:</Typography>
                                    </Grid>

                                    <Grid item md={9} xs={9} sm={9}>
                                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                                            {params?.product_accessories?.map((item, index) => (
                                                <Chip
                                                    key={index}
                                                    label={item}
                                                    variant="outlined"
                                                    sx={{
                                                        backgroundColor: "rgba(29, 1, 59, 0.1)",
                                                        borderColor: "grey",
                                                        color: "#808080",
                                                        fontWeight: "bold",
                                                    }}
                                                />
                                            ))}
                                        </Box>
                                    </Grid>
                                </>
                            )}
                            {params.completed_status && <> <Grid item mt={3} md={3} xs={3} sm={3}>
                                <Typography fontWeight="bold" variant="h6">Customer Device Status: </Typography>
                            </Grid>

                                <Grid item mt={3} md={3} xs={3} sm={3}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {params?.completed_status ? params?.completed_status : "NA"}
                                    </Typography>
                                </Grid></>}
                            {
                                params.repairing_amount_status && <>  <Grid item mt={3} md={3} xs={3} sm={3}>
                                    <Typography fontWeight="bold" variant="h6">Payment Status: </Typography>
                                </Grid>

                                    <Grid item mt={3} md={3} xs={3} sm={3}>
                                        <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                            {params.repairing_amount_status ? titleCase(params.repairing_amount_status) : "NA"}
                                        </Typography>
                                    </Grid></>}
                            {params?.courier_partner_nmae && <><Grid item mt={3} md={3} xs={3} sm={3}>
                                <Typography fontWeight="bold" variant="h6"> Courier Partner Name: </Typography>
                            </Grid>

                                <Grid item mt={3} md={3} xs={3} sm={3}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {params?.courier_partner_nmae ? titleCase(params?.courier_partner_nmae) : "NA"}
                                    </Typography>
                                </Grid></>}
                            {params?.tracking_id && <><Grid item mt={3} md={3} xs={3} sm={3}>
                                <Typography fontWeight="bold" variant="h6"> Device Tracking Id: </Typography>
                            </Grid>

                                <Grid item mt={3} md={3} xs={3} sm={3}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {params?.tracking_id ? titleCase(params?.tracking_id) : "NA"}
                                    </Typography>
                                </Grid></>}

                            {params?.device_is && <><Grid item mt={3} md={3} xs={3} sm={3}>
                                <Typography fontWeight="bold" variant="h6">Device type Problem: </Typography>
                            </Grid>

                                <Grid item mt={3} md={3} xs={3} sm={3}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {params?.device_is ? titleCase(params?.device_is) : "NA"}
                                    </Typography>
                                </Grid></>}
                            {
                                params?.physical_damage?.damage_part && <> <Grid item mt={3} md={3} xs={3} sm={3}>
                                    <Typography fontWeight="bold" variant="h6">Physical Damage part: </Typography>
                                </Grid>

                                    <Grid item mt={3} md={3} xs={3} sm={3}>
                                        <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                            {params?.physical_damage?.damage_part ? params?.physical_damage?.damage_part : "NA"}
                                        </Typography>
                                    </Grid></>}
                            {params?.physical_damage?.remarks && <><Grid item mt={3} md={3} xs={3} sm={3} >
                                <Typography fontWeight="bold" variant="h6">Physical Damage Remarks:</Typography>
                            </Grid>

                                <Grid container mt={3} md={3} xs={3} sm={3}>
                                    <Typography variant="h6" sx={{ display: "flex", color: "#808080", flexDirection: "column" }}>
                                        {params?.physical_damage?.remarks ? params?.physical_damage?.remarks : "NA"}

                                        {/* {params?.logs !== 0 ? params?.logs.map((e,index) => <Box sx={{ display: 'flex' }}>{ "("+ ++index + ")"} {e.remarks} </Box>) : "NA"} */}
                                    </Typography>
                                </Grid></>}

                            {
                                params?.physical_damage?.repairing_amount && <> <Grid item mt={3} md={3} xs={3} sm={3}>
                                    <Typography fontWeight="bold" variant="h6">Physical Damage part: </Typography>
                                </Grid>

                                    <Grid item mt={3} md={3} xs={3} sm={3}>
                                        <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                            {params?.physical_damage?.repairing_amount ? params?.physical_damage?.repairing_amount : "NA"}
                                        </Typography>
                                    </Grid></>}

                            {params?.repairable_part && <><Grid item mt={3} md={3} xs={3} sm={3}>
                                <Typography fontWeight="bold" variant="h6">Repairable Part: </Typography>
                            </Grid>

                                <Grid item mt={3} md={3} xs={3} sm={3}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {params?.repairable_part ? params?.repairable_part : "NA"}
                                    </Typography>
                                </Grid></>}
                            {params?.repairable_remarks && <><Grid item mt={3} md={3} xs={3} sm={3}>
                                <Typography fontWeight="bold" variant="h6">Repairable Remark: </Typography>
                            </Grid>

                                <Grid item mt={3} md={3} xs={3} sm={3}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {params?.repairable_remarks ? params?.repairable_remarks : "NA"}
                                    </Typography>
                                </Grid></>}
                            {((params?.repairing_amount) || (params?.repairing_amount == 0)) && <>     <Grid item mt={3} md={3} xs={3} sm={3}>
                                <Typography fontWeight="bold" variant="h6">Repairing Amount : </Typography>
                            </Grid>

                                <Grid item mt={3} md={3} xs={3} sm={3}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {params?.repairing_amount ? params?.repairing_amount : "NA"}
                                    </Typography>
                                </Grid></>}
                            {params?.replace_remarks && <>  <Grid item mt={3} md={3} xs={3} sm={3}>
                                <Typography fontWeight="bold" variant="h6">Replace Remark : </Typography>
                            </Grid>

                                <Grid item mt={3} md={3} xs={3} sm={3}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {params?.replace_remarks ? params?.replace_remarks : "NA"}
                                    </Typography>
                                </Grid></>
                            }
                            {
                                params.dispatch_remarks && <>  <Grid item mt={3} md={3} xs={3} sm={3}>
                                    <Typography fontWeight="bold" variant="h6">
                                        Cancel Payment Remark: </Typography>
                                </Grid>

                                    <Grid item mt={3} md={3} xs={3} sm={3}>
                                        <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                            {params.dispatch_remarks ? titleCase(params.dispatch_remarks) : "NA"}
                                        </Typography>
                                    </Grid></>}
                            {params?.new_box_id && <> <Grid item mt={3} md={3} xs={3} sm={3}>
                                <Typography fontWeight="bold" variant="h6">Device New Box Id : </Typography>
                            </Grid>

                                <Grid item mt={3} md={3} xs={3} sm={3}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {params?.new_box_id ? params?.new_box_id : "NA"}
                                    </Typography>
                                </Grid></>}
                            {params?.head_set_id && params.device_is === "Replace" && params?.product_id?.name === "HearNU" && <> <Grid item mt={3} md={3} xs={3} sm={3}>
                                <Typography fontWeight="bold" variant="h6">New Head Set Id : </Typography>
                            </Grid>

                                <Grid item mt={3} md={3} xs={3} sm={3}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {params?.head_set_id ? params?.head_set_id : "NA"}
                                    </Typography>
                                </Grid></>}
                            {params?.band_box_id && params.device_is === "Replace" && params?.product_id?.name === "HearNU" && <> <Grid item mt={3} md={3} xs={3} sm={3}>
                                <Typography fontWeight="bold" variant="h6">Old Band Id : </Typography>
                            </Grid>

                                <Grid item mt={3} md={3} xs={3} sm={3}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {params?.band_box_id ? params?.band_box_id : "NA"}
                                    </Typography>
                                </Grid></>}
                            {params?.new_band_box_id && params.device_is === "Replace" && params?.product_id?.name === "HearNU" && <> <Grid item mt={3} md={3} xs={3} sm={3}>
                                <Typography fontWeight="bold" variant="h6">New Band Id : </Typography>
                            </Grid>

                                <Grid item mt={3} md={3} xs={3} sm={3}>
                                    <Typography variant="h6" sx={{ display: "flex", justifyContent: "flex-start", color: "#808080" }}>
                                        {params?.new_band_box_id ? params?.new_band_box_id : "NA"}
                                    </Typography>
                                </Grid></>}
                        </Grid>
                    </Box>
                </>}

                {STATUSWiseData == USER_LOG.USER_LOGS &&
                    <>
                        <Card sx={{ marginBottom: 2, padding: 2, backgroundColor: "#1D013B", color: "white" }}>
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item xs={4}>
                                    <Typography variant="body2" sx={{ fontWeight: "bold", textAlign: "center" }}>Date</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography variant="body2" sx={{ fontWeight: "bold", textAlign: "center" }}>User Name</Typography>
                                </Grid>
                                {/* <Grid item xs={3}>
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>User Role</Typography>
            </Grid> */}
                                <Grid item xs={4}>
                                    <Typography variant="body2" sx={{ fontWeight: "bold", textAlign: "center" }}>Query Status</Typography>
                                </Grid>
                            </Grid>
                        </Card>

                        {params?.logs?.length > 0 ?
                            params.logs.map((item, index) => (
                                <Card key={index} sx={{ marginBottom: 2, padding: 2 }}>
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item xs={4} sx={{ textAlign: "center" }}>
                                            <Typography variant="body2">
                                                {moment(item?.date).format("DD/MM/YYYY")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} sx={{ textAlign: "center" }}>
                                            <Typography variant="body2">{item?.created_by}</Typography>
                                        </Grid>
                                        {/* <Grid item xs={3}>
                                                <Typography variant="body2">
                                                    {titleCase(findObjectKeyByValue(item?.role, USER_ROLES))}
                                                </Typography>
                                          </Grid> */}
                                        <Grid item xs={4}>
                                            <Typography variant="body2" sx={{ textAlign: "center" }}>
                                                {titleCase(findObjectKeyByValue(item?.status, STATUS))}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Card>
                            )) :
                            <Box sx={{ width: "100%", height: "10vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Typography> No Data Found </Typography>
                            </Box>
                        }
                    </>

                }
            </CustomDialog >
        </>
    )
}

export default CustomerInformation
