import axios from "axios";
import { getHeaders } from "../utils/main";
import endpoints from "./endpoints";

export const getsourceApi = async params => {
    const callResponse = await axios({
        url: endpoints.sourceFetch,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};

export const CreatesourceApi = async data => {
    const callResponse = await axios({
        url: endpoints.Createsource,
        method: "post",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};

export const DeletesourceApi = async data => {
    const callResponse = await axios({
        url: endpoints.Deletesource,
        method: "delete",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};

export const UpdatesourceApi = async data => {
    const callResponse = await axios({
        url: endpoints.Updatesource,
        method: "patch",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};

export const sourceFindByIdApi = async params => {
    const callResponse = await axios({
        url: endpoints.sourceById,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};