import { Autocomplete, Box, Button, Checkbox, Chip, CircularProgress, FormControlLabel, FormGroup, FormLabel, ListItem, Radio, TextField, Typography } from "@mui/material";
import { memo, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import CustomInput from "../../components/inputs/CustomInputs";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import { PROBLEMS, SOLVED_STATUS, SELECT_PRODUCT_LABLE, SOLUTIONS, SOLVED_STATUS_LABLE, PROBLEMS_LABLE, STATUS, USER_ROLES, PAYMENT_METHOD } from "../../utils/constants";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import moment from "moment";
import { findObjectKeyByValue } from "../../utils/main";
import { getProdutApi } from "../../apis/product.api";
import { StyledSearchBar } from "../../components/inputs/SearchBar"
import AsyncDropDown from "../../components/inputs/AsyncDropDown";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxDesktop";
import { getsourceApi } from "../../apis/source.api";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
// import { getTicketProblemsFetchApi } from "../../apis/repairing.api";
import { getBoxIdApi } from "../../apis/boxid.api";
import { CheckBox } from "@mui/icons-material";
import { DesktopDatePicker } from "@mui/x-date-pickers";


const UpdateCreateQueryUi = ({ statusPass, listProblem, title, modalKey, isUpdate, fields, setFields, loading, onSubmit, setvalidation, validation }) => {

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const user = useSelector(state => state.user)

    let isAlready = false;

    const todayDate = moment();
    const warrantyDate = moment(fields?.warrenty_date);

    const isLessThanOneYear = warrantyDate.isValid() && Math.trunc(((new Date(todayDate) - (new Date(warrantyDate))) / (1000 * 60 * 60 * 24))) / 365 <= 1;
    const isLessThanThreeYear = warrantyDate.isValid() && Math.trunc(((new Date(todayDate) - (new Date(warrantyDate))) / (1000 * 60 * 60 * 24))) / 365 <= 3;
console.log(" status data ",fields.status)
    return (
        <>
            <CustomDialog
                id={`UpdateQuery`}
                loading={loading}
                err={fields.err}
                onSubmit={onSubmit}
                title={` Update ${title}`}
                closeText="Close"
                confirmText={`Update`}
            >
                {loading ? (
                    <CenteredBox><CircularProgress /></CenteredBox>
                ) : (
                    <>
                        <Box width={"90%"} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                            <Box display={"flex"} flexDirection={"column"}>
                                <Typography>
                                    Ticket Number :{fields?.ticket_no}
                                </Typography>
                            </Box>
                            <Box display={"flex"} flexDirection={"column"}>
                                <Typography>
                                    Date :{moment(fields?.createdAt).format("DD/MM/YYYY")}
                                </Typography>
                            </Box>
                        </Box>
                        <Box mt={3} width={"100%"}>
                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                                <AsyncDropDown
                                    disabled={user.data.role !== USER_ROLES.ADMIN}

                                    defaultVal={
                                        fields.product_id?._id ? {
                                            _id: fields.product_id?._id,
                                            name: fields.product_id?.name,
                                        } : null
                                    }
                                    lazyFun={async (para) => await getProdutApi({ ...para, allStatus: true })}
                                    OptionComponent={({ option, ...rest }) => {
                                        return <ListItem {...rest}>{option.name}</ListItem >
                                    }}
                                    onChange={async (changedVal) => {
                                        console.log("first changedVal", changedVal)
                                        setFields({ ...fields, product_id: changedVal ? changedVal : null, })
                                    }}
                                    titleKey={'name'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <StyledSearchBar placeholder={"Select Product"} {...params} margin="none" />}
                                />
                            </PaddingBoxInDesktop>

                            <Box mt={3}>
                                <Typography variant="h4">
                                    Customer Details :
                                </Typography>
                            </Box>
                            <CustomInput
                                // autoFocus={true}
                                disabled={user.data.role !== USER_ROLES.ADMIN}
                                value={fields.box_id}
                                onChange={(e) => setFields({ ...fields, err: '', box_id: e.target.value })}
                                type="text"
                                label={" Box Id.*"}
                            />
                            <Box mt={1} gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                <CustomInput
                                    autoFocus={true}
                                    disabled={user.data.role !== USER_ROLES.ADMIN}
                                    value={fields.customer_name}
                                    onChange={(e) => setFields({ ...fields, err: '', customer_name: e.target.value })}
                                    type="text"
                                    label={"Customer Name*"}
                                />
                                <CustomInput
                                    // autoFocus={true}
                                    disabled={user.data.role !== USER_ROLES.ADMIN}
                                    value={fields.customer_email}
                                    onChange={(e) => setFields({ ...fields, err: '', customer_email: e.target.value })}
                                    type="text"
                                    label={"Email"}
                                />

                            </Box>
                            <Box gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                <CustomInput
                                    // autoFocus={true}
                                    disabled={user.data.role !== USER_ROLES.ADMIN}
                                    value={fields.customer_phone}
                                    onChange={(e) => setFields({ ...fields, err: '', customer_phone: e.target.value })}
                                    type="number"
                                    label={" Phone No.*"}
                                />
                                <CustomInput
                                    // autoFocus={true}
                                    disabled={user.data.role !== USER_ROLES.ADMIN}
                                    value={fields.customer_address}
                                    onChange={(e) => setFields({ ...fields, err: '', customer_address: e.target.value })}
                                    type="text"
                                    label={"Address*"}
                                />

                            </Box>
                            <PaddingBoxInDesktop mt={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end", width: "100%" }}  >
                                <AsyncDropDown
                                    defaultVal={
                                        fields.source_id?._id ? {
                                            _id: fields.source_id?._id,
                                            title: fields.source_id?.title,
                                        } : null
                                    }
                                    disabled={user.data.role !== USER_ROLES.ADMIN}

                                    lazyFun={async (para) => await getsourceApi({ ...para, allStatus: true })}
                                    OptionComponent={({ option, ...rest }) => {
                                        return <ListItem {...rest}>{option.title}</ListItem >
                                    }}
                                    onChange={async (changedVal) => {
                                        setFields({ ...fields, source_id: changedVal ? changedVal._id : null, })
                                    }}
                                    titleKey={'title'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <StyledSearchBar placeholder={"Source*"} {...params} margin="none" />}
                                />
                            </PaddingBoxInDesktop>
                            <Box gap={3} mt={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                <Autocomplete
                                    disabled={user.data.role !== USER_ROLES.ADMIN ? statusPass === STATUS.EVALUTION ? false : true : false}
                                    value={listProblem?.filter(problem => fields.problems.includes(problem.name)) || []}
                                    sx={{ width: "100%", color: "primary.main" }}
                                    multiple
                                    onChange={(event, newValue) => {
                                        setFields({
                                            ...fields,
                                            err: "",
                                            problems: newValue.map((val) => val?.name),
                                        });
                                    }}
                                    options={listProblem ?? []}
                                    getOptionLabel={(option) => option.name}
                                    renderTags={(tagValue, getTagProps) =>
                                        tagValue.map((option, index) => (
                                            <Chip label={option.name} {...getTagProps({ index })} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            margin="dense"
                                            {...params}
                                            label="Problems*"
                                            variant="outlined"
                                            placeholder="Selected Problems"
                                        />
                                    )}
                                />
                                {fields.other && <CustomInput
                                    // autoFocus={true}
                                    disabled={user.data.role !== USER_ROLES.ADMIN}
                                    value={fields.other}
                                    onChange={(e) => setFields({ ...fields, err: '', other: e.target.value })}
                                    type="text"
                                    label={" Other Problems"}
                                />}
                            </Box>
                            <Box gap={3} mt={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                    <Autocomplete
                                    disabled={user.data.role !== USER_ROLES.ADMIN ? statusPass === STATUS.EVALUTION ? false : true : false}
                                    value={fields?.product_accessories?.filter(accessories => fields.product_accessories.includes(accessories)) || []}
                                        sx={{ width: "100%", color: "primary.main" }}
                                        multiple
                                        onChange={(event, newValue) => {
                                            setFields({
                                                ...fields,
                                                err: "",
                                                product_accessories: newValue.map((val) => val?.name),
                                            });
                                        }}
                                        options={fields?.product_accessories ?? []}
                                        getOptionLabel={(option) => option}
                                        renderTags={(tagValue, getTagProps) =>
                                            tagValue.map((option, index) => (
                                                <Chip label={option} {...getTagProps({ index })} />
                                            ))
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                margin="dense"
                                                {...params}
                                                label="Product Accessories*"
                                                variant="outlined"
                                                placeholder="Selected Product Accessories"
                                            />
                                        )}
                                    />
                                </Box>
                            {statusPass === STATUS.DEVICE_RECEIVED && <FormControl >
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={(e) => {
                                                    setFields({
                                                        ...fields,
                                                        err: "",
                                                        status: [

                                                            {
                                                                status_role: STATUS.REPAIR_START,
                                                                role: USER_ROLES.REPAIR_TEAM
                                                            },
                                                            {
                                                                status_role: STATUS.SEND_TO_REPAIR,
                                                                role: USER_ROLES.CRM_TEAM
                                                            }, {
                                                                status_role: STATUS.SEND_TO_REPAIR,
                                                                role: USER_ROLES.LD
                                                            }
                                                        ]
                                                    });
                                                }}
                                            />
                                        }
                                        label="Repairing Start"
                                        labelPlacement="end"
                                    />
                                </FormGroup>
                            </FormControl>}
                            {(statusPass === STATUS.REPAIR_START) && <>

                                <DesktopDatePicker
                                    disableFuture
                                    inputFormat="DD/MM/yyyy"
                                    renderInput={(props) => <CustomInput {...props} />}
                                    autoFocus={true}
                                    disabled={user.data.role !== USER_ROLES.ADMIN}
                                    label="Purchase Date"
                                    value={fields?.warrenty_date ? moment(fields?.warrenty_date) : null}
                                    onChange={(val) => {
                                        if (val && val.isValid()) {
                                            const isoString = val.toISOString();
                                            setFields({
                                                ...fields,
                                                err: "",
                                                warrenty_date: isoString,
                                            });
                                        } else {
                                            setFields({
                                                ...fields,
                                                err: "",
                                                warrenty_date: "",
                                            });
                                        }
                                    }}
                                />
                                <Box mt={2} mb={3} >
                                    <Typography variant="h3">Device is :</Typography>
                                    <Box mt={3}
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-around",
                                            border: "1px solid black",
                                            borderRadius: "8px",
                                            padding: "7px",
                                            "@media (min-width: 490px)": {
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "space-around",
                                            },
                                        }}
                                    >
                                        {/* <FormControl > */}
                                        {/* <FormGroup component="fieldset" row> */}
                                        <FormControlLabel
                                            control={
                                                <Radio
                                                    checked={fields.device_is === "physical_damage"}
                                                    onChange={(e) => {
                                                        setFields({
                                                            ...fields,
                                                            err: "",
                                                            device_is: e.target.name,
                                                            repairable_remarks: "",
                                                            replace_remarks: "",
                                                            repairing_amount: "",
                                                            repairable_part: "",
                                                            status: [
                                                                // ...fields.status,, 
                                                                {
                                                                    status_role: STATUS.WAITING_FOR_PAYMENT,
                                                                    role: USER_ROLES.CRM_TEAM
                                                                },
                                                                {
                                                                    status_role: STATUS.WAITING_FOR_PAYMENT,
                                                                    role: USER_ROLES.LD
                                                                }, {
                                                                    status_role: STATUS.WAITING_FOR_PAYMENT,
                                                                    role: USER_ROLES.REPAIR_TEAM
                                                                }

                                                            ]
                                                        });
                                                    }}
                                                    name="physical_damage"
                                                />
                                            }
                                            label="Physical Damage"
                                            labelPlacement="end"
                                        />

                                        <FormControlLabel
                                            control={
                                                <Radio
                                                    checked={fields.device_is === "Repairable"}
                                                    onChange={(e) => {
                                                        let statusdata = [];
                                                        let isBatteryNot = false;
                                                        fields.problems.forEach((item) => {
                                                            const isBattery = item.toLowerCase() === "battery";
                                                            if (item.toLowerCase() === "battery") {
                                                                isBatteryNot = true;
                                                            }
                                                            if ((!isBattery && !isLessThanThreeYear) || (isBattery && !isLessThanOneYear) || ((!isBattery && isBatteryNot) && !isLessThanOneYear)) {
                                                                statusdata = [
                                                                    { status_role: STATUS.WAITING_FOR_PAYMENT, role: USER_ROLES.CRM_TEAM },
                                                                    { status_role: STATUS.WAITING_FOR_PAYMENT, role: USER_ROLES.LD },
                                                                    { status_role: STATUS.WAITING_FOR_PAYMENT, role: USER_ROLES.REPAIR_TEAM }
                                                                ];
                                                            } else if ((!isBattery && isLessThanThreeYear) || (isBattery && isLessThanOneYear)) {
                                                                statusdata = [
                                                                    { status_role: STATUS.DISPATCH, role: USER_ROLES.LD },
                                                                    { status_role: STATUS.REPAIR_COMPLETED, role: USER_ROLES.REPAIR_TEAM },
                                                                    { status_role: STATUS.DISPATCH, role: USER_ROLES.CRM_TEAM }
                                                                ];
                                                            }
                                                        });
                                                        setFields({
                                                            ...fields,
                                                            err: "",
                                                            device_is: e.target.name,
                                                            physical_damage: {},
                                                            replace_remarks: "",
                                                            status: statusdata
                                                        });
                                                    }}
                                                    name="Repairable"
                                                />
                                            }
                                            label="Repairable"
                                            labelPlacement="end"
                                        />

                                        <FormControlLabel
                                            control={
                                                <Radio
                                                    checked={fields.device_is === "Replace"}
                                                    onChange={(e) => {
                                                        setFields({
                                                            ...fields,
                                                            err: "",
                                                            repairable_remarks: "",
                                                            repairing_amount: "",
                                                            repairable_part: "",
                                                            physical_damage: {},
                                                            device_is: e.target.name,
                                                            status: [

                                                                {
                                                                    status_role: STATUS.DISPATCH,
                                                                    role: USER_ROLES.LD
                                                                },

                                                                {
                                                                    status_role: STATUS.REPLACEMENT,
                                                                    role: USER_ROLES.REPAIR_TEAM
                                                                },

                                                                {
                                                                    status_role: STATUS.DISPATCH,
                                                                    role: USER_ROLES.CRM_TEAM
                                                                }

                                                            ]
                                                        });
                                                    }}
                                                    name="Replace"
                                                />
                                            }
                                            label="Replacement"
                                            labelPlacement="end"
                                        />

                                        {/* </FormGroup> */}
                                        {/* </FormControl> */}
                                    </Box>
                                </Box>

                                {fields.device_is === "Repairable" && <Box mt={1} gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>

                                    <CustomInput
                                        autoFocus={true}
                                        disabled={loading}
                                        value={fields?.repairable_part}
                                        onChange={(e) => setFields({ ...fields, err: '', repairable_part: e.target.value })}
                                        type="text"
                                        label={"Which part you repair*"}
                                    />
                                    <CustomInput
                                        // autoFocus={true}
                                        disabled={loading}
                                        value={fields?.repairable_remarks}
                                        onChange={(e) => setFields({ ...fields, err: '', repairable_remarks: e.target.value })}
                                        type="text"
                                        label={"Remarks*"}
                                    />
                                    {fields.problems.map((item) => {
                                        if (isAlready) {
                                            return null;
                                        }
                                        return <>
                                            {(item.toLowerCase() === "battery" && !isLessThanOneYear) && (
                                                <>
                                                    {isAlready = true}
                                                    {setvalidation(true)}
                                                    <CustomInput
                                                        disabled={loading}
                                                        value={fields?.repairing_amount}
                                                        onChange={(e) => setFields({ ...fields, err: '', repairing_amount: e.target.value })}
                                                        type="number"
                                                        label={"Cost*"}
                                                    />
                                                </>
                                            )}
                                            {(item.toLowerCase() != "battery" && !isLessThanThreeYear) && (
                                                <>
                                                    {isAlready = true}

                                                    {setvalidation(true)}
                                                    <CustomInput
                                                        disabled={loading}
                                                        value={fields?.repairing_amount}
                                                        onChange={(e) => setFields({ ...fields, err: '', repairing_amount: e.target.value })}
                                                        type="number"
                                                        label={"Cost*"}
                                                    />
                                                </>
                                            )}
                                        </>
                                    }

                                    )}

                                </Box>}
                                {fields.device_is === "Replace" && <Box mt={1} gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                    <CustomInput
                                        autoFocus={true}
                                        disabled={loading}
                                        value={fields?.replace_remarks}
                                        onChange={(e) => setFields({ ...fields, err: '', replace_remarks: e.target.value })}
                                        type="text"
                                        label={"Remarks*"}
                                    />
                                </Box>}

                                {fields.device_is === "physical_damage" && <Box mt={1} gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                    <CustomInput
                                        autoFocus={true}
                                        disabled={loading}
                                        value={fields?.physical_damage?.damage_part}
                                        onChange={(e) => setFields({ ...fields, err: '', physical_damage: { ...fields.physical_damage, damage_part: e.target.value } })}
                                        type="text"
                                        label={"Damage Part*"}
                                    />
                                    <CustomInput
                                        // autoFocus={true}
                                        disabled={loading}
                                        value={fields?.physical_damage?.repairing_amount}
                                        onChange={(e) => setFields({ ...fields, err: '', physical_damage: { ...fields.physical_damage, repairing_amount: e.target.value } })}
                                        type="number"
                                        label={"Cost*"}
                                    />
                                    <CustomInput
                                        // autoFocus={true}
                                        disabled={loading}
                                        value={fields?.physical_damage?.remarks}
                                        onChange={(e) => setFields({ ...fields, err: '', physical_damage: { ...fields.physical_damage, remarks: e.target.value } })}
                                        type="text"
                                        label={"Remarks*"}
                                    />

                                </Box>}

                            </>}

                            {(statusPass === STATUS.PAYMENT_RECEIVED) && <>
                                {(fields.device_is === "physical_damage") &&
                                    <Box mt={1} gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                        <CustomInput
                                            autoFocus={true}
                                            disabled={user.data.role !== USER_ROLES.ADMIN}

                                            value={fields?.physical_damage?.damage_part}
                                            onChange={(e) => setFields({ ...fields, err: '', physical_damage: { ...fields.physical_damage, damage_part: e.target.value } })}
                                            type="text"
                                            label={"Damage Part*"}
                                        />
                                        <CustomInput
                                            // autoFocus={true}
                                            disabled={user.data.role !== USER_ROLES.ADMIN}

                                            value={fields?.physical_damage?.repairing_amount}
                                            onChange={(e) => setFields({ ...fields, err: '', physical_damage: { ...fields.physical_damage, repairing_amount: e.target.value } })}
                                            type="number"
                                            label={"Cost*"}
                                        />
                                        <CustomInput
                                            // autoFocus={true}
                                            disabled={user.data.role !== USER_ROLES.ADMIN}

                                            value={fields?.physical_damage?.remarks}
                                            onChange={(e) => setFields({ ...fields, err: '', physical_damage: { ...fields.physical_damage, remarks: e.target.value } })}
                                            type="text"
                                            label={"Remarks*"}
                                        />


                                    </Box>}
                                {fields.device_is === "Repairable" && <Box mt={1} mb={2} gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>

                                    <CustomInput
                                        autoFocus={true}
                                        disabled={user.data.role !== USER_ROLES.ADMIN}

                                        value={fields?.repairable_part}
                                        onChange={(e) => setFields({ ...fields, err: '', repairable_part: e.target.value })}
                                        type="text"
                                        label={"Which part you repair*"}
                                    />
                                    <CustomInput
                                        // autoFocus={true}
                                        disabled={user.data.role !== USER_ROLES.ADMIN}

                                        value={fields?.repairable_remarks}
                                        onChange={(e) => setFields({ ...fields, err: '', repairable_remarks: e.target.value })}
                                        type="text"
                                        label={"Remarks*"}
                                    />
                                    {fields.problems.map((item) => {

                                        if (isAlready) {
                                            return null;
                                        }
                                        return <>
                                            {(item.toLowerCase() === "battery" && !isLessThanOneYear) && (
                                                <> {isAlready = true}
                                                    <CustomInput
                                                        disabled={user.data.role !== USER_ROLES.ADMIN}

                                                        value={fields?.repairing_amount}
                                                        onChange={(e) => setFields({ ...fields, err: '', repairing_amount: e.target.value })}
                                                        type="number"
                                                        label={"Cost*"}
                                                    />
                                                </>
                                            )}
                                            {(item.toLowerCase() != "battery" && !isLessThanThreeYear) && (
                                                <> {isAlready = true}
                                                    <CustomInput
                                                        disabled={user.data.role !== USER_ROLES.ADMIN}

                                                        value={fields?.repairing_amount}
                                                        onChange={(e) => setFields({ ...fields, err: '', repairing_amount: e.target.value })}
                                                        type="number"
                                                        label={"Cost*"}
                                                    />
                                                </>
                                            )}
                                        </>
                                    }

                                    )}

                                </Box>}
                                <FormControl >
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onChange={(e) => {
                                                        setFields({
                                                            ...fields,
                                                            err: "",
                                                            status: fields.repairing_amount_status == "RETURN_DEVICE" ?
                                                                [
                                                                    
                                                                    {
                                                                        status_role: STATUS.DEMO,
                                                                        role: USER_ROLES.REPAIR_TEAM
                                                                    },
                                                                    {
                                                                        status_role: STATUS.DEMO,
                                                                        role: USER_ROLES.LD
                                                                    },
                                                                    {
                                                                        status_role: STATUS.DEMO,
                                                                        role: USER_ROLES.CRM_TEAM
                                                                    }
                                                                    // ,
                                                                    // {
                                                                    //     status_role: STATUS.REPAIR_COMPLETED,
                                                                    //     role: USER_ROLES.REPAIR_TEAM
                                                                    // }
                                                                ]
                                                                :
                                                                [
                                                                    {
                                                                        status_role: STATUS.DISPATCH,
                                                                        role: USER_ROLES.LD
                                                                    },
                                                                    {
                                                                        status_role: STATUS.DISPATCH,
                                                                        role: USER_ROLES.CRM_TEAM
                                                                    },

                                                                    {
                                                                        status_role: STATUS.REPAIR_COMPLETED,
                                                                        role: USER_ROLES.REPAIR_TEAM
                                                                    }
                                                                ]
                                                        });
                                                    }}
                                                />
                                            }
                                            label="Repairing Complete"
                                            labelPlacement="end"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </>}
                        </Box>
                    </>
                )}
            </CustomDialog >
        </>
    );
}

export default memo(UpdateCreateQueryUi);



{/* <Box mt={4} width={"100%"}>
                                <Box>
                                    <Typography variant="h3">
                                        Solved In :
                                    </Typography>
                                </Box>
                                <Box mt={4} gap={2} display={"flex"} justifyContent={"space-around"} flexDirection={"row"} >
                                    <Box>
                                        <Button
                                            variant={fields.solved_in === "call" || variant1 === SOLUTIONS.CALL ? "contained" : "outlined"}
                                            onClick={(e) => {
                                                setVariant(SOLUTIONS.CALL);
                                                setFields({ ...fields, err: '', solved_in: SOLUTIONS.CALL })
                                            }
                                            }
                                            sx={{ width: "19.5vw" }}>
                                            <Typography>Call</Typography>
                                        </Button>
                                    </Box>
                                    <Box>
                                        <Button onClick={() => {
                                            setVariant(SOLUTIONS.Videao_Call);
                                            setFields({ ...fields, err: '', solved_in: SOLUTIONS.Videao_Call })
                                        }}
                                            variant={fields.solved_in === "vc" || variant1 === SOLUTIONS.Videao_Call ? "contained" : "outlined"}
                                            sx={{ width: "19.5vw" }}>
                                            <Typography>Video Call</Typography>
                                        </Button>
                                    </Box>
                                    <Box>
                                        <Button onClick={() => {
                                            setVariant(SOLUTIONS.REPAIR);
                                            setFields({ ...fields, err: '', solved_in: SOLUTIONS.REPAIR })
                                        }}
                                            variant={fields.solved_in === "repairing" || variant1 === SOLUTIONS.REPAIR ? "contained" : "outlined"}
                                            sx={{ width: "19.5vw" }}>
                                            <Typography>Repair</Typography>
                                        </Button>
                                    </Box>
                                </Box>
                            </Box> */}


{/* {variant1 === SOLUTIONS.REPAIR && <PaddingBoxInDesktop mt={4} sx={{ display: "flex", flex: 1, justifyContent: "flex-end", width: "100%" }}  >
                                    <AsyncDropDown
                                        lazyFun={async (para) => await getTicketProblemsFetchApi({ ...para, allStatus: true })}
                                        OptionComponent={({ option, ...rest }) => {
                                            return <ListItem {...rest}>{option.name}</ListItem >
                                        }}
                                        defaultVal={
                                            fields.status ? {
                                                _id: fields.status._id,
                                                name: fields.status.name,
                                            } : null
                                        }
                                        onChange={async (changedVal) => {
                                            setFields({ ...fields, status: changedVal ? changedVal._id : null, })
                                        }}
                                        titleKey={'name'}
                                        valueKey={"_id"}
                                        InputComponent={(params) => <StyledSearchBar placeholder={"Repairing Ticket Problems*"} {...params} margin="none" />}
                                    />
                                </PaddingBoxInDesktop>}
                                {variant1 === SOLUTIONS.REPAIR &&
                                    <Box mt={3}>
                                        <Autocomplete

                                            options={SOLVED_STATUS}
                                            // defaultValue={
                                            //     fields.disibility_type
                                            //         ? fields.disibility_type?.map((item) =>
                                            //             DISIBILITY_TYPE.find((e) => item == e.value)
                                            //         )
                                            //         : []
                                            // }
                                            onChange={(event, newValue) => {
                                                setFields({
                                                    ...fields,
                                                    err: "",
                                                    solvedProblem: newValue.label,
                                                });
                                            }}
                                            disableCloseOnSelect
                                            getOptionLabel={(option) => option.label}
                                            renderOption={(props, option, { selected }) => {
                                                const { key, ...optionProps } = props;
                                                return (
                                                    <li key={key} {...optionProps}>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option.label}
                                                    </li>
                                                );
                                            }}
                                            style={{ width: "100%" }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Repairing status*" placeholder="" />
                                            )}
                                        />
                                    </Box>}

                                {variant1 === SOLUTIONS.REPAIR && <Box mt={1} gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                    <CustomInput
                                        disabled={loading}
                                        value={fields.remarks}
                                        onChange={(e) => setFields({ ...fields, err: '', remarks: e.target.value })}
                                        type="text"
                                        label={"Remarks*"}
                                    />
                                </Box>} */}