export const mode = "development"; //process.env.REACT_APP_APP_MODE;
let domain = "";

switch (mode) {
  case "ip":
    domain = "http://10.10.81.35:8001/";
    break;
  case "local":
    domain = "http://localhost:8001/";
    break;
  case "development":
    domain = "https://wehear-crm-development.up.railway.app/";
    break;
  case "production":
    domain = "https://wehear-crm-production.up.railway.app/";
    break;
  default:
    domain = "http://localhost:8000/";
}

export default {
  root: domain,
  signIn: `${domain}api/repair-auth/login`,
  resetToken: `${domain}api/repair-auth/reset-token`,
  resetpassword: `${domain}api/repair-auth/reset-password`,

  userAndSystemFetchByToken: `${domain}api/repair-user/fetch-by-token`,
  UserResetPassword: `${domain}api/repair-user/reset-password`,

  fileOrImage: `${domain}api/file/`,
  file: `${domain}api/file/images/`,
  image: `${domain}api/file/images/`,
  DeleteFile: `${domain}api/file/`,


  UserCreateApi: `${domain}api/repair-user/`,
  UserFetchByIdApi: `${domain}api/repair-user/fetch-by-id`,
  UserUpdateApi: `${domain}api/repair-user/`,
  UserFetchApi: `${domain}api/repair-user/`,
  UserDelete: `${domain}api/repair-user/`,
  UserUndoDelete: `${domain}api/repair-user/undo`,

  UserSearch: `${domain}api/repair-user/search`,


  RepairingTicketGenrator: `${domain}api/repair-ticket/`,
  RepairingTicketCreate: `${domain}api/repair-query/`,
  RepairingTicketFetch: `${domain}api/repair-query/`,
  RepairingTicketStatusChange: `${domain}api/repair/ticket/status`,
  RepairingTicketStatusById: `${domain}api/repair-query/by-id`,
  RepairingTicketStatusUpdate: `${domain}api/repair-query/`,
  RepairingTicketStatusCount: `${domain}api/repair-query/count`,
  StatusCount: `${domain}api/repair-query/status-count`,

  BoxIDFetch: `${domain}api/repair-query/box-id`,
  notallocatedboxidFetch: `${domain}api/repair-query/not-allocated-box-id`,
  changeboxIdfordms: `${domain}api/repair-query/change-boxId`,

  CreateProblem: `${domain}api/repair-problem/`,
  FetchProblem: `${domain}api/repair-problem/`,
  DeleteProblem: `${domain}api/repair-problem/`,
  UpdateProblem: `${domain}api/repair-problem/`,
  ProblemById: `${domain}api/repair-problem/by-id`,

  produtFetch: `${domain}api/repair-product/`,
  CreateProduct: `${domain}api/repair-product/`,
  DeleteProduct: `${domain}api/repair-product/`,
  UpdateProduct: `${domain}api/repair-product/`,
  ProductById: `${domain}api/repair-product/by-id`,

  sourceFetch: `${domain}api/repair-source/`,
  Createsource: `${domain}api/repair-source/`,
  Deletesource: `${domain}api/repair-source/`,
  Updatesource: `${domain}api/repair-source/`,
  sourceById: `${domain}api/repair-source/by-id`,

  productAccessoriesFetch: `${domain}api/repair-productAccessories/`,
  CreateproductAccessories: `${domain}api/repair-productAccessories/`,
  DeleteproductAccessories: `${domain}api/repair-productAccessories/`,
  UpdateproductAccessories: `${domain}api/repair-productAccessories/`,
  productAccessoriesById: `${domain}api/repair-productAccessories/by-id`,

  FetchQueryAdmin: `${domain}api/repair-query/admin`,

  TicketProblemsFetch: `${domain}api/repair/status`,

  BoxIdFetch: `${domain}api/repair-query/box-id`,

  notificationFetch: `${domain}api/repair-notification/`,

  whatsappFetch: `https://backend.api-wa.co/campaign/jam-research-services/api/v2`
}