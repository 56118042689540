import { Autocomplete, Box, Button, ButtonGroup, Checkbox, Chip, CircularProgress, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, InputAdornment, ListItem, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import CustomInput from "../../components/inputs/CustomInputs";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import { PROBLEMS, SOLVED_STATUS, SELECT_PRODUCT_LABLE, SOLUTIONS, SOLVED_STATUS_LABLE, PROBLEMS_LABLE, STATUS, USER_ROLES, PAYMENT_METHOD, CANCEL_PAYMENT, DEVICES_BACK, REPLACEMENT_BOX } from "../../utils/constants";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import moment from "moment";
import { findObjectKeyByValue, titleCase } from "../../utils/main";
import { getProdutApi } from "../../apis/product.api";
import { StyledSearchBar } from "../../components/inputs/SearchBar"
import AsyncDropDown from "../../components/inputs/AsyncDropDown";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxDesktop";
import { getsourceApi } from "../../apis/source.api";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
// import { getTicketProblemsFetchApi } from "../../apis/repairing.api";
import { getBoxIdApi, getBoxnotallocatedboxIdApi } from "../../apis/boxid.api";
import { CheckBox, QrCodeScanner } from "@mui/icons-material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import ButtonComponentsUi from "./ButtonComponentsUi";
import OtpInput from "../../components/OtpInput";
import { closeModal, openModal } from "../../store/actions/modalAction";
import QrScannerPopup from "../../components/Scanner/QrScannerPopup";
import FileInput from "../../components/upload/FileInput";

const CreateUI = ({ statusPass, user, setVariant, variant1, BoxIDAutoCompleted, setFiltersBoxId, filtersBoxId, listProblem, title, modalKey, isUpdate, fields, setFields, loading, onSubmit }) => {
    const DropDown_BoxId = [fields?.box_id];
    const dispatch = useDispatch()

    const onScan = (data) => {
        setFields({ ...fields, err: '', box_id: data?.text })
    };
    const onQrBtnClick = () => {
        dispatch(
            openModal(
                <QrScannerPopup
                    setFields={setFields}
                    fields={fields}
                    onScan={onScan}
                    onClose={() => dispatch(closeModal("scanner"))}
                />,
                "sm",
                false,
                "scanner"
            )
        );
    };
    return (<>
        <Box mt={3} width={"100%"}>
            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                <AsyncDropDown
                    // disabled={user.data.role !== USER_ROLES.ADMIN}
                    defaultVal={
                        fields.product_id?._id ? {
                            _id: fields.product_id?._id,
                            name: fields.product_id?.name,
                        } : null
                    }
                    lazyFun={async (para) => await getProdutApi({ ...para, allStatus: true })}
                    OptionComponent={({ option, ...rest }) => {
                        return <ListItem {...rest}>{option.name}</ListItem >
                    }}
                    onChange={async (changedVal) => {
                        setFields({ ...fields, product_id: changedVal ? changedVal._id : null, })
                    }}
                    titleKey={'name'}
                    valueKey={"_id"}
                    InputComponent={(params) => <CustomInput placeholder={"Select Product"} {...params} margin="none" />}
                />
            </PaddingBoxInDesktop>

            <Box mt={3}>
                <Typography variant="h4">
                    Customer Details :
                </Typography>
            </Box>
            <Box mt={1} gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <Box mt={1} width={"100%"}>
                    <FormControl color="primary" fullWidth variant="outlined">
                        <InputLabel color="primary" htmlFor="outlined-adornment-boxid">
                            Enter Box Id*
                        </InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-boxid"
                            value={fields?.box_id || ""}
                            onChange={(e) => setFields({ ...fields, err: '', box_id: e.target.value })}
                            type="text"
                            autoFocus={true}
                            disabled={loading}
                            endAdornment={<>
                                {loading && <CircularProgress size={30} />}
                                <InputAdornment position="end">
                                    <IconButton onClick={onQrBtnClick} edge="end">
                                        <QrCodeScanner />
                                    </IconButton>
                                </InputAdornment>
                            </>}
                            color="primary"
                            label="Enter Box Id*"
                        />
                    </FormControl>
                </Box>
                <Autocomplete
                    sx={{ width: "100%", color: "primary.main" }}
                    onChange={(event, newValue) => {
                        setFields({
                            ...fields,
                            err: "",
                            box_id: newValue?.boxId ?? "",
                            _id: newValue?._id ?? "",
                            customer_phone: newValue?.customer_phone ?? "",
                            customer_email: newValue?.customer_email ?? "",
                            customer_name: newValue?.customer_name ?? "",
                            customer_address: newValue?.customer_address ?? "",
                            band_box_id: newValue?.band_box_id ?? ""
                        });
                    }}
                    options={BoxIDAutoCompleted ?? []}
                    getOptionLabel={(option) => option.boxId}
                    renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                            <Chip label={option.boxId} {...getTagProps({ index })} />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            margin="dense"
                            {...params}
                            label="Box Id*"
                            variant="outlined"
                            placeholder="Selected Box Id"
                            onChange={(e) => setFiltersBoxId({ ...filtersBoxId, search: e.target.value })}
                        />
                    )}
                />
            </Box>
            <Box mt={1} gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <CustomInput
                    autoFocus={true}
                    disabled={loading}
                    value={fields.customer_name}
                    onChange={(e) => setFields({ ...fields, err: '', customer_name: e.target.value })}
                    type="text"
                    label={"Customer Name*"}
                />
                <CustomInput
                    // autoFocus={true}
                    disabled={loading}
                    value={fields.customer_email}
                    onChange={(e) => setFields({ ...fields, err: '', customer_email: e.target.value })}
                    type="text"
                    label={"Email"}
                />

            </Box>
            <Box gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <CustomInput
                    // autoFocus={true}
                    disabled={loading}
                    value={fields.customer_phone}
                    onChange={(e) => setFields({ ...fields, err: '', customer_phone: e.target.value })}
                    type="number"
                    label={" Phone No.*"}
                />
                <CustomInput
                    // autoFocus={true}
                    disabled={loading}
                    value={fields.customer_address}
                    onChange={(e) => setFields({ ...fields, err: '', customer_address: e.target.value })}
                    type="text"
                    label={"Address*"}
                />
            </Box>
            <DesktopDatePicker
                label="Manufacturing Date"
                disableFuture
                inputFormat="DD/MM/yyyy"
                renderInput={(props) => <CustomInput {...props} />}
                autoFocus={true}
                disabled={loading}
                value={fields?.manufacturer_date ? moment(fields?.manufacturer_date) : null}
                onChange={(val) => {
                    if (val && val.isValid()) {
                        const isoString = val.toISOString();
                        setFields({
                            ...fields,
                            err: "",
                            manufacturer_date: isoString,
                        });
                    } else {
                        setFields({
                            ...fields,
                            err: "",
                            manufacturer_date: "",
                        });
                    }
                }}
            />

            <PaddingBoxInDesktop mt={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end", width: "100%" }}  >
                <AsyncDropDown
                    // disabled={user.data.role !== USER_ROLES.ADMIN}
                    defaultVal={
                        fields.source_id?._id ? {
                            _id: fields.source_id?._id,
                            title: fields.source_id?.title,
                        } : null
                    }
                    lazyFun={async (para) => await getsourceApi({ ...para, allStatus: true })}
                    OptionComponent={({ option, ...rest }) => {
                        return <ListItem {...rest}>{option.title}</ListItem >
                    }}
                    onChange={async (changedVal) => {
                        setFields({ ...fields, source_id: changedVal ? changedVal._id : null, })
                    }}
                    titleKey={'title'}
                    valueKey={"_id"}
                    InputComponent={(params) => <CustomInput placeholder={"Source*"} {...params} margin="none" />}
                />
            </PaddingBoxInDesktop>
            <Box gap={3} mt={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <Autocomplete
                    // disabled={statusPass !== STATUS.EVALUTION}
                    value={listProblem?.filter(problem => fields.problems.includes(problem.name)) || []}
                    sx={{ width: "100%", color: "primary.main" }}
                    multiple
                    onChange={(event, newValue) => {
                        setFields({
                            ...fields,
                            err: "",
                            problems: newValue.map((val) => val?.name),
                        });
                    }}
                    options={listProblem ?? []}
                    getOptionLabel={(option) => option.name}
                    renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                            <Chip label={option.name} {...getTagProps({ index })} />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            margin="dense"
                            {...params}
                            label="Problems*"
                            variant="outlined"
                            placeholder="Selected Problems"
                        />
                    )}
                />

                {fields?.problems?.map((item) =>
                (item.toLowerCase() === "other" && <CustomInput
                    disabled={statusPass !== STATUS.EVALUTION}
                    value={fields.other}
                    onChange={(e) => setFields({ ...fields, err: '', other: e.target.value })}
                    type="text"
                    label={" Other Problems"}
                />
                ))}
            </Box>

            <Box mt={4}>
                <Box>
                    <Typography variant="h3">
                        Mode Of Query Solving :
                    </Typography>
                </Box>
                <Grid mt={2} container spacing={3} justifyContent="space-between" alignItems="center" width="100%">
                    <Grid item xs={4} sm={4} md={4}>
                        <Button
                            variant={fields.solved_in === SOLUTIONS.CALL || variant1 === SOLUTIONS.CALL ? "contained" : "outlined"}
                            onClick={(e) => {
                                setVariant(SOLUTIONS.CALL);
                                setFields({
                                    ...fields, err: '', solved_in: SOLUTIONS.CALL, status: [
                                        // ...fields.status,
                                        {
                                            status_role: STATUS.COMPLETED,
                                            role: USER_ROLES.CRM_TEAM
                                        },
                                        {
                                            status_role: STATUS.COMPLETED,
                                            role: USER_ROLES.LD
                                        }
                                    ]
                                })
                            }
                            }
                            fullWidth>
                            <Typography>Call</Typography>
                        </Button>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4}>
                        <Button onClick={() => {
                            setVariant(SOLUTIONS.Videao_Call);
                            setFields({
                                ...fields, err: '', solved_in: SOLUTIONS.Videao_Call, status: [
                                    // ...fields.status,
                                    {
                                        status_role: STATUS.COMPLETED,
                                        role: USER_ROLES.CRM_TEAM
                                    },
                                    {
                                        status_role: STATUS.COMPLETED,
                                        role: USER_ROLES.LD
                                    }
                                ]
                            })
                        }}
                            variant={fields.solved_in === SOLUTIONS.Videao_Call || variant1 === SOLUTIONS.Videao_Call ? "contained" : "outlined"}
                            fullWidth>
                            <Typography>Video Call</Typography>
                        </Button>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4}>
                        <Button onClick={() => {
                            setVariant(SOLUTIONS.REPAIR);
                            setFields({
                                ...fields, err: '', solved_in: SOLUTIONS.REPAIR, status: [
                                    // ...fields.status,
                                    {
                                        status_role: STATUS.YET_TO_RECEIVED,
                                        role: USER_ROLES.LD
                                    }, {
                                        status_role: STATUS.YET_TO_RECEIVED,
                                        role: USER_ROLES.CRM_TEAM
                                    }
                                ]
                            })
                        }}
                            variant={((fields.solved_in === SOLUTIONS.REPAIR) || (variant1 === SOLUTIONS.REPAIR)) ? "contained" : "outlined"}
                            fullWidth>
                            <Typography>Repair</Typography>
                        </Button>
                    </Grid>
                </Grid>
                {((fields.solved_in === SOLUTIONS.REPAIR) || (variant1 === SOLUTIONS.REPAIR)) && <Box mt={4}>
                    <Grid xs={12} md={6} item>
                        <Box>
                            <FileInput
                                onDelete={(filesToDelete) => {
                                    // setFields((prevFields) => {
                                    //     const updatedPhotos = { ...prevFields.device_photo }
                                    //     const filesArray = Array.isArray(filesToDelete) ? filesToDelete : [filesToDelete];
                                    //     filesArray.forEach((file) => {
                                    //         if (updatedPhotos[file.name]) {
                                    //             delete updatedPhotos[file.name];
                                    //         }
                                    //     });
                                    //     return {
                                    //         ...prevFields,
                                    //         err: "",
                                    //         device_photo: "",
                                    //     };
                                    // });
                                    setFields({
                                        ...fields,
                                        err: "",
                                        device_photo: ""
                                    })
                                }}
                                onChange={(files) => {
                                    setFields({
                                        ...fields,
                                        err: "",
                                        device_photo: files
                                    })
                                }}
                                defaults={fields?.device_photo ? [fields?.device_photo] : []}
                                accept="image/*,.pdf"
                                title="Upload Photo"
                                subTitle="Only png,jpeg,jpg,pdf files are allowed! less than 1 MB"
                            />
                        </Box>
                    </Grid>
                </Box>}
            </Box>
        </Box></>)
}

const YETTORECEIVED = ({ statusPass, user, setVariant, variant1, BoxIDAutoCompleted, setFiltersBoxId, filtersBoxId, listProblem, title, modalKey, isUpdate, fields, setFields, loading, onSubmit }) => {
    return (<>
        <FormControl >
            <FormGroup row>
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={(e) => {
                                setFields({
                                    ...fields,
                                    err: "",
                                    status: [
                                        // ...fields.status,
                                        {
                                            status_role: STATUS.START_EVALUTION,
                                            role: USER_ROLES.CRM_TEAM
                                        },
                                        {
                                            status_role: STATUS.SEND_TO_CRM,
                                            role: USER_ROLES.LD
                                        }
                                    ]
                                });
                            }}
                        />
                    }
                    label="Device Arrived"
                    labelPlacement="end"
                />
            </FormGroup>
        </FormControl></>)
}

const STARTEVALUTION = ({ statusPass, user, setVariant, variant1, BoxIDAutoCompleted, setFiltersBoxId, filtersBoxId, listProblem, title, modalKey, isUpdate, fields, setFields, loading, onSubmit }) => {
    return (<>
        <FormControl >
            <FormGroup row>
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={(e) => {
                                setFields({
                                    ...fields,
                                    err: "",
                                    status: [
                                        // ...fields.status,
                                        {
                                            status_role: STATUS.EVALUTION,
                                            role: USER_ROLES.CRM_TEAM
                                        },
                                        {
                                            status_role: STATUS.EVALUTION,
                                            role: USER_ROLES.LD
                                        }
                                    ]
                                });
                            }}
                        />
                    }
                    label=" Start Evalution"
                    labelPlacement="end"
                />
            </FormGroup>
        </FormControl></>)
}

const EVALUTION = ({ statusPass, productAccessories, user, setVariant, variant1, BoxIDAutoCompleted, setFiltersBoxId, filtersBoxId, listProblem, title, modalKey, isUpdate, fields, setFields, loading, onSubmit }) => {
    return (<>
        <Box gap={3} mt={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            <Autocomplete
                // disabled={statusPass !== STATUS.EVALUTION}
                value={productAccessories?.filter(accessories => fields.product_accessories.includes(accessories.name)) || []}
                sx={{ width: "100%", color: "primary.main" }}
                multiple
                onChange={(event, newValue) => {
                    setFields({
                        ...fields,
                        err: "",
                        product_accessories: newValue.map((val) => val?.name),
                    });
                }}
                options={productAccessories ?? []}
                getOptionLabel={(option) => option.name}
                renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                        <Chip label={option.name} {...getTagProps({ index })} />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        margin="dense"
                        {...params}
                        label="Product Accessories"
                        variant="outlined"
                        placeholder="Selected Product Accessories"
                    />
                )}
            />
        </Box>
        <Box mt={3}>
            <DesktopDatePicker
                label="Purchase Date"
                disableFuture
                inputFormat="DD/MM/yyyy"
                renderInput={(props) => <CustomInput {...props} />}
                autoFocus={true}
                disabled={loading}
                value={fields?.warrenty_date ? moment(fields?.warrenty_date) : null}
                onChange={(val) => {
                    if (val && val.isValid()) {
                        const isoString = val.toISOString();
                        setFields({
                            ...fields,
                            err: "",
                            warrenty_date: isoString,
                        });
                    } else {
                        setFields({
                            ...fields,
                            err: "",
                            warrenty_date: "",
                        });
                    }
                }}
            /></Box>

        <Box>
            <Grid xs={12} md={6} item>
                <Box>
                    <FileInput
                        multi
                        onDelete={(filesToDelete) => {
                            setFields((prevFields) => ({
                                ...prevFields,
                                err: "",
                                unbox_photo: prevFields.unbox_photo.filter(file => !filesToDelete.includes(file))
                            }));
                        }}
                        onChange={(newFiles) => {
                            setFields((prevFields) => ({
                                ...prevFields,
                                err: "",
                                unbox_photo: [...prevFields.unbox_photo, ...newFiles]
                            }));
                        }}
                        defaults={fields?.unbox_photo ?? []}
                        accept="image/*,.pdf"
                        title="Upload Photo"
                        subTitle="Only png, jpeg, jpg, pdf files are allowed! Less than 1 MB"
                    />
                </Box>
            </Grid>
        </Box>
        <Box mt={3}>
            <FormControl >
                {/* <FormLabel component="legend">Conclusion</FormLabel> */}
                <FormGroup component="fieldset" row>

                    {<FormControlLabel
                        control={
                            <Checkbox
                                checked={fields.conclusion === "send_for_repair"}
                                onChange={(e) => {
                                    setFields({
                                        ...fields,
                                        err: "",
                                        conclusion: e.target.name,
                                        status: [
                                            // ...fields.status,
                                            {
                                                status_role: STATUS.DEVICE_RECEIVED,
                                                role: USER_ROLES.REPAIR_TEAM
                                            }
                                            , {
                                                status_role: STATUS.SEND_TO_REPAIR,
                                                role: USER_ROLES.CRM_TEAM
                                            }, {
                                                status_role: STATUS.SEND_TO_REPAIR,
                                                role: USER_ROLES.LD
                                            }
                                        ]
                                    });
                                }}
                                name="send_for_repair"
                            />
                        }
                        label="Send for Repair Team"
                        labelPlacement="end"
                    />}
                </FormGroup>
            </FormControl>
        </Box>
    </>)
}

const DEVICERECEIVED = ({ statusPass, user, setVariant, variant1, BoxIDAutoCompleted, setFiltersBoxId, filtersBoxId, listProblem, title, modalKey, isUpdate, fields, setFields, loading, onSubmit }) => {
    return (<> <FormControl >
        <FormGroup row>
            <FormControlLabel
                control={
                    <Checkbox
                        onChange={(e) => {
                            setFields({
                                ...fields,
                                err: "",
                                status: [

                                    {
                                        status_role: STATUS.REPAIR_START,
                                        role: USER_ROLES.REPAIR_TEAM
                                    },
                                    {
                                        status_role: STATUS.SEND_TO_REPAIR,
                                        role: USER_ROLES.CRM_TEAM
                                    }, {
                                        status_role: STATUS.SEND_TO_REPAIR,
                                        role: USER_ROLES.LD
                                    }
                                ]
                            });
                        }}
                    />
                }
                label="Repairing Start"
                labelPlacement="end"
            />
        </FormGroup>
    </FormControl></>)
}

const REPAIRING = ({ setvalidation, validation, statusPass, user, setVariant, variant1, BoxIDAutoCompleted, setFiltersBoxId, filtersBoxId, listProblem, title, modalKey, isUpdate, fields, setFields, loading, onSubmit }) => {
    let isAlready = false;

    const todayDate = moment();
    const warrantyDate = moment(fields?.warrenty_date);

    const isLessThanOneYear = warrantyDate.isValid() && Math.trunc(((new Date(todayDate) - (new Date(warrantyDate))) / (1000 * 60 * 60 * 24))) / 365 <= 1;
    const isLessThanThreeYear = warrantyDate.isValid() && Math.trunc(((new Date(todayDate) - (new Date(warrantyDate))) / (1000 * 60 * 60 * 24))) / 365 <= 3;
    return (<>
        <Box mt={3}>
            <DesktopDatePicker
                disableFuture
                inputFormat="DD/MM/yyyy"
                renderInput={(props) => <CustomInput {...props} />}
                autoFocus={true}
                disabled={user.data.role !== USER_ROLES.ADMIN}
                label="Purchase Date"
                value={fields?.warrenty_date ? moment(fields?.warrenty_date) : null}
                onChange={(val) => {
                    if (val && val.isValid()) {
                        const isoString = val.toISOString();
                        setFields({
                            ...fields,
                            err: "",
                            warrenty_date: isoString,
                        });
                    } else {
                        setFields({
                            ...fields,
                            err: "",
                            warrenty_date: "",
                        });
                    }
                }}
            /></Box>
        <Box mt={2} mb={3} >
            <Typography variant="h3">Device is :</Typography>
            <Box mt={3}
                sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    border: "1px solid black",
                    borderRadius: "8px",
                    padding: "7px",
                    "@media (min-width: 490px)": {
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-around",
                    },
                }}
            >
                {/* <FormControl > */}
                {/* <FormGroup component="fieldset" row> */}
                <FormControlLabel
                    control={
                        <Radio
                            checked={fields.device_is === "physical_damage"}
                            onChange={(e) => {
                                setFields({
                                    ...fields,
                                    err: "",
                                    device_is: e.target.name,
                                    // repairable_remarks: "",
                                    // replace_remarks: "",
                                    // repairing_amount: "",
                                    // repairable_part: "",
                                    status: [
                                        // ...fields.status,, 
                                        {
                                            status_role: STATUS.WAITING_FOR_PAYMENT,
                                            role: USER_ROLES.CRM_TEAM
                                        },
                                        {
                                            status_role: STATUS.WAITING_FOR_PAYMENT,
                                            role: USER_ROLES.LD
                                        }, {
                                            status_role: STATUS.WAITING_FOR_PAYMENT,
                                            role: USER_ROLES.REPAIR_TEAM
                                        }

                                    ]
                                });
                            }}
                            name="physical_damage"
                        />
                    }
                    label="Physical Damage"
                    labelPlacement="end"
                />

                <FormControlLabel
                    control={
                        <Radio
                            checked={fields.device_is === "Repairable"}
                            onChange={(e) => {
                                let statusdata = [];
                                let isBatteryNot = false;
                                fields.problems.forEach((item) => {
                                    const isBattery = item.toLowerCase() === "battery";
                                    if (item.toLowerCase() === "battery") {
                                        isBatteryNot = true;
                                    }
                                    if ((!isBattery && !isLessThanThreeYear) || (isBattery && !isLessThanOneYear) || ((!isBattery && isBatteryNot) && !isLessThanOneYear)) {
                                        statusdata = [
                                            { status_role: STATUS.WAITING_FOR_PAYMENT, role: USER_ROLES.CRM_TEAM },
                                            { status_role: STATUS.WAITING_FOR_PAYMENT, role: USER_ROLES.LD },
                                            { status_role: STATUS.WAITING_FOR_PAYMENT, role: USER_ROLES.REPAIR_TEAM }
                                        ];
                                    } else if ((!isBattery && isLessThanThreeYear) || (isBattery && isLessThanOneYear)) {
                                        statusdata = [
                                            { status_role: STATUS.DISPATCH, role: USER_ROLES.LD },
                                            { status_role: STATUS.REPAIR_COMPLETED, role: USER_ROLES.REPAIR_TEAM },
                                            { status_role: STATUS.DISPATCH, role: USER_ROLES.CRM_TEAM }
                                        ];
                                    }
                                });
                                setFields({
                                    ...fields,
                                    err: "",
                                    device_is: e.target.name,
                                    // physical_damage: {},
                                    // replace_remarks: "",
                                    status: statusdata
                                });
                            }}
                            name="Repairable"
                        />
                    }
                    label="Repairable"
                    labelPlacement="end"
                />

                <FormControlLabel
                    control={
                        <Radio
                            checked={fields.device_is === "Replace"}
                            onChange={(e) => {
                                setFields({
                                    ...fields,
                                    err: "",
                                    // repairable_remarks: "",
                                    // repairing_amount: "",
                                    // repairable_part: "",
                                    // physical_damage: {},
                                    device_is: e.target.name,
                                    status: [

                                        {
                                            status_role: STATUS.DISPATCH,
                                            role: USER_ROLES.LD
                                        },

                                        {
                                            status_role: STATUS.REPLACEMENT,
                                            role: USER_ROLES.REPAIR_TEAM
                                        },

                                        {
                                            status_role: STATUS.DISPATCH,
                                            role: USER_ROLES.CRM_TEAM
                                        }

                                    ]
                                });
                            }}
                            name="Replace"
                        />
                    }
                    label="Replacement"
                    labelPlacement="end"
                />

                {/* </FormGroup> */}
                {/* </FormControl> */}
            </Box>
        </Box>

        {fields.device_is === "Repairable" && <Box mt={1} gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>

            <CustomInput
                autoFocus={true}
                disabled={loading}
                value={fields?.repairable_part}
                onChange={(e) => setFields({ ...fields, err: '', repairable_part: e.target.value })}
                type="text"
                label={"Which part you repair*"}
            />
            <CustomInput
                // autoFocus={true}
                disabled={loading}
                value={fields?.repairable_remarks}
                onChange={(e) => setFields({ ...fields, err: '', repairable_remarks: e.target.value })}
                type="text"
                label={"Remarks*"}
            />
            {fields.problems.map((item) => {
                if (isAlready) {
                    return null;
                }
                return <>
                    {(item.toLowerCase() === "battery" && !isLessThanOneYear) && (
                        <>
                            {isAlready = true}
                            {setvalidation(true)}
                            <CustomInput
                                disabled={loading}
                                value={fields?.repairing_amount}
                                onChange={(e) => setFields({ ...fields, err: '', repairing_amount: e.target.value })}
                                type="number"
                                label={"Cost*"}
                            />
                        </>
                    )}
                    {(item.toLowerCase() != "battery" && !isLessThanThreeYear) && (
                        <>
                            {isAlready = true}

                            {setvalidation(true)}
                            <CustomInput
                                disabled={loading}
                                value={fields?.repairing_amount}
                                onChange={(e) => setFields({ ...fields, err: '', repairing_amount: e.target.value })}
                                type="number"
                                label={"Cost*"}
                            />
                        </>
                    )}
                </>
            }

            )}

        </Box>}
        {fields.device_is === "Replace" && <Box mt={1} gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            <CustomInput
                autoFocus={true}
                disabled={loading}
                value={fields?.replace_remarks}
                onChange={(e) => setFields({ ...fields, err: '', replace_remarks: e.target.value })}
                type="text"
                label={"Remarks*"}
            />
        </Box>}

        {fields.device_is === "physical_damage" && <Box mt={1} gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            <CustomInput
                autoFocus={true}
                disabled={loading}
                value={fields?.physical_damage?.damage_part}
                onChange={(e) => setFields({ ...fields, err: '', physical_damage: { ...fields.physical_damage, damage_part: e.target.value } })}
                type="text"
                label={"Damage Part*"}
            />
            <CustomInput
                // autoFocus={true}
                disabled={loading}
                value={fields?.physical_damage?.repairing_amount}
                onChange={(e) => setFields({ ...fields, err: '', physical_damage: { ...fields.physical_damage, repairing_amount: e.target.value } })}
                type="number"
                label={"Cost*"}
            />
            <CustomInput
                // autoFocus={true}
                disabled={loading}
                value={fields?.physical_damage?.remarks}
                onChange={(e) => setFields({ ...fields, err: '', physical_damage: { ...fields.physical_damage, remarks: e.target.value } })}
                type="text"
                label={"Remarks*"}
            />

        </Box>}

    </>)
}

const WAITINGFORPAYMENT = ({ statusPass, user, setVariant, variant1, BoxIDAutoCompleted, setFiltersBoxId, filtersBoxId, listProblem, title, modalKey, isUpdate, fields, setFields, loading, onSubmit }) => {

    const selectedDispatch = useRef();

    let isAlready = false;

    const todayDate = moment();
    const warrantyDate = moment(fields?.warrenty_date);

    const isLessThanOneYear = warrantyDate.isValid() && Math.trunc(((new Date(todayDate) - (new Date(warrantyDate))) / (1000 * 60 * 60 * 24))) / 365 <= 1;
    const isLessThanThreeYear = warrantyDate.isValid() && Math.trunc(((new Date(todayDate) - (new Date(warrantyDate))) / (1000 * 60 * 60 * 24))) / 365 <= 3;


    return (<><Box mt={3}>
        <DesktopDatePicker
            disableFuture

            inputFormat="DD/MM/yyyy"
            renderInput={(props) => <CustomInput {...props} />}
            autoFocus={true}
            // disabled={user.data.role !== USER_ROLES.ADMIN}
            label="Purchase Date"
            value={fields?.warrenty_date ? moment(fields?.warrenty_date) : null}
            onChange={(val) => {
                if (val && val.isValid()) {
                    const isoString = val.toISOString();
                    setFields({
                        ...fields,
                        err: "",
                        warrenty_date: isoString,
                    });
                } else {
                    setFields({
                        ...fields,
                        err: "",
                        warrenty_date: "",
                    });
                }
            }}
        />
        {fields.device_is === "physical_damage" && <Box mt={1} gap={3} mb={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            <CustomInput
                // autoFocus={true}
                disabled={user.data.role !== USER_ROLES.ADMIN}
                value={fields?.physical_damage?.damage_part}
                onChange={(e) => setFields({ ...fields, err: '', physical_damage: { ...fields.physical_damage, damage_part: e.target.value } })}
                type="text"
                label={"Damage Part*"}
            />
            <CustomInput
                // autoFocus={true}
                disabled={user.data.role !== USER_ROLES.ADMIN}
                value={fields?.physical_damage?.repairing_amount}
                onChange={(e) => setFields({ ...fields, err: '', physical_damage: { ...fields.physical_damage, repairing_amount: e.target.value } })}
                type="text"
                label={"Cost*"}
            />
            <CustomInput
                // autoFocus={true}
                disabled={user.data.role !== USER_ROLES.ADMIN}
                value={fields?.physical_damage?.remarks}
                onChange={(e) => setFields({ ...fields, err: '', physical_damage: { ...fields.physical_damage, remarks: e.target.value } })}
                type="text"
                label={"Remarks*"}
            />

        </Box>}

        {fields.device_is === "Repairable" && <Box mt={1} mb={2} gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>

            <CustomInput
                autoFocus={true}
                disabled={user.data.role !== USER_ROLES.ADMIN}

                value={fields?.repairable_part}
                onChange={(e) => setFields({ ...fields, err: '', repairable_part: e.target.value })}
                type="text"
                label={"Which part you repair*"}
            />
            <CustomInput
                // autoFocus={true}
                disabled={user.data.role !== USER_ROLES.ADMIN}

                value={fields?.repairable_remarks}
                onChange={(e) => setFields({ ...fields, err: '', repairable_remarks: e.target.value })}
                type="text"
                label={"Repairable Remarks*"}
            />
            {fields.problems.map((item) => {

                if (isAlready) {
                    return null;
                }
                return <>
                    {(item.toLowerCase() === "battery" && !isLessThanOneYear) && (
                        <> {isAlready = true}
                            <CustomInput
                                disabled={user.data.role !== USER_ROLES.ADMIN}

                                value={fields?.repairing_amount}
                                onChange={(e) => setFields({ ...fields, err: '', repairing_amount: e.target.value })}
                                type="number"
                                label={"Cost*"}
                            />
                        </>
                    )}
                    {(item != "Battery" && !isLessThanThreeYear) && (
                        <> {isAlready = true}
                            <CustomInput
                                disabled={user.data.role !== USER_ROLES.ADMIN}

                                value={fields?.repairing_amount}
                                onChange={(e) => setFields({ ...fields, err: '', repairing_amount: e.target.value })}
                                type="number"
                                label={"Cost*"}
                            />
                        </>
                    )}
                </>
            }

            )}

        </Box>}
        <FormControl fullWidth >
            <InputLabel >Pending Payment</InputLabel>
            <Select
                value={fields.repairing_amount_status}
                label="Pending Payment"
                onChange={(e) => {
                    setFields({
                        ...fields,
                        err: '',
                        repairing_amount_status: e.target.value,
                        // status
                    });
                }}
            >
                <MenuItem value={PAYMENT_METHOD.PAYMENT_RECEIVED}>{titleCase(PAYMENT_METHOD.PAYMENT_RECEIVED)}</MenuItem>
                <MenuItem value={PAYMENT_METHOD.CANCEL_PAYMENT}>{"Unable To Pay"}</MenuItem>
                <MenuItem value={PAYMENT_METHOD.RETURN_DEVICE}>{titleCase(PAYMENT_METHOD.RETURN_DEVICE)}</MenuItem>
            </Select>
        </FormControl>

        {fields.repairing_amount_status === PAYMENT_METHOD.PAYMENT_RECEIVED && <FormControl >
            <FormGroup row>
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={(e) => {
                                setFields({
                                    ...fields,
                                    err: "",
                                    status: [
                                        {
                                            status_role: STATUS.PAYMENT_RECEIVED,
                                            role: USER_ROLES.REPAIR_TEAM
                                        },
                                        {
                                            status_role: STATUS.SEND_TO_REPAIR,
                                            role: USER_ROLES.CRM_TEAM
                                        },
                                        {
                                            status_role: STATUS.SEND_TO_REPAIR,
                                            role: USER_ROLES.LD
                                        }

                                    ]
                                });
                            }}
                        />
                    }
                    label="Sent To Repair"
                    labelPlacement="end"
                />
            </FormGroup>
        </FormControl>}
        {fields.repairing_amount_status === PAYMENT_METHOD.CANCEL_PAYMENT && <>

            <Box mt={2} display={"flex"} flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"} border={"1px solid black"} width={"100%"} height={"8vh"} borderRadius={"4px"} p={3}>

                <FormControl sx={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>
                    <FormLabel id="demo-radio-buttons-group-label">Customer need Devices Back ? :</FormLabel>
                    <RadioGroup
                        row
                        sx={{ marginLeft: "10px" }}
                        value={selectedDispatch.current}
                        onChange={(e) => {
                            const data = e.target.value

                            let statusdata = [];
                            if (data == CANCEL_PAYMENT.YES) {
                                statusdata = [
                                    {
                                        status_role: STATUS.DISPATCH,
                                        role: USER_ROLES.LD,
                                    },
                                    {
                                        status_role: STATUS.DISPATCH,
                                        role: USER_ROLES.CRM_TEAM,
                                    }

                                ];
                            }

                            if (data == CANCEL_PAYMENT.NO) {

                                statusdata = [
                                    {
                                        status_role: STATUS.NOT_DISPATCH,
                                        role: USER_ROLES.CRM_TEAM,
                                    },
                                    {
                                        status_role: STATUS.NOT_DISPATCH,
                                        role: USER_ROLES.LD,
                                    },
                                    {
                                        status_role: STATUS.NOT_DISPATCH,
                                        role: USER_ROLES.REPAIR_TEAM,
                                    }
                                ];
                            }

                            setFields({
                                ...fields,
                                err: "",
                                status: statusdata,
                            });
                        }}
                    >
                        {Object.keys(CANCEL_PAYMENT).map((item) => (
                            <FormControlLabel
                                disabled={loading}
                                key={item}
                                value={CANCEL_PAYMENT[item]}
                                control={<Radio />}
                                label={titleCase(item)}
                            />
                        ))}
                    </RadioGroup>

                </FormControl>
            </Box>
            {selectedDispatch && (
                <CustomInput
                    disabled={loading}
                    value={fields.dispatch_remarks}
                    onChange={(e) => setFields({ ...fields, err: '', dispatch_remarks: e.target.value })}
                    type="text"
                    label={"Remarks*"}
                />
            )}

        </>}
        {fields.repairing_amount_status === PAYMENT_METHOD.RETURN_DEVICE && <FormGroup row>
            <FormControlLabel
                control={
                    <Checkbox
                        onChange={(e) => {
                            setFields({
                                ...fields,
                                err: "",
                                status: [
                                    {
                                        status_role: STATUS.PAYMENT_RECEIVED,
                                        role: USER_ROLES.REPAIR_TEAM
                                    },
                                    {
                                        status_role: STATUS.SEND_TO_REPAIR,
                                        role: USER_ROLES.CRM_TEAM
                                    },
                                    {
                                        status_role: STATUS.SEND_TO_REPAIR,
                                        role: USER_ROLES.LD
                                    }

                                ]
                            });
                        }}
                    />
                }
                label="Return Device"
                labelPlacement="end"
            />
        </FormGroup>
        }
    </Box></>)
}

const PAYMENT_RECEIVED = ({ setvalidation, validation, statusPass, user, setVariant, variant1, BoxIDAutoCompleted, setFiltersBoxId, filtersBoxId, listProblem, title, modalKey, isUpdate, fields, setFields, loading, onSubmit }) => {
    let isAlready = false;

    const todayDate = moment();
    const warrantyDate = moment(fields?.warrenty_date);

    const isLessThanOneYear = warrantyDate.isValid() && Math.trunc(((new Date(todayDate) - (new Date(warrantyDate))) / (1000 * 60 * 60 * 24))) / 365 <= 1;
    const isLessThanThreeYear = warrantyDate.isValid() && Math.trunc(((new Date(todayDate) - (new Date(warrantyDate))) / (1000 * 60 * 60 * 24))) / 365 <= 3;

    return (<><>
        {(fields.device_is === "physical_damage") &&
            <Box mt={4} gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                <CustomInput
                    autoFocus={true}
                    disabled={user.data.role !== USER_ROLES.ADMIN}

                    value={fields?.physical_damage?.damage_part}
                    onChange={(e) => setFields({ ...fields, err: '', physical_damage: { ...fields.physical_damage, damage_part: e.target.value } })}
                    type="text"
                    label={"Damage Part*"}
                />
                <CustomInput
                    // autoFocus={true}
                    disabled={user.data.role !== USER_ROLES.ADMIN}

                    value={fields?.physical_damage?.repairing_amount}
                    onChange={(e) => setFields({ ...fields, err: '', physical_damage: { ...fields.physical_damage, repairing_amount: e.target.value } })}
                    type="number"
                    label={"Cost*"}
                />
                <CustomInput
                    // autoFocus={true}
                    disabled={user.data.role !== USER_ROLES.ADMIN}

                    value={fields?.physical_damage?.remarks}
                    onChange={(e) => setFields({ ...fields, err: '', physical_damage: { ...fields.physical_damage, remarks: e.target.value } })}
                    type="text"
                    label={"Remarks*"}
                />


            </Box>}
        {fields.device_is === "Repairable" && <Box mt={4} mb={2} gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>

            <CustomInput
                autoFocus={true}
                disabled={user.data.role !== USER_ROLES.ADMIN}

                value={fields?.repairable_part}
                onChange={(e) => setFields({ ...fields, err: '', repairable_part: e.target.value })}
                type="text"
                label={"Which part you repair*"}
            />
            <CustomInput
                // autoFocus={true}
                disabled={user.data.role !== USER_ROLES.ADMIN}

                value={fields?.repairable_remarks}
                onChange={(e) => setFields({ ...fields, err: '', repairable_remarks: e.target.value })}
                type="text"
                label={"Remarks*"}
            />
            {fields.problems.map((item) => {

                if (isAlready) {
                    return null;
                }
                return <>
                    {(item.toLowerCase() === "battery" && !isLessThanOneYear) && (
                        <> {isAlready = true}
                            <CustomInput
                                disabled={user.data.role !== USER_ROLES.ADMIN}

                                value={fields?.repairing_amount}
                                onChange={(e) => setFields({ ...fields, err: '', repairing_amount: e.target.value })}
                                type="number"
                                label={"Cost*"}
                            />
                        </>
                    )}
                    {(item.toLowerCase() != "battery" && !isLessThanThreeYear) && (
                        <> {isAlready = true}
                            <CustomInput
                                disabled={user.data.role !== USER_ROLES.ADMIN}

                                value={fields?.repairing_amount}
                                onChange={(e) => setFields({ ...fields, err: '', repairing_amount: e.target.value })}
                                type="number"
                                label={"Cost*"}
                            />
                        </>
                    )}
                </>
            }

            )}

        </Box>}
        <FormControl >
            <FormGroup row>
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={(e) => {
                                setFields({
                                    ...fields,
                                    err: "",
                                    status: fields.repairing_amount_status == "RETURN_DEVICE" ?
                                        [

                                            {
                                                status_role: STATUS.DEMO,
                                                role: USER_ROLES.REPAIR_TEAM
                                            },
                                            {
                                                status_role: STATUS.DEMO,
                                                role: USER_ROLES.LD
                                            },
                                            {
                                                status_role: STATUS.DEMO,
                                                role: USER_ROLES.CRM_TEAM
                                            }
                                            // ,
                                            // {
                                            //     status_role: STATUS.REPAIR_COMPLETED,
                                            //     role: USER_ROLES.REPAIR_TEAM
                                            // }
                                        ]
                                        :
                                        [
                                            {
                                                status_role: STATUS.DISPATCH,
                                                role: USER_ROLES.LD
                                            },
                                            {
                                                status_role: STATUS.DISPATCH,
                                                role: USER_ROLES.CRM_TEAM
                                            },

                                            {
                                                status_role: STATUS.REPAIR_COMPLETED,
                                                role: USER_ROLES.REPAIR_TEAM
                                            }
                                        ]
                                });
                            }}
                        />
                    }
                    label="Repairing Complete"
                    labelPlacement="end"
                />
            </FormGroup>
        </FormControl>
    </></>)
}

const DISPATCH = ({ statusPass, productAccessories, replace, SetReplace, setSelectedDispatch, selectedDispatch, user, setVariant, variant1, BoxIDAutoCompleted, setFiltersBoxId, filtersBoxId, listProblem, title, modalKey, isUpdate, fields, setFields, loading, onSubmit }) => {
    const onCheckbox1 = (e) => {
        // setIsChecked(e);

        setFields({
            ...fields,
            err: "",
            status: fields.repairing_amount_status == "CANCEL_PAYMENT" ?
                [
                    {
                        status_role: STATUS.COMPLETED,
                        role: USER_ROLES.CRM_TEAM
                    },
                    {
                        status_role: STATUS.COMPLETED,
                        role: USER_ROLES.LD
                    }
                ]
                :
                fields.device_is === "Replace" ? [
                    {
                        status_role: STATUS.REPLACEMENT_COMPLETE,
                        role: USER_ROLES.CRM_TEAM
                    },
                    {
                        status_role: STATUS.REPLACEMENT_COMPLETE,
                        role: USER_ROLES.LD
                    },
                    {
                        status_role: STATUS.REPLACEMENT_COMPLETE,
                        role: USER_ROLES.REPAIR_TEAM
                    }
                    // {
                    //     status_role: STATUS.COMPLETED,
                    //     role: USER_ROLES.LD
                    // },
                    // {
                    //     status_role: STATUS.REPLACEMENT,
                    //     role: USER_ROLES.REPAIR_TEAM
                    // },
                ]
                    :
                    [
                        {
                            status_role: STATUS.COMPLETED,
                            role: USER_ROLES.CRM_TEAM
                        },
                        {
                            status_role: STATUS.COMPLETED,
                            role: USER_ROLES.LD
                        },

                        {
                            status_role: STATUS.REPAIR_COMPLETED,
                            role: USER_ROLES.REPAIR_TEAM
                        }
                    ]

        });
    }

    const onCheckbox2 = () => {

        setFields({
            ...fields,
            err: "",
            status: [
                {
                    status_role: STATUS.DEMO,
                    role: USER_ROLES.REPAIR_TEAM
                },
                {
                    status_role: STATUS.DEMO,
                    role: USER_ROLES.LD
                },
                {
                    status_role: STATUS.DEMO,
                    role: USER_ROLES.CRM_TEAM
                }

            ]
        });
    }

    const dispatch = useDispatch()

    const onScan = (data) => {
        setFields({ ...fields, err: '', new_box_id: data?.text })
    };
    const onQrBtnClick = () => {
        dispatch(
            openModal(
                <QrScannerPopup
                    setFields={setFields}
                    fields={fields}
                    onScan={onScan}
                    onClose={() => dispatch(closeModal("scanner"))}
                />,
                "sm",
                false,
                "scanner"
            )
        );
    };
    return (
        <>
            <Box mt={4} display={"flex"} flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"} border={"1px solid black"} width={"100%"} height={"8vh"} borderRadius={"4px"} p={3}>
                <FormControl sx={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>
                    <FormLabel id="demo-radio-buttons-group-label">Customer need Devices Back ? :</FormLabel>
                    <RadioGroup
                        row
                        sx={{ marginLeft: "10px" }}
                        onChange={(e) => {
                            setSelectedDispatch(e.target.value);
                            e.target.value == DEVICES_BACK.YES ? onCheckbox1(true) : onCheckbox2();
                        }}
                    >
                        {Object.keys(DEVICES_BACK).map((item) => (
                            <FormControlLabel
                                disabled={loading}
                                key={item}
                                value={DEVICES_BACK[item]}
                                control={<Radio />}
                                label={titleCase(item)}
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
            </Box>

            {(selectedDispatch == DEVICES_BACK.YES) && fields.product_id?.name === "HearNU" && fields.device_is === "Replace" && (
                <Box mt={2} mb={4} display={"flex"} flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"} border={"1px solid black"} width={"100%"} height={"8vh"} borderRadius={"4px"} p={3}>
                    <FormControl sx={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>
                        <RadioGroup
                            row
                            sx={{ marginLeft: "10px" }}
                            onChange={(e) => {
                                SetReplace(e.target.value);
                                setFields({ ...fields, err: '' });
                            }}
                        >
                            {Object.keys(REPLACEMENT_BOX).map((item) => (
                                <FormControlLabel
                                    disabled={loading}
                                    key={item}
                                    value={REPLACEMENT_BOX[item]}
                                    control={<Radio />}
                                    label={titleCase(item)}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </Box>
            )}

            {fields.device_is === "Replace" && (selectedDispatch == DEVICES_BACK.YES) && ((replace === REPLACEMENT_BOX.NEW_BOX) || (fields.product_id?.name !== "HearNU")) &&

                <PaddingBoxInDesktop sx={{ display: "flex", flex: 1, justifyContent: "flex-end", gap: "10px" }}  >

                    <Box width={"100%"}>
                        <FormControl color="primary" fullWidth variant="outlined">
                            <InputLabel color="primary" htmlFor="outlined-adornment-boxid">
                                New Box Id*
                            </InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-boxid"
                                value={fields?.new_box_id || ""}
                                onChange={(e) => setFields({ ...fields, err: '', new_box_id: e.target.value })}
                                type="text"
                                autoFocus={true}
                                disabled={loading}
                                endAdornment={<>
                                    {loading && <CircularProgress size={30} />}
                                    <InputAdornment position="end">
                                        <IconButton onClick={onQrBtnClick} edge="end">
                                            <QrCodeScanner />
                                        </IconButton>
                                    </InputAdornment>
                                </>}
                                color="primary"
                                label="New Box Id*"
                            />
                        </FormControl>
                    </Box>
                    <AsyncDropDown
                        defaultVal={
                            fields.new_box_id ? {

                                _id: fields._id,
                                new_box_id: fields.new_box_id,
                            }
                                : null
                        }
                        lazyFun={async (para) => await getBoxnotallocatedboxIdApi({ ...para, allStatus: true, searchable: ["boxId", "sold.cphone"] })}
                        OptionComponent={({ option, ...rest }) => {
                            return <ListItem {...rest}>{option.boxId}</ListItem >
                        }}
                        onChange={async (changedVal) => {
                            setFields({ ...fields, err: '', new_box_id: changedVal ? changedVal.boxId : null })
                        }}
                        titleKey={'boxId'}
                        valueKey={"_id"}
                        InputComponent={(params) => <CustomInput placeholder={"New Box Id*"} {...params} margin="none" />}
                    />
                </PaddingBoxInDesktop>}

            {fields.device_is === "Replace" && replace === REPLACEMENT_BOX.HEAR_NU_BAND && (
                <Box gap={3} mt={2} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                    <CustomInput
                        disabled={loading}
                        value={fields.band_box_id}
                        onChange={(e) => setFields({ ...fields, err: '', band_box_id: e.target.value })}
                        type="text"
                        label={"Old Band Id.*"}
                    />
                    <CustomInput
                        disabled={loading}
                        value={fields.new_band_box_id}
                        onChange={(e) => setFields({ ...fields, err: '', new_band_box_id: e.target.value })}
                        type="text"
                        label={"New Band Id.*"}
                    />
                </Box>
            )}

            {fields.device_is === "Replace" && replace === REPLACEMENT_BOX.HEAD_SET && (
                <Box gap={3} mt={2} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                    <CustomInput
                        disabled={loading}
                        value={fields.head_set_id}
                        onChange={(e) => setFields({ ...fields, err: '', head_set_id: e.target.value })}
                        type="text"
                        label={"New Head Set Id *"}
                    />
                </Box>
            )}

            {selectedDispatch == DEVICES_BACK.YES && (
                <>
                    <Autocomplete
                        value={productAccessories?.filter(accessories => fields?.product_accessories?.includes(accessories?.name)) ?? []}
                        sx={{ width: "100%", color: "primary.main" }}
                        multiple
                        onChange={(event, newValue) => {
                            setFields({
                                ...fields,
                                err: "",
                                product_accessories: newValue.map((val) => val?.name),
                            });
                        }}
                        options={productAccessories ?? []}
                        getOptionLabel={(option) => option.name}
                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                                <Chip label={option.name} {...getTagProps({ index })} />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                margin="dense"
                                {...params}
                                label="Product Accessories"
                                variant="outlined"
                                placeholder="Selected Product Accessories"
                            />
                        )}
                    />

                    <Box gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <CustomInput
                            disabled={loading}
                            value={fields.courier_partner_nmae}
                            onChange={(e) => setFields({ ...fields, err: '', courier_partner_nmae: e.target.value })}
                            type="text"
                            label={"Courier Partner Name*"}
                        />
                        <CustomInput
                            disabled={loading}
                            value={fields.tracking_id}
                            onChange={(e) => setFields({ ...fields, err: '', tracking_id: e.target.value })}
                            type="text"
                            label={"Tracking Id*"}
                        />
                    </Box>
                </>
            )}
        </>
    );
}

const UpdateCreateQueryUi = ({ replace, SetReplace, setSTATUSWiseData, STATUSWiseData, setvalidation, validation, statusPass, setSelectedDispatch, selectedDispatch, productAccessories, listProblem, title, modalKey, isUpdate, fields, setFields, loading, onSubmit, BoxIDAutoCompleted, setFiltersBoxId, filtersBoxId, variant1, setVariant }) => {

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const [isChecked, setIsChecked] = useState(false);
    // const arrr=[]
    const user = useSelector(state => state.user)
    const dispatch = useDispatch();
    const OnOtp = () => {
        dispatch(openModal(<OtpInput handleOTPSubmit={onSubmit}
        />, "sm", false, "Otp"))
    }

    return (
        <>
            <CustomDialog
                id={`UpdateQuery`}
                loading={loading}
                err={fields.err}
                onSubmit={onSubmit}
                title={` Update ${title}`}
                closeText="Close"
                confirmText={`Update`}
            >
                {loading ? (
                    <CenteredBox><CircularProgress /></CenteredBox>
                ) : (
                    <>
                        <Box width={"90%"} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                            <Box display={"flex"} flexDirection={"column"}>
                                <Typography>
                                    Ticket Number :{fields?.ticket_no}
                                </Typography>
                            </Box>
                            <Box display={"flex"} flexDirection={"column"}>
                                <Typography>
                                    Date :{moment(fields?.createdAt).format("DD/MM/YYYY")}
                                </Typography>
                            </Box>
                        </Box>

                        <ButtonGroup sx={{ flexDirection: "row", width: "100%", mt: 4 }} >
                            <ButtonComponentsUi onSubmit={() => { setSTATUSWiseData(STATUS.YET_TO_RECEIVED); }} Title={STATUS.YET_TO_RECEIVED} STATUSWiseData={STATUSWiseData == STATUS.YET_TO_RECEIVED} />
                            <ButtonComponentsUi onSubmit={() => { setSTATUSWiseData(STATUS.START_EVALUTION); }} Title={STATUS.START_EVALUTION} STATUSWiseData={STATUSWiseData == STATUS.START_EVALUTION} />
                            <ButtonComponentsUi onSubmit={() => { setSTATUSWiseData(STATUS.EVALUTION); }} Title={STATUS.EVALUTION} STATUSWiseData={STATUSWiseData == STATUS.EVALUTION} />
                            <ButtonComponentsUi onSubmit={() => { setSTATUSWiseData(STATUS.WAITING_FOR_PAYMENT); }} Title={STATUS.WAITING_FOR_PAYMENT} STATUSWiseData={STATUSWiseData == STATUS.WAITING_FOR_PAYMENT} />
                            <ButtonComponentsUi onSubmit={() => { setSTATUSWiseData(STATUS.DEVICE_RECEIVED); }} Title={STATUS.DEVICE_RECEIVED} STATUSWiseData={STATUSWiseData == STATUS.DEVICE_RECEIVED} />
                            <ButtonComponentsUi onSubmit={() => { setSTATUSWiseData(STATUS.REPAIR_START); }} Title={STATUS.REPAIR_START} STATUSWiseData={STATUSWiseData == STATUS.REPAIR_START} />
                            <ButtonComponentsUi onSubmit={() => { setSTATUSWiseData(STATUS.PAYMENT_RECEIVED); }} Title={STATUS.PAYMENT_RECEIVED} STATUSWiseData={STATUSWiseData == STATUS.PAYMENT_RECEIVED} />
                            <ButtonComponentsUi onSubmit={() => { setSTATUSWiseData(STATUS.DISPATCH); }} Title={STATUS.DISPATCH} STATUSWiseData={STATUSWiseData == STATUS.DISPATCH} />
                            <ButtonComponentsUi onSubmit={() => { setSTATUSWiseData("OTP"); OnOtp(); }} Title={"OTP"} STATUSWiseData={STATUSWiseData == "OTP"} />

                        </ButtonGroup>

                        {((STATUSWiseData == STATUS.YET_TO_RECEIVED) || (STATUSWiseData == STATUS.START_EVALUTION) || (STATUSWiseData == STATUS.DEVICE_RECEIVED)) && <CreateUI user={user} statusPass={statusPass} listProblem={listProblem} title={title} modalKey={modalKey} isUpdate={isUpdate} fields={fields} setFields={setFields} loading={loading} onSubmit={onSubmit} variant1={variant1} setVariant={setVariant} BoxIDAutoCompleted={BoxIDAutoCompleted} setFiltersBoxId={setFiltersBoxId} filtersBoxId={filtersBoxId} />}
                        {STATUSWiseData == STATUS.YET_TO_RECEIVED && <YETTORECEIVED user={user} statusPass={statusPass} listProblem={listProblem} title={title} modalKey={modalKey} isUpdate={isUpdate} fields={fields} setFields={setFields} loading={loading} onSubmit={onSubmit} variant1={variant1} setVariant={setVariant} BoxIDAutoCompleted={BoxIDAutoCompleted} setFiltersBoxId={setFiltersBoxId} filtersBoxId={filtersBoxId} />}
                        {STATUSWiseData == STATUS.START_EVALUTION && <STARTEVALUTION user={user} statusPass={statusPass} listProblem={listProblem} title={title} modalKey={modalKey} isUpdate={isUpdate} fields={fields} setFields={setFields} loading={loading} onSubmit={onSubmit} variant1={variant1} setVariant={setVariant} BoxIDAutoCompleted={BoxIDAutoCompleted} setFiltersBoxId={setFiltersBoxId} filtersBoxId={filtersBoxId} />}
                        {STATUSWiseData == STATUS.EVALUTION && <EVALUTION productAccessories={productAccessories} user={user} statusPass={statusPass} listProblem={listProblem} title={title} modalKey={modalKey} isUpdate={isUpdate} fields={fields} setFields={setFields} loading={loading} onSubmit={onSubmit} variant1={variant1} setVariant={setVariant} BoxIDAutoCompleted={BoxIDAutoCompleted} setFiltersBoxId={setFiltersBoxId} filtersBoxId={filtersBoxId} />}
                        {STATUSWiseData == STATUS.WAITING_FOR_PAYMENT && <WAITINGFORPAYMENT user={user} statusPass={statusPass} listProblem={listProblem} title={title} modalKey={modalKey} isUpdate={isUpdate} fields={fields} setFields={setFields} loading={loading} onSubmit={onSubmit} variant1={variant1} setVariant={setVariant} BoxIDAutoCompleted={BoxIDAutoCompleted} setFiltersBoxId={setFiltersBoxId} filtersBoxId={filtersBoxId} />}
                        {STATUSWiseData == STATUS.DEVICE_RECEIVED && <DEVICERECEIVED validation={validation} setvalidation={setvalidation} user={user} statusPass={statusPass} listProblem={listProblem} title={title} modalKey={modalKey} isUpdate={isUpdate} fields={fields} setFields={setFields} loading={loading} onSubmit={onSubmit} variant1={variant1} setVariant={setVariant} BoxIDAutoCompleted={BoxIDAutoCompleted} setFiltersBoxId={setFiltersBoxId} filtersBoxId={filtersBoxId} />}
                        {STATUSWiseData == STATUS.REPAIR_START && <REPAIRING validation={validation} setvalidation={setvalidation} user={user} statusPass={statusPass} listProblem={listProblem} title={title} modalKey={modalKey} isUpdate={isUpdate} fields={fields} setFields={setFields} loading={loading} onSubmit={onSubmit} variant1={variant1} setVariant={setVariant} BoxIDAutoCompleted={BoxIDAutoCompleted} setFiltersBoxId={setFiltersBoxId} filtersBoxId={filtersBoxId} />}
                        {STATUSWiseData == STATUS.PAYMENT_RECEIVED && <PAYMENT_RECEIVED user={user} statusPass={statusPass} listProblem={listProblem} title={title} modalKey={modalKey} isUpdate={isUpdate} fields={fields} setFields={setFields} loading={loading} onSubmit={onSubmit} variant1={variant1} setVariant={setVariant} BoxIDAutoCompleted={BoxIDAutoCompleted} setFiltersBoxId={setFiltersBoxId} filtersBoxId={filtersBoxId} />}
                        {STATUSWiseData == STATUS.DISPATCH && <DISPATCH productAccessories={productAccessories} replace={replace} SetReplace={SetReplace} selectedDispatch={selectedDispatch} setSelectedDispatch={setSelectedDispatch} user={user} statusPass={statusPass} listProblem={listProblem} title={title} modalKey={modalKey} isUpdate={isUpdate} fields={fields} setFields={setFields} loading={loading} onSubmit={onSubmit} variant1={variant1} setVariant={setVariant} BoxIDAutoCompleted={BoxIDAutoCompleted} setFiltersBoxId={setFiltersBoxId} filtersBoxId={filtersBoxId} />}
                    </>
                )}
            </CustomDialog >
        </>
    );
}

export default memo(UpdateCreateQueryUi);
