import React, { useState, useRef, useEffect, memo } from "react";
import { TextField, Box, CircularProgress } from "@mui/material";
import CustomDialog from "./layouts/common/CustomDialog";
import { CenteredBox } from "./layouts/OneViewBox";
import { closeModal } from "../store/actions/modalAction";
import { useDispatch } from "react-redux";

const OtpInput = ({ length = 6, handleOTPSubmit, fields, loading }) => {
    const [otp, setOtp] = useState(Array(length).fill(""));
    const inputRefs = useRef([]);

    useEffect(() => {
        if (inputRefs.current[0]) {
            inputRefs.current[0].focus();
        }
    }, []);

    const handleChange = (e, index) => {
        const value = e.target.value;
        if (/^[0-9]$/.test(value) || value === "") {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);
            if (value !== "" && index < length - 1) {
                inputRefs.current[index + 1]?.focus();
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" && !otp[index] && index > 0) {
            inputRefs.current[index - 1]?.focus();
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const pasteData = e.clipboardData.getData("text").slice(0, length);
        if (!/^\d+$/.test(pasteData)) return;

        const newOtp = pasteData.split("");
        while (newOtp.length < length) {
            newOtp.push("");
        }
        setOtp(newOtp);
        const nextIndex = newOtp.length - 1;
        inputRefs.current[nextIndex]?.focus();
    };
    const dispatch = useDispatch();


    return (
        <CustomDialog
            id={"Otp"}
            loading={loading}
            err={fields?.err}
            onSubmit={(e) => {
                handleOTPSubmit(e, otp.join(""));
                dispatch(closeModal("UpdateQuery"))
            }}
            title="Enter OTP"
            closeText="Close"
            confirmText="Submit"
        >
            {loading ? (
                <CenteredBox><CircularProgress /></CenteredBox>
            ) : (
                <Box mt={3} display="flex" justifyContent="space-evenly">
                    {otp.map((value, index) => (
                        <TextField
                            key={index}
                            value={value}
                            onChange={(e) => handleChange(e, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            onPaste={handlePaste}
                            inputRef={(el) => (inputRefs.current[index] = el)}
                            variant="outlined"
                            size="small"
                            inputProps={{
                                maxLength: 1,
                                style: { textAlign: "center", width: "40px", height: "30px" },
                            }}
                        />
                    ))}
                </Box>
            )}
        </CustomDialog>
    );
};

export default memo(OtpInput);
