
import { memo, useEffect, useMemo, useRef, useState } from "react";
import useValidate from "../../store/hooks/useValidator";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { closeModal, openModal } from "../../store/actions/modalAction";
import { useParams } from "react-router-dom";
import { PAYMENT_METHOD, PROBLEMS_LABLE, SNACK_BAR_VARIETNS, SOLUTIONS, STATUS, USER_ROLES } from "../../utils/constants";
import { callSnackBar } from "../../store/actions/snackbarAction";
import CreateQueryUi from "./CreateQueryUi";
import { CreateRepairingTicketApi, getRepairingTicketStatusByIdApi, RepairingTicketCreateApi, RepairingUpdateTicketApi } from "../../apis/repairing.api";
import axios from "axios";
import UpdateCreateQueryUi from "./UpdateCreateQueryUi";
import { statusActionFilters, statusCountActionFilters } from "../../store/actions/statusAction";
import { FetchProblemApi } from "../../apis/problem.api";
import { fetchNotificationAction } from "../../store/actions/notificationAction";
import { getProductAccessoriesApi } from "../../apis/productAccessories.api";
import OtpInput from "../../components/OtpInput";

const UpdateCreateQueryController = ({ statusPass, isOtp, fieldTicket, setTickets, id }) => {
  const [fields, setFields] = useState({
    id: id,
    err: "",
    createdAt: fieldTicket?.createdAt ? fieldTicket?.createdAt : "",
    ticket_no: fieldTicket?.ticketNo ? fieldTicket?.ticketNo : "",
    product_id: "",
    box_id: "",
    customer_phone: "",
    customer_name: "",
    customer_email: "",
    source_id: "",
    trakingId: "",
    problems: [],
    solved_in: "",
    customer_address: "",
    conclusion: "",
    other: "",
    physical_damage: {
      damage_part: "",
      repairing_amount: null,
      remarks: ""
    },
    problem_solve_by_crm: {
      type_of_problem: "",
      remarks: ""
    },
    repairing_amount_status: "",
    dispatch_remarks: "",
    warrenty_date: "",
    manufacturer_date: "",
    completed: "",
    product_accessories: []
  });

  const [variant1, setVariant] = useState();

  const updateTicketApi = RepairingTicketCreateApi;
  const [loading, setLoading] = useState(false);
  const validate = useValidate();
  const dispatch = useDispatch();
  const modalKey = 'Query';
  const title = "Query";
  const [arrr, setArrr] = useState([])

  const validationSchemaForUpdate = useMemo(
    () => [
      {
        required: true,
        value: fields.ticket_no,
        field: "ticket_no",
      },
      {
        required: true,
        value: fields.createdAt,
        field: "createdAt",
      },
      {
        required: true,
        value: fields.product_id,
        field: "product_id",
      },
      {
        // required: true,
        value: fields.customer_email,
        field: "Email",
      },
      {
        required: fields?.problems.some((item) => item.toLowerCase() === "other"),
        value: fields.other,
        field: "other",
      },
      {
        // required: true,
        value: fields.box_id,
        field: "Box Id",
      },
      {
        required: true,
        value: fields.customer_phone,
        field: "customer_phone",
      },
      {
        required: true,
        value: fields.customer_name,
        field: "Customer Name",
      },
      {
        required: true,
        value: fields.source_id,
        field: "Source",
      },
      {
        required: true,
        value: fields.problems.length,
        field: "Problems",
      },
      {
        // required: true,
        value: fields.solved_in,
        field: "solved_in",
      },
      {
        required: true,
        value: fields.customer_address,
        field: "Customer Address",
      },
      {
        required: statusPass === STATUS.EVALUTION && true,
        value: fields.product_accessories.length,
        field: "Product Accessories",
      },
      {
        required: statusPass === STATUS.EVALUTION && true,
        value: fields.warrenty_date,
        field: "Purchase Date",
      },
      {
        required: statusPass === STATUS.EVALUTION && true,
        value: fields.conclusion,
        field: "Send for Repair Team",
      },
      // {
      //   required: statusPass === STATUS.COMPLETED && true,
      //   value: fields.completed_status,
      //   field: "Completed Status",
      // },
      // {
      //   required: (statusPass === STATUS.EVALUTION && fields.conclusion === "physical_damage") && true,
      //   value: fields.physical_damage.damage_part,
      //   field: "name",
      // },
      // {
      //   required: (statusPass === STATUS.EVALUTION && fields.conclusion === "physical_damage") && true,
      //   value: fields.physical_damage.repairing_amount,
      //   field: "name",
      // },
      // {
      //   required: (statusPass === STATUS.EVALUTION && fields.conclusion === "physical_damage") && true,
      //   value: fields.physical_damage.remarks,
      //   field: "name",
      // },

      {
        required: statusPass === STATUS.WAITING_FOR_PAYMENT && true,
        value: fields.repairing_amount_status,
        field: "repairing_amount_status",
      },
      {
        required: (statusPass === STATUS.WAITING_FOR_PAYMENT && fields.repairing_amount_status === PAYMENT_METHOD.CANCEL_PAYMENT) && true,
        value: fields.dispatch_remarks,
        field: "dispatch remarks*",
      },

    ],
    [fields]
  );
  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ['cName'],
    role: "",
    sort: "createdAt",
    sortDirection: -1,

  });

  const onSubmit = async (e, isOtpTrue) => {
    e.preventDefault();
    const validationResponse = validate(validationSchemaForUpdate)
    if (validationResponse === true) {
      setLoading(isOtpTrue ? false : true)
      dispatch(callApiAction(
        async () => await RepairingUpdateTicketApi({ ...fields, completed_status: isOtpTrue ? "Device Received" : "", product_id: fields.product_id?._id, source_id: fields.source_id?._id }),
        (response) => {
          setLoading(false)
          dispatch(closeModal("Otp"))
          dispatch(closeModal("UpdateQuery"))
          dispatch(callSnackBar("Updated successfully", SNACK_BAR_VARIETNS.suceess));
          dispatch(fetchNotificationAction());
          dispatch(statusActionFilters(filters));
          dispatch(statusCountActionFilters(-1));
        }, (err) => {
          setFields({ ...fields, err });
          setLoading(false);
        }))
    } else {
      setFields({ ...fields, err: validationResponse })
      setLoading(false)
    }
  };

  const fetchById = (id) => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await getRepairingTicketStatusByIdApi({ id }),
        async (response) => {
          setFields({ ...fields, ...response });
          setLoading(false);
        },
        (err) => {
          setFields({ ...fields, err });
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    if (id) fetchById(id);
  }, [id]);

  const OtPCheck = (e, otp) => {
    e.preventDefault()
    const isOtpTrue = Number(otp) === fields?.otp
    if (isOtpTrue) {
      onSubmit(e, isOtpTrue)
    } else {
      setFields({ ...fields, err: "Please enter a valid OTP" })
    }
  }
  console.log("{first}", fields.otp)
  if (isOtp) {
    dispatch(openModal(<OtpInput handleOTPSubmit={(e, otp) => OtPCheck(e, otp)} fields={fields}
    />, "sm", false, "Otp"))
  }

  const [listProblem, setlistProblem] = useState()

  const fetchList = () => {
    // setLoading(true);
    dispatch(
      callApiAction(
        async () => await FetchProblemApi({ ...filters }),
        (response) => {
          setlistProblem(response?.result);
          // setLoading(false);
        },
        (err) => {
          // setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchList()
  }, [filters])

  const [productAccessories, setProductAccessories] = useState()

  const fetchProductAccessories = () => {
    dispatch(
      callApiAction(
        async () => await getProductAccessoriesApi({ ...filters, Repair: true, product_id: fields.product_id?._id }),
        (response) => {
          setProductAccessories(response?.result[0].product_accessories);
        },
        (err) => {
          console.log("error", err)
        }
      )
    );
  };

  useEffect(() => {
    if (statusPass == STATUS.EVALUTION) {
      fetchProductAccessories();
    }
  }, [statusPass])

  return (<UpdateCreateQueryUi
    listProblem={listProblem}
    statusPass={statusPass}
    modalKey={modalKey}
    title={title}
    loading={loading}
    fields={fields}
    onSubmit={onSubmit}
    setFields={setFields}
    isOtp={isOtp}
    variant1={variant1}
    setVariant={setVariant}
    productAccessories={productAccessories}
  />
  );
};
export default memo(UpdateCreateQueryController);
