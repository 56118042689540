
import { memo, useEffect, useMemo, useState } from "react";
import useValidate from "../../store/hooks/useValidator";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { closeModal } from "../../store/actions/modalAction";
import { useParams } from "react-router-dom";
import { DEVICES_BACK, PROBLEMS_LABLE, REPLACEMENT_BOX, SNACK_BAR_VARIETNS, SOLUTIONS, STATUS, USER_ROLES } from "../../utils/constants";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { CreateRepairingTicketApi, getRepairingTicketStatusByIdApi, RepairingTicketCreateApi, RepairingUpdateTicketApi } from "../../apis/repairing.api";
import axios from "axios";
import UpdateCreateQueryUi from "./UpdateCreateQueryUi";
import { statusActionFilters, statusCountActionFilters } from "../../store/actions/statusAction";
import { getBoxIdApi, NewBoxIdForDmsUpdateTicketApi } from "../../apis/boxid.api";
import { FetchProblemApi } from "../../apis/problem.api";
import { fetchNotificationAction } from "../../store/actions/notificationAction";
import { FetchBoxIdformsims } from "../../apis/BoxIdformsims.api";
import { getProductAccessoriesApi } from "../../apis/productAccessories.api";

const UpdateCreateQueryController = ({ fieldTicket, setTickets, id, statusPass }) => {
  const [selectedDispatch, setSelectedDispatch] = useState();
  const [STATUSWiseData, setSTATUSWiseData] = useState(STATUS.YET_TO_RECEIVED)
  const [replace, SetReplace] = useState();
  const [fields, setFields] = useState({
    id: id,
    err: "",
    createdAt: fieldTicket?.createdAt ? fieldTicket?.createdAt : "",
    ticket_no: fieldTicket?.ticketNo ? fieldTicket?.ticketNo : "",
    product_id: '',
    box_id: "",
    customer_phone: "",
    customer_name: "",
    customer_email: "",
    source_id: "",
    trakingId: "",
    problems: [],
    repairingAmount: [],
    solved_in: "",
    checked: "",
    device_is: "",
    replace_remarks: "",
    customer_address: "",
    new_box_id: "",
    other: "",
    tracking_id: null,
    status: [{
      status_role: null,
      role: null
    }],
    repairing_amount_status: ""
  });
  const [variant1, setVariant] = useState(fields?.solved_in);
  console.log(" fields?.solved_in", fields?.solved_in, variant1)
  const updateTicketApi = RepairingTicketCreateApi;
  const [loading, setLoading] = useState(false);
  const validate = useValidate();
  const dispatch = useDispatch();
  const modalKey = 'Query';
  const title = "Query";
  const [arrr, setArrr] = useState([])
  const [validation, setvalidation] = useState(false);

  const validationSchemaForUpdate = useMemo(
    () => [
      {
        required: true,
        value: fields.ticket_no,
        field: "ticket_no",
      },
      {
        required: true,
        value: fields.createdAt,
        field: "createdAt",
      },
      {
        required: true,
        value: fields.product_id,
        field: "product_id",
      },
      {
        // required: true,
        value: fields.customer_email,
        field: "Email",
      },
      {
        required: true,
        value: fields.customer_address,
        field: "customer_address",
      },
      {
        // required: true,
        value: fields.box_id,
        field: "Box Id",
      },

      {
        required: true,
        value: fields.customer_phone,
        field: "Phone",
      },
      {
        required: true,
        value: fields.customer_name,
        field: "Customer Name",
      },
      {
        required: true,
        value: fields.source_id,
        field: "Source",
      },
      {
        required: true,
        value: fields.problems,
        field: "Problems",
      },
      {
        // required: true,
        value: fields.other,
        field: "other",
      },
      // {
      //   required: statusPass === STATUS.WAITING_FOR_PAYMENT && true,
      //   value: fields.repairing_amount_status,
      //   field: "repairing_amount_status",
      // },
      {
        required: (statusPass === STATUS.DISPATCH && fields.device_is === "Replace") && true,
        value: fields.new_box_id,
        field: "New Box Id",
      }, {
        required: (statusPass === STATUS.DISPATCH) && true,
        value: fields.tracking_id,
        field: "tracking id",
      }
    ],
    [fields]
  );
  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ['customer_name'],
    role: "",
    sort: "createdAt",
    sortDirection: -1,

    // ...statusData.statusReducer.filters
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    const validationResponse = validate(validationSchemaForUpdate)
    // if (statusPass === STATUS.DISPATCH && fields.device_is === "Replace" && replace === REPLACEMENT_BOX.BOX) {
    if (statusPass === STATUS.DISPATCH && fields.device_is === "Replace" && selectedDispatch == DEVICES_BACK.YES && ((replace === REPLACEMENT_BOX.NEW_BOX) || (fields?.product_id?.name !== "HearNU"))) {
      if (validationResponse === true) {
        setLoading(true)
        dispatch(callApiAction(
          async () => await NewBoxIdForDmsUpdateTicketApi({ ...fields, amount: 0 }),
          (response) => {
            setLoading(false)
          }, (err) => {
            console.log("error msg", err)
            setFields({ ...fields, err });
            setLoading(false);
          }
        ))
      } else {

        setFields({ ...fields, err: validationResponse })
        setLoading(false)
      }
    }


    if (validationResponse === true) {
      setLoading(true)
      dispatch(callApiAction(
        async () => await RepairingUpdateTicketApi({ ...fields, completed_status: STATUSWiseData == "OTP" ? "Device Received" : "", product_id: fields.product_id?._id ? fields.product_id?._id : fields.product_id, source_id: fields.source_id?._id ? fields.source_id?._id : fields.source_id }),
        (response) => {
          setLoading(false)
          dispatch(callSnackBar("Updated successfully", SNACK_BAR_VARIETNS.suceess));
          dispatch(fetchNotificationAction());
          dispatch(closeModal("UpdateQuery"))
          dispatch(statusActionFilters(filters));
          dispatch(statusCountActionFilters(-1));
        },
        setLoading(false)
      ))
    } else {
      setFields({ ...fields, err: validationResponse })
      setLoading(false)
    }
  };

  const fetchById = (id) => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await getRepairingTicketStatusByIdApi({ id }),
        async (response) => {
          setFields({ ...fields, ...response });
          setLoading(false);
          fetchProductAccessories(response?.product_id?._id);

        },
        (err) => {
          setFields({ ...fields, err });
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    if (id) fetchById(id);
  }, [id]);


  const [listProblem, setlistProblem] = useState()

  const fetchList = () => {
    // setLoading(true);
    dispatch(
      callApiAction(
        async () => await FetchProblemApi({ ...filters }),
        (response) => {
          setlistProblem(response?.result);
          // setLoading(false);
        },
        (err) => {
          // setLoading(false);
          console.log("error", err)
        }
      )
    );
  };

  const [boxId, setBoxId] = useState();
  const [boxIdformsims, setBoxIdformsims] = useState();

  const [filtersBoxId, setFiltersBoxId] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    sort: "createdAt",
    sortDirection: -1,
  });

  const FetchBoxId = () => {
    dispatch(
      callApiAction(
        async () => await getBoxIdApi({ ...filtersBoxId, searchable: ["boxId", "sold.cphone"] }),
        (response) => {
          setBoxId(response?.result?.map((item) => ({
            boxId: item.boxId,
            _id: item._id,
            customer_phone: item.sold.cphone,
            customer_email: item.sold.cemail,
            customer_name: item.sold.cname,
            customer_address: item.sold.caddress,
            band_box_id: item.convertedProducts?.[0] || ""
          })))
        },
        (err) => {
          console.log("Error", err)
        }
      )
    );
  };

  const FetchBoxIdSims = () => {
    dispatch(
      callApiAction(
        async () => await FetchBoxIdformsims({ ...filtersBoxId, searchable: ["benificiaris.device_id"] }),
        (response) => {
          setBoxIdformsims(response?.result?.map((item) => ({
            boxId: item.boxId,
            _id: item._id,
            customer_phone: item.beneficiary_id.phone,
            customer_email: item.beneficiary_id.email,
            customer_name: item.beneficiary_id.name,
            customer_address: item.beneficiary_id.address,
            band_box_id: ""
          })))
        },
        (err) => {
          console.log("Error", err)
        }
      )
    );
  };

  const BoxIDAutoCompleted = boxIdformsims?.length > 0 ? boxIdformsims?.concat(boxId) : boxId;

  const [productAccessories, setProductAccessories] = useState()

  const fetchProductAccessories = (id) => {
    dispatch(
      callApiAction(
        async () => await getProductAccessoriesApi({ ...filters, Repair: true, product_id: id }),
        (response) => {
          setProductAccessories(response?.result[0].product_accessories);
        },
        (err) => {
          console.log("error", err)
        }
      )
    );
  };


  useEffect(() => {
    fetchList();
    FetchBoxId();
    FetchBoxIdSims();
  }, [filters])


  return (
    <UpdateCreateQueryUi
      listProblem={listProblem}
      modalKey={modalKey}
      title={title}
      loading={loading}
      fields={fields}
      onSubmit={onSubmit}
      setFields={setFields}
      variant1={variant1}
      setVariant={setVariant}
      statusPass={statusPass}
      BoxIDAutoCompleted={BoxIDAutoCompleted}
      setFiltersBoxId={setFiltersBoxId}
      filtersBoxId={filtersBoxId}
      productAccessories={productAccessories}
      selectedDispatch={selectedDispatch}
      setSelectedDispatch={setSelectedDispatch}
      validation={validation}
      setvalidation={setvalidation}
      setSTATUSWiseData={setSTATUSWiseData}
      STATUSWiseData={STATUSWiseData}
      SetReplace={SetReplace}
      replace={replace}
    />
  );
};
export default memo(UpdateCreateQueryController);
