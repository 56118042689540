import { actions } from "../../utils/constants"
import { refreshToken ,accessToken, accessSimsToken, refreshSimsToken} from "../../utils/main";

const initialState = {
  data: {},

  isLoggedIn: false,

  sims_data:{}
};
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_USER:
      return { ...state, data: action.value, isLoggedIn: true };
    case actions.SIGN_IN: {
      accessToken.set(action.value.accessToken);

      refreshToken.set(action.value.refreshToken);
      return { ...state, data: action.value, isLoggedIn: true };
    }
    case actions.SET_USER_SIMS:
      return { ...state, sims_data: action.value, isLoggedIn: true };
    case actions.SIGN_IN_SIMS: {
      accessSimsToken.set(action.value.accessToken);

      refreshSimsToken.set(action.value.refreshToken);
      return { ...state, sims_data: action.value, isLoggedIn: true };
    }
    case actions.SIGN_OUT:
      return { ...state, data: {}, isLoggedIn: false };
    default:
      return { ...state };
  }
};
export default userReducer;
