import axios from "axios";
import { getHeaders, refreshSimsToken, refreshToken } from "../utils/main";
import endpoints from "./endpoints";
import endpointsims from "./endpointsims.api";

export const signInApi = async data => {
  const callResponse = await axios({
    url: endpoints.signIn,
    method: "post",
    data,
  })
    .then(response => response.data)
    .catch(err => (err.response ? ({ status: 0, response: err.response, code: err.response.status }) : {}));
  return callResponse;
};

export const resetTokenApi = async () => {
  const callResponse = await axios({
    url: endpoints.resetToken,
    method: "post",
    data: {
      refreshToken: refreshToken.get(),
    },
  })
    .then(response => response.data)
    .catch(err => (err.response ? ({ status: 0, response: err.response, code: err.response.status }) : {}));
  return callResponse;
};

export const signInSimsApi = async data => {
  const callResponse = await axios({
    url: endpointsims.signInSims,
    method: "post",
    data,
  })
    .then(response => response.data)
    .catch(err => (err.response ? ({ status: 0, response: err.response, code: err.response.status }) : {}));
  return callResponse;
};

export const resetTokenSimsApi = async () => {
  const callResponse = await axios({
    url: endpointsims.resetTokenSims,
    method: "post",
    data: {
      refreshToken: refreshSimsToken.get(),
    },
  })
    .then(response => response.data)
    .catch(err => (err.response ? ({ status: 0, response: err.response, code: err.response.status }) : {}));
  return callResponse;
};

export const resetPasswordUserApi = async (data,
) => {
  const callResponse = await axios({
    url: endpoints.resetpassword,
    method: "post",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


