import { Autocomplete, Box, Button, Checkbox, Chip, CircularProgress, Grid, IconButton, InputAdornment, ListItem, TextField, Typography } from "@mui/material";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import CustomInput from "../../components/inputs/CustomInputs";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import { PROBLEMS, SOLVED_STATUS, SELECT_PRODUCT_LABLE, SOLUTIONS, SOLVED_STATUS_LABLE, PROBLEMS_LABLE, USER_ROLES, STATUS } from "../../utils/constants";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import moment from "moment";
import { findObjectKeyByValue } from "../../utils/main";
import { getProdutApi } from "../../apis/product.api";
import { StyledSearchBar } from "../../components/inputs/SearchBar"
import AsyncDropDown from "../../components/inputs/AsyncDropDown";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxDesktop";
import { getsourceApi } from "../../apis/source.api";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { getTicketProblemsFetchApi } from "../../apis/repairing.api";
import { getBoxIdApi } from "../../apis/boxid.api";
import { Add, QrCodeScanner } from "@mui/icons-material";
import CreateProblemController from "../problem/CreateProblemController";
import { closeModal, openModal } from "../../store/actions/modalAction";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { FetchBoxIdformsims } from "../../apis/BoxIdformsims.api";
import ProfilePicture from "../../components/upload/ProfilePicture";
import FileInput from "../../components/upload/FileInput";
import QrScannerPopup from "../../components/Scanner/QrScannerPopup";


const CreateQueryUi = ({ listProblem, setArrr, handleClick, setFiltersBoxId, filtersBoxId, BoxIDAutoCompleted, arrr, title, modalKey, isUpdate, fields, setFields, loading, onSubmit, setVariant, variant1 }) => {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const dispatch = useDispatch()
    const id = 'problem';
    // const onAddProblem = () => {
    //     dispatch(openModal(
    //         <CreateProblemController
    //             handleClick={handleClick}
    //         />, "sm", false, id))
    // }
    const onScan = (data) => {
        setFields({ ...fields, err: '', box_id: data?.text })
    };
    const onQrBtnClick = () => {
        dispatch(
            openModal(
                <QrScannerPopup
                    setFields={setFields}
                    fields={fields}
                    onScan={onScan}
                    onClose={() => dispatch(closeModal("scanner"))}
                />,
                "sm",
                false,
                "scanner"
            )
        );
    };
    console.log(" device_photo", fields.device_photo)
    return (
        <>
            <CustomDialog
                id={`${isUpdate ? "Query" : "Query"}`}
                loading={loading}
                err={fields.err}
                onSubmit={onSubmit}
                title={`${isUpdate ? "Update" : "Create"} ${title}`}
                closeText="Close"
                confirmText={`${isUpdate ? "Update" : "Submit"}`}
            >
                {loading ? (
                    <CenteredBox><CircularProgress /></CenteredBox>
                ) : (
                    <>
                        <Box width={"90%"} display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                            <Box display={"flex"} flexDirection={"column"}>
                                <Typography>
                                    Ticket Number :{fields?.ticket_no}
                                </Typography>
                            </Box>
                            <Box display={"flex"} flexDirection={"column"}>
                                <Typography>
                                    Date :{moment(fields?.createdAt).format("DD/MM/YYYY")}
                                </Typography>
                            </Box>
                        </Box>
                        <Box mt={3} width={"100%"}>

                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                                <AsyncDropDown

                                    lazyFun={async (para) => await getProdutApi({ ...para, allStatus: true })}
                                    OptionComponent={({ option, ...rest }) => {
                                        return <ListItem {...rest}>{option.name}</ListItem >
                                    }}
                                    onChange={async (changedVal) => {
                                        setFields({ ...fields, product_id: changedVal ? changedVal._id : null, })
                                    }}
                                    titleKey={'name'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <CustomInput placeholder={"Select Product"} {...params} margin="none" />}
                                />
                            </PaddingBoxInDesktop>

                            <Box mt={3}>
                                <Typography variant="h4">
                                    Customer Details :
                                </Typography>
                            </Box>
                            <Box gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>

                                {/* <CustomInput
                                    // autoFocus={true}
                                    disabled={loading}
                                    value={fields.box_id}
                                    onChange={(e) => setFields({ ...fields, err: '', box_id: e.target.value })}
                                    type="text"
                                    label={" Box Id*"}
                                /> */}
                                <Box mt={1} width={"100%"}>
                                    <FormControl color="primary" fullWidth variant="outlined">
                                        <InputLabel color="primary" htmlFor="outlined-adornment-boxid">
                                            Enter Box Id*
                                        </InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-boxid"
                                            value={fields?.box_id || ""}
                                            onChange={(e) => setFields({ ...fields, err: '', box_id: e.target.value })}
                                            type="text"
                                            autoFocus={true}
                                            disabled={loading}
                                            endAdornment={<>
                                                {loading && <CircularProgress size={30} />}
                                                <InputAdornment position="end">
                                                    <IconButton onClick={onQrBtnClick} edge="end">
                                                        <QrCodeScanner />
                                                    </IconButton>
                                                </InputAdornment>
                                            </>}
                                            color="primary"
                                            label="Enter Box Id*"
                                        />
                                    </FormControl>
                                </Box>
                                <Autocomplete
                                    sx={{ width: "100%", color: "primary.main" }}
                                    onChange={(event, newValue) => {
                                        setFields({
                                            ...fields,
                                            err: "",
                                            box_id: newValue?.boxId ?? "",
                                            _id: newValue?._id ?? "",
                                            customer_phone: newValue?.customer_phone ?? "",
                                            customer_email: newValue?.customer_email ?? "",
                                            customer_name: newValue?.customer_name ?? "",
                                            customer_address: newValue?.customer_address ?? "",
                                            band_box_id: newValue?.band_box_id ?? ""
                                        });
                                    }}
                                    options={BoxIDAutoCompleted ?? []}
                                    getOptionLabel={(option) => option.boxId}
                                    renderTags={(tagValue, getTagProps) =>
                                        tagValue.map((option, index) => (
                                            <Chip label={option.boxId} {...getTagProps({ index })} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            margin="dense"
                                            {...params}
                                            label="Box Id*"
                                            variant="outlined"
                                            placeholder="Selected Box Id"
                                            onChange={(e) => setFiltersBoxId({ ...filtersBoxId, search: e.target.value })}
                                        />
                                    )}
                                />

                            </Box>
                            <Box mt={1} gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                <CustomInput
                                    autoFocus={true}
                                    disabled={loading}
                                    value={fields.customer_name}
                                    onChange={(e) => setFields({ ...fields, err: '', customer_name: e.target.value })}
                                    type="text"
                                    label={"Customer Name*"}
                                />
                                <CustomInput
                                    // autoFocus={true}
                                    disabled={loading}
                                    value={fields.customer_email}
                                    onChange={(e) => setFields({ ...fields, err: '', customer_email: e.target.value })}
                                    type="text"
                                    label={"Email"}
                                />

                            </Box>
                            <Box gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                <CustomInput
                                    // autoFocus={true}
                                    disabled={loading}
                                    value={fields.customer_phone}
                                    onChange={(e) => setFields({ ...fields, err: '', customer_phone: e.target.value })}
                                    type="number"
                                    label={" Phone No.*"}
                                />
                                <CustomInput
                                    // autoFocus={true}
                                    disabled={loading}
                                    value={fields.customer_address}
                                    onChange={(e) => setFields({ ...fields, err: '', customer_address: e.target.value })}
                                    type="text"
                                    label={"Address*"}
                                />


                                {/* <AsyncDropDown
                                    defaultVal={
                                        fields.customer_phone ? {

                                            _id: fields._id,
                                            cphone: fields.customer_phone,
                                        }
                                            : null
                                    }
                                    lazyFun={async (para) => await getBoxIdApi({ ...para, allStatus: true })}
                                    OptionComponent={({ option, ...rest }) => {
                                        return <ListItem {...rest}>{option.sold.cphone}</ListItem >
                                    }}
                                    onChange={async (changedVal) => {
                                        setFields({ ...fields, box_id: changedVal ? changedVal.boxId : null, _id: changedVal ? changedVal._id : null,customer_phone:changedVal ? changedVal.sold.cphone : null,customer_email:changedVal ? changedVal.sold.cemail : null,customer_name:changedVal ? changedVal.sold.cname : null, })
                                    }}
                                    titleKey={'cphone'}
                                    valueKey={"sold.soldBy"}
                                    InputComponent={(params) => <StyledSearchBar placeholder={"Phone No.*"} {...params} margin="none" />}
                                /> */}


                            </Box>
                            <DesktopDatePicker
                                label="Manufacturing Date"
                                disableFuture
                                inputFormat="DD/MM/yyyy"
                                renderInput={(props) => <CustomInput {...props} />}
                                autoFocus={true}
                                disabled={loading}
                                value={fields?.manufacturer_date ? moment(fields?.manufacturer_date) : null}
                                onChange={(val) => {
                                    if (val && val.isValid()) {
                                        const isoString = val.toISOString();
                                        setFields({
                                            ...fields,
                                            err: "",
                                            manufacturer_date: isoString,
                                        });
                                    } else {
                                        setFields({
                                            ...fields,
                                            err: "",
                                            manufacturer_date: "",
                                        });
                                    }
                                }}
                            />

                            <PaddingBoxInDesktop mt={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end", width: "100%" }}  >
                                <AsyncDropDown
                                    //  defaultVal={
                                    //     fields.status ? {
                                    //         _id: fields.status,
                                    //         name: fields._id,
                                    //     } : null
                                    // }
                                    lazyFun={async (para) => await getsourceApi({ ...para, allStatus: true })}
                                    OptionComponent={({ option, ...rest }) => {
                                        return <ListItem {...rest}>{option.title}</ListItem >
                                    }}
                                    onChange={async (changedVal) => {
                                        setFields({ ...fields, source_id: changedVal ? changedVal._id : null, })
                                    }}
                                    titleKey={'title'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <CustomInput placeholder={"Source*"} {...params} margin="none" />}
                                />
                            </PaddingBoxInDesktop>
                            <Box gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>

                                <Autocomplete
                                    sx={{ width: "100%", color: "primary.main" }}
                                    multiple
                                    onChange={(event, newValue) => {
                                        setFields({
                                            ...fields,
                                            err: "",
                                            problems: newValue.map((val) => val?.name),
                                        });
                                    }}
                                    options={listProblem ?? []}
                                    getOptionLabel={(option) => option.name}
                                    renderTags={(tagValue, getTagProps) =>
                                        tagValue.map((option, index) => (
                                            <Chip label={option.name} {...getTagProps({ index })} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            margin="dense"
                                            {...params}
                                            label="Problems*"
                                            variant="outlined"
                                            placeholder="Selected Problems"
                                        />
                                    )}
                                />
                                {fields?.problems?.map((item) =>
                                (item.toLowerCase() === "other" && <CustomInput
                                    disabled={loading}
                                    value={fields.other}
                                    onChange={(e) => setFields({ ...fields, err: '', other: e.target.value })}
                                    type="text"
                                    label={" Other Problems"}
                                />
                                ))}
                                {/* <Box onClick={onAddProblem} sx={{width:"7vw",height:"8vh",backgroundColor:"#1D013B",borderRadius:"10px",mt:1,display:"flex",justifyContent:"center",alignItems:"center",cursor:"pointer"}}>
                                    
                                    <Add sx={{width:"50px",color:"white",height:"50px"}}/>
                                </Box> */}
                            </Box>

                            <Box mt={4}>
                                <Box>
                                    <Typography variant="h3">
                                        Mode Of Query Solving :
                                    </Typography>
                                </Box>
                                <Grid mt={2} container spacing={3} justifyContent="space-between" alignItems="center" width="100%">
                                    <Grid item xs={4} sm={4} md={4}>
                                        <Button
                                            variant={fields.solved_in === "call" || variant1 === SOLUTIONS.CALL ? "contained" : "outlined"}
                                            onClick={(e) => {
                                                setVariant(SOLUTIONS.CALL);
                                                setFields({
                                                    ...fields, err: '', solved_in: SOLUTIONS.CALL, status: [
                                                        // ...fields.status,
                                                        {
                                                            status_role: STATUS.COMPLETED,
                                                            role: USER_ROLES.CRM_TEAM
                                                        },
                                                        {
                                                            status_role: STATUS.COMPLETED,
                                                            role: USER_ROLES.LD
                                                        }
                                                    ]
                                                })
                                            }
                                            }
                                            fullWidth>
                                            <Typography>Call</Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={4}>
                                        <Button onClick={() => {
                                            setVariant(SOLUTIONS.Videao_Call);
                                            setFields({
                                                ...fields, err: '', solved_in: SOLUTIONS.Videao_Call, status: [
                                                    // ...fields.status,
                                                    {
                                                        status_role: STATUS.COMPLETED,
                                                        role: USER_ROLES.CRM_TEAM
                                                    },
                                                    {
                                                        status_role: STATUS.COMPLETED,
                                                        role: USER_ROLES.LD
                                                    }
                                                ]
                                            })
                                        }}
                                            variant={fields.solved_in === "vc" || variant1 === SOLUTIONS.Videao_Call ? "contained" : "outlined"}
                                            fullWidth>
                                            <Typography>Video Call</Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={4}>
                                        <Button onClick={() => {
                                            setVariant(SOLUTIONS.REPAIR);
                                            setFields({
                                                ...fields, err: '', solved_in: SOLUTIONS.REPAIR, status: [
                                                    // ...fields.status,
                                                    {
                                                        status_role: STATUS.YET_TO_RECEIVED,
                                                        role: USER_ROLES.LD
                                                    }, {
                                                        status_role: STATUS.YET_TO_RECEIVED,
                                                        role: USER_ROLES.CRM_TEAM
                                                    }
                                                ]
                                            })
                                        }}
                                            variant={fields.solved_in === "repairing" || variant1 === SOLUTIONS.REPAIR ? "contained" : "outlined"}
                                            fullWidth>
                                            <Typography>Repair</Typography>
                                        </Button>
                                    </Grid>
                                </Grid>

                            </Box>
                            {variant1 === SOLUTIONS.REPAIR && <Box mt={4}>
                                <Grid xs={12} md={6} item>
                                    <Box>
                                        <FileInput
                                            onDelete={(filesToDelete) => {
                                                // setFields((prevFields) => {
                                                //     const updatedPhotos = { ...prevFields.device_photo }
                                                //     const filesArray = Array.isArray(filesToDelete) ? filesToDelete : [filesToDelete];
                                                //     filesArray.forEach((file) => {
                                                //         if (updatedPhotos[file.name]) {
                                                //             delete updatedPhotos[file.name];
                                                //         }
                                                //     });
                                                //     return {
                                                //         ...prevFields,
                                                //         err: "",
                                                //         device_photo: "",
                                                //     };
                                                // });
                                                setFields({
                                                    ...fields,
                                                    err: "",
                                                    device_photo: ""
                                                })
                                            }}
                                            onChange={(files) => {
                                                setFields({
                                                    ...fields,
                                                    err: "",
                                                    device_photo: files
                                                })
                                            }}
                                            defaults={fields?.device_photo ? [fields?.device_photo] : []}
                                            accept="image/*,.pdf"
                                            title="Upload Photo"
                                            subTitle="Only png,jpeg,jpg,pdf files are allowed! less than 1 MB"
                                        />
                                    </Box>
                                </Grid>
                            </Box>}
                        </Box>
                    </>
                )}
            </CustomDialog >
        </>
    );
}

export default memo(CreateQueryUi);



{/* {variant1 === SOLUTIONS.REPAIR && <PaddingBoxInDesktop mt={4} sx={{ display: "flex", flex: 1, justifyContent: "flex-end", width: "100%" }}  >
                                    <AsyncDropDown
                                        lazyFun={async (para) => await getTicketProblemsFetchApi({ ...para, allStatus: true })}
                                        OptionComponent={({ option, ...rest }) => {
                                            return <ListItem {...rest}>{option.name}</ListItem >
                                        }}
                                        defaultVal={
                                            fields.status ? {
                                                _id: fields.status._id,
                                                name: fields.status.name,
                                            } : null
                                        }
                                        onChange={async (changedVal) => {
                                            setFields({ ...fields, status: changedVal ? changedVal._id : null, })
                                        }}
                                        titleKey={'name'}
                                        valueKey={"_id"}
                                        InputComponent={(params) => <StyledSearchBar placeholder={"Repairing Ticket Problems*"} {...params} margin="none" />}
                                    />
                                </PaddingBoxInDesktop>}
                                {variant1 === SOLUTIONS.REPAIR &&
                                    <Box mt={3}>
                                        <Autocomplete

                                            options={SOLVED_STATUS}
                                            // defaultValue={
                                            //     fields.disibility_type
                                            //         ? fields.disibility_type?.map((item) =>
                                            //             DISIBILITY_TYPE.find((e) => item == e.value)
                                            //         )
                                            //         : []
                                            // }
                                            onChange={(event, newValue) => {
                                                setFields({
                                                    ...fields,
                                                    err: "",
                                                    solvedProblem: newValue.label,
                                                });
                                            }}
                                            disableCloseOnSelect
                                            getOptionLabel={(option) => option.label}
                                            renderOption={(props, option, { selected }) => {
                                                const { key, ...optionProps } = props;
                                                return (
                                                    <li key={key} {...optionProps}>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option.label}
                                                    </li>
                                                );
                                            }}
                                            style={{ width: "100%" }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Repairing status*" placeholder="" />
                                            )}
                                        />
                                    </Box>}

                                {variant1 === SOLUTIONS.REPAIR && <Box mt={1} gap={3} flexDirection={"row"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                    <CustomInput
                                        disabled={loading}
                                        value={fields.remarks}
                                        onChange={(e) => setFields({ ...fields, err: '', remarks: e.target.value })}
                                        type="text"
                                        label={"Remarks*"}
                                    />
                                </Box>} */}