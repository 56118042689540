import { useDispatch, useSelector } from "react-redux"
import useValidate from "../../store/hooks/useValidator"
import { useParams } from "react-router-dom"
import { closeModal } from "../../store/actions/modalAction"
import { memo, useCallback, useEffect, useMemo, useState } from "react"
import { callApiAction } from "../../store/actions/commonAction"
import { callSnackBar } from "../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../utils/constants"
// import CreateProblemUI from "./CreateProblemUI"
import { CreateProblemApi, ProblemFindByIdApi, UpdateProblemApi } from "../../apis/problem.api"
import CreateProductUi from "./CreateProductUi"
import { CreateProductApi, ProductFindByIdApi, UpdateProductApi } from "../../apis/product.api"

const CreateProductController = ({ callBack = () => { }, id, isModal, handleClick = () => { } }) => {
    const validate = useValidate()
    const dispatch = useDispatch()
    const title = "Product"

    const [loading, setLoading] = useState(false)


    const [fields, setFields] = useState({
        err: '',
        id,
        name: '',

    })
    const [originalDocument, setOriginalDocument] = useState({})


    const validationSchemaForCreate = useMemo(() => ([

        {
            required: true,
            value: fields.name,
            field: 'Product Name',
        }
    ]), [fields])

    const validationSchemaForUpdate = useMemo(() => ([

        {
            required: true,
            value: fields.name,
            field: 'Product Name',
        }
    ]), [fields])


    const createFunction = async () => {
        const validationResponse = validate(validationSchemaForCreate)

        if (validationResponse === true) {


            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await CreateProductApi(fields),
                    async (response) => {

                        setLoading(false)
                        callBack()
                        dispatch(callSnackBar("Product Created Successfully", SNACK_BAR_VARIETNS.suceess))
                        dispatch(closeModal("Product"))
                        handleClick();
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })

                        dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }

    const updateFunction = async () => {
        const validationResponse = validate(validationSchemaForUpdate)
        const updatedData = ({ id })

        for (let field in originalDocument) {

            if (originalDocument[field] && fields[field] && fields[field] != originalDocument[field]) {
                updatedData[field] = fields[field]
            }
        }

        if (validationResponse === true) {
            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await UpdateProductApi(updatedData),
                    async (response) => {
                        setLoading(false)
                        callBack()
                        dispatch(callSnackBar("Product Updated Successfully", SNACK_BAR_VARIETNS.suceess))
                        dispatch(closeModal("update"))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }



    const onSubmit = async (e) => {
        e.preventDefault()
        if (id)
            updateFunction()
        else
            createFunction()
    }



    const fetchById = (id) => {
        setLoading(true)
        dispatch(
            callApiAction(
                async () => await ProductFindByIdApi({ id }),
                async (response) => {
                    setFields({ ...fields, ...response })
                    setOriginalDocument(response)
                    setLoading(false)
                },
                (err) => {
                    setFields({ ...fields, err })
                    setLoading(false)

                }
            )
        )
    }

    useEffect(() => {
        if (id)
            fetchById(id)

    }, [id])


    return <CreateProductUi
        title={title}
        isUpdate={id}
        loading={loading}
        fields={fields}
        onSubmit={onSubmit}
        setFields={setFields}
        isModal={isModal}

    />
}
export default memo(CreateProductController)