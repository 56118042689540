import React from 'react'
import SignInController from './pages/signin/SignInController'
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Navigate } from 'react-router-dom'
import AppContainer from './components/layouts/common/AppContainer'
import PagenotFound from './components/layouts/PagenotFound'
import DashboardPageController from './pages/dashboard/DashboardPageController';
import { getDefaultRedirect } from './utils/routinghelper';
import NotAllowedComponent from './components/layouts/NotAllowedComponent';
import { USER_ROLES } from './utils/constants';
import DashboardPageControllerLD from './pages/Ld/DashboardPageControllerLD';
import DashboardPageControllerRepair from './pages/Repair/DashboardPageControllerRepair';
import { AddShoppingCart, Category, Construction, ContentPasteSearch, Groups2Outlined, LocalShipping, SupportAgent } from '@mui/icons-material';
import ListController from './pages/user/UserLIstController';
import ProblemListController from './pages/problem/ProblemListController';
import DashboardPageControllerAdmin from './pages/AdminPage/DashboardPageControllerAdmin';
import SourceListController from './pages/source/SourceListController';
import ProductListController from './pages/product/ProductListController';
// import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import ProductAccessoriesController from './pages/ProductAccessories/ProductAccessoriesController';

const loggedInPathElementRender = (login, allowed = [], permittedModule = [], Component, defaultRedirect, hideInPannel = false) => {

  const obj = {
    hideInPannel,
    element: Component,
  }
  if (!login) {
    obj['element'] = <Navigate replace to={'/sign-in'} />
  } else {

    let found = false
    for (let module of allowed) {
      for (let allowedModule of permittedModule) {
        if (module == allowedModule) {
          found = true
          break;
        }
      }
    }
    if (!found) {
      obj['hideInPannel'] = true
      obj['element'] = <NotAllowedComponent />
    }
  }
  return obj
}


const defineRoutes = (user) => {
  const allowedModules = [user.data.role] ?? []
  const defaultRedirect = getDefaultRedirect(allowedModules)

  return ([
    {
      path: "sign-in",
      element: !user.isLoggedIn ?
        <SignInController />
        :
        (((user.data.role === USER_ROLES.ADMIN) || (user.data.role === USER_ROLES.SUPER_ADMIN)) ?
          <Navigate replace to="/admin-page" />
          :
          user.data.role === USER_ROLES.CRM_TEAM ? (
            <Navigate replace to="/crm-dashboard" />
          ) : user.data.role === USER_ROLES.LD ? (
            <Navigate replace to="/ld-dashboard" />
          ) : user.data.role === USER_ROLES.REPAIR_TEAM ? (
            <Navigate replace to="/repair-dashboard" />
          ) : null
        ),
      hideInPannel: true

    },
    {
      path: "",
      element: user.isLoggedIn ? (
        ((user.data.role === USER_ROLES.ADMIN) || (user.data.role === USER_ROLES.SUPER_ADMIN)) ?
          <Navigate replace to="/admin-page" />
          :
          user.data.role === USER_ROLES.CRM_TEAM ? (
            <Navigate replace to="/crm-dashboard" />
          ) : user.data.role === USER_ROLES.LD ? (
            <Navigate replace to="/ld-dashboard" />
          ) : user.data.role === USER_ROLES.REPAIR_TEAM ? (
            <Navigate replace to="/repair-dashboard" />
          ) : null
      ) : (
        <Navigate replace to="/sign-in" />
      ),
      hideInPannel: true
    },
    {
      path: "admin-page",
      icon: <SupportAgent />,
      title: "Dashboard",
      ...loggedInPathElementRender(user.isLoggedIn, [USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN], allowedModules, <AppContainer ><DashboardPageControllerAdmin /></AppContainer>, defaultRedirect),
      hideInPannel: false,
    },
    {
      path: "crm-dashboard",
      icon: <SupportAgent />,
      title: "Dashboard",
      ...loggedInPathElementRender(user.isLoggedIn, [USER_ROLES.CRM_TEAM], allowedModules, <AppContainer ><DashboardPageController /></AppContainer>, defaultRedirect),
      hideInPannel: (user.data.role === USER_ROLES.ADMIN) || (user.data.role === USER_ROLES.SUPER_ADMIN) && true,
    },
    {
      path: "ld-dashboard",
      icon: <LocalShipping />,
      title: "Dashboard",
      ...loggedInPathElementRender(user.isLoggedIn, [USER_ROLES.LD], allowedModules, <AppContainer ><DashboardPageControllerLD /></AppContainer>, defaultRedirect),
      hideInPannel: (user.data.role === USER_ROLES.ADMIN) || (user.data.role === USER_ROLES.SUPER_ADMIN) && true,
    },
    {
      path: "repair-dashboard",
      icon: <Construction />,
      title: "Dashboard",
      ...loggedInPathElementRender(user.isLoggedIn, [USER_ROLES.REPAIR_TEAM], allowedModules, <AppContainer ><DashboardPageControllerRepair /></AppContainer>, defaultRedirect),
      hideInPannel: (user.data.role === USER_ROLES.ADMIN) || (user.data.role === USER_ROLES.SUPER_ADMIN) && true,
    },
    {
      path: "users",
      icon: <Groups2Outlined />,
      title: "Users",
      ...loggedInPathElementRender(user.isLoggedIn, [USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN], allowedModules, <AppContainer ><ListController /></AppContainer>, defaultRedirect),
      hideInPannel: false,

    },
    {
      path: "problem",
      icon: <ContentPasteSearch />,
      title: "Problem",
      ...loggedInPathElementRender(user.isLoggedIn, [USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN], allowedModules, <AppContainer ><ProblemListController /></AppContainer>, defaultRedirect),
      hideInPannel: false,
    },
    {
      path: "source",
      icon: <ScreenSearchDesktopIcon />,
      title: "Source",
      ...loggedInPathElementRender(user.isLoggedIn, [USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN], allowedModules, <AppContainer ><SourceListController /></AppContainer>, defaultRedirect),
      hideInPannel: false,
    },
    {
      path: "product",
      icon: <AddShoppingCart />,
      title: "Product",
      ...loggedInPathElementRender(user.isLoggedIn, [USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN], allowedModules, < AppContainer > <ProductListController /></AppContainer >, defaultRedirect),
      hideInPannel: false,
    },
    {
      path: "accessories",
      icon: <Category />,
      title: "Product Accessories",
      ...loggedInPathElementRender(user.isLoggedIn, [USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN], allowedModules, <AppContainer ><ProductAccessoriesController /></AppContainer>, defaultRedirect),
      hideInPannel: false,
    },
    {
      path: "*",
      hideInPannel: true,
      element: !user.isLoggedIn ? <Navigate replace to={"/sign-in"} /> : <PagenotFound />,
    },

  ])

}

export default defineRoutes