
import { memo, useEffect, useMemo, useRef, useState } from "react";
import useValidate from "../../store/hooks/useValidator";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { closeModal } from "../../store/actions/modalAction";
import { useParams } from "react-router-dom";
import { PROBLEMS_LABLE, SNACK_BAR_VARIETNS, SOLUTIONS, STATUS, USER_ROLES } from "../../utils/constants";
import { callSnackBar } from "../../store/actions/snackbarAction";
import CreateQueryUi from "./CreateQueryUi";
import { CreateRepairingTicketApi, getRepairingTicketStatusByIdApi, RepairingTicketCreateApi } from "../../apis/repairing.api";
import axios from "axios";
import { statusAction, statusActionFilters, statusCountActionFilters } from "../../store/actions/statusAction";
import { FetchProblemApi } from "../../apis/problem.api";
import { fetchNotificationAction } from "../../store/actions/notificationAction";
import moment from "moment";
import { getBoxIdApi } from "../../apis/boxid.api";
import { FetchBoxIdformsims } from "../../apis/BoxIdformsims.api";

const CreateQueryController = ({ statusPass, fieldTicket, setTickets, id }) => {

  const [fields, setFields] = useState({
    _id: "",
    err: "",
    createdAt: fieldTicket?.createdAt ? fieldTicket?.createdAt : "",
    ticket_no: fieldTicket?.ticketNo ? fieldTicket?.ticketNo : "",
    product_id: "",
    box_id: "",
    customer_phone: "",
    customer_name: "",
    customer_email: "",
    source_id: "",
    trakingId: "",
    problems: [],
    repairingAmount: [],
    solved_in: "",
    customer_address: "",
    other: "",
    status: [],
    manufacturer_date: ""
  });
  const [variant1, setVariant] = useState();

  const updateTicketApi = RepairingTicketCreateApi;
  const [loading, setLoading] = useState(false);
  const validate = useValidate();
  const dispatch = useDispatch();
  const modalKey = 'Query';
  const title = "Query";
  const [arrr, setArrr] = useState([])

  const validationSchemaForUpdate = useMemo(
    () => [
      {
        required: true,
        value: fields.ticket_no,
        field: "name",
      },
      {
        required: true,
        value: fields.createdAt,
        field: "name",
      },
      {
        required: true,
        value: fields.product_id,
        field: "product_id",
      },
      {
        // required: true,
        value: fields.customer_email,
        field: "Email",
      },
      {
        required: true,
        value: fields.box_id,
        field: "Box Id",
      },
      {
        required: true,
        value: fields.customer_phone,
        field: "Phone",
      },
      {
        required: true,
        value: fields.customer_name,
        field: "Customer Name",
      },
      {
        required: true,
        value: fields.source_id,
        field: "Source",
      },
      {
        required: true,
        value: fields.problems.length,
        field: "Problems",
      },
      {
        required: fields?.problems.some((item) => item.toLowerCase() === "other"),
        value: fields.other,
        field: "other",
      },
      {
        required: true,
        value: fields.solved_in,
        field: "Mode Of Query Solving",
      },
      {
        required: true,
        value: fields.customer_address,
        field: "customer_address",
      },
      {
        required: true,
        value: fields.manufacturer_date,
        field: "Manufacturer Date",
      },
    ],
    [fields]
  );

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ['cName'],
    role: "",
    sort: "createdAt",
    sortDirection: -1,

    // ...statusData.statusReducer.filters
  });
  const statusData = useSelector((state) => state)

  const onSubmit = async (e) => {
    e.preventDefault();
    const validationResponse = validate(validationSchemaForUpdate)
    if (validationResponse === true) {
      setLoading(true)
      dispatch(callApiAction(
        async () => await updateTicketApi({ ...fields }),
        (response) => {

          setLoading(false)
          dispatch(fetchNotificationAction());
          dispatch(callSnackBar("Query Created successfully", SNACK_BAR_VARIETNS.suceess));
          dispatch(closeModal("Query"))
          dispatch(statusActionFilters(filters));
          dispatch(statusCountActionFilters(-1));
        }, (err) => {
          setLoading(false);
          setFields({ ...fields, err });
        }
      ))
    } else {
      setFields({ ...fields, err: validationResponse })
      setLoading(false)
    }
  };

  const [listProblem, setlistProblem] = useState()
  const fetchList = () => {
    dispatch(
      callApiAction(
        async () => await FetchProblemApi({}),
        (response) => {
          setlistProblem(response?.result);
        },
        (err) => {
          console.log("Error", err)
        }
      )
    );
  };

  const [boxId, setBoxId] = useState();
  const [boxIdformsims, setBoxIdformsims] = useState();

  const [filtersBoxId, setFiltersBoxId] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    sort: "createdAt",
    sortDirection: -1,
  });

  const FetchBoxId = () => {
    dispatch(
      callApiAction(
        async () => await getBoxIdApi({ ...filtersBoxId, searchable: ["boxId", "sold.cphone"] }),
        (response) => {

          if (response?.result?.length < 1) {
            console.log("Error")
          } else {
            setBoxId(response?.result?.map((item) => ({
              boxId: item.boxId,
              _id: item._id,
              customer_phone: item.sold?.cphone || "",
              customer_email: item.sold?.cemail || "",
              customer_name: item.sold?.cname || "",
              customer_address: item.sold?.caddress || "",
              band_box_id: item.convertedProducts?.[0] || ""
            }))
            );

          }
        },
        (err) => {
          console.log("Error", err)
        }
      )
    );
  };

  const FetchBoxIdSims = () => {
    dispatch(
      callApiAction(
        async () => await FetchBoxIdformsims({ ...filtersBoxId, searchable: ["benificiaris.device_id"] }),
        (response) => {
          if (response?.result?.length < 1) {
            console.log("Error")
          } else {
            setBoxIdformsims(response?.result?.map((item) => ({
              boxId: item.boxId,
              _id: item._id,
              customer_phone: item?.beneficiary_id?.phone,
              customer_email: item?.beneficiary_id?.email,
              customer_name: item?.beneficiary_id?.name,
              customer_address: item?.beneficiary_id?.address,
              band_box_id: ""
            })
            ))
          }
        },
        (err) => {
          console.log("Error", err)
        }
      )
    );
  };

  const BoxIDAutoCompleted = boxIdformsims?.length > 0 ? boxIdformsims?.concat(boxId) : boxId;

  useEffect(() => {
    fetchList();
  }, [filters])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (filtersBoxId.search) {
        FetchBoxId();
        FetchBoxIdSims();
      }
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [filtersBoxId.search])

  useEffect(() => {
    FetchBoxId();
    FetchBoxIdSims();
  }, [])

  return (
    <CreateQueryUi
      setArrr={setArrr}
      arrr={arrr}
      listProblem={listProblem}
      modalKey={modalKey}
      title={title}
      loading={loading}
      fields={fields}
      onSubmit={onSubmit}
      setFields={setFields}
      variant1={variant1}
      setVariant={setVariant}
      BoxIDAutoCompleted={BoxIDAutoCompleted}
      setFiltersBoxId={setFiltersBoxId}
      filtersBoxId={filtersBoxId}
    />
  );
};
export default memo(CreateQueryController);
