export const mode = "production"; //process.env.REACT_APP_APP_MODE;
let domain = "";

switch (mode) {
    case "ip":
        domain = "http://10.10.81.35:8000/";
        break;
    case "local":
        domain = "http://localhost:8000/";
        break;
    case "development":
        domain = "https://social-impact-backend-production.up.railway.app/";
        break;
    case "production":
        domain = "https://social-impact-backend-production.up.railway.app/";
        break;
    default:
        domain = "http://localhost:8000/";
}

export default {
    root: domain,
    signInSims: `${domain}api/auth/login`,
    resetTokenSims: `${domain}api/auth/reset-token`,
    fetchUserByTokenSims: `${domain}api/user/fetch-by-token`,

    fetchBoxIdformsims: `${domain}fetch-beneficiary`,
}