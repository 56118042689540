import { Box, Grid, IconButton } from '@mui/material';
import React from 'react';
import CustomInput from '../../components/inputs/CustomInputs';
import DeleteIcon from '@mui/icons-material/Delete';

const AddProductAccessories = ({ fields, setFields, loading }) => {
    const handleDeleteItem = (index) => {
        const updatedItems = fields.product_accessories.filter((_, inx) => inx !== index);
        setFields({ ...fields, err: "", product_accessories: updatedItems });
    };

    return (
        <Box mt={2}>
            {fields?.product_accessories?.length > 0 &&
                fields.product_accessories.map((val, inx) => {
                    if (!val) return null;
                    return (
                        <Box key={inx} display="flex" alignItems="center">
                            <CustomInput
                                disabled={loading}
                                value={val.name}
                                onChange={(e) => {
                                    const updatedproduct_accessories = [...fields.product_accessories];
                                    updatedproduct_accessories[inx] = {
                                        ...updatedproduct_accessories[inx],
                                        name: e.target.value,
                                    };
                                    setFields({ ...fields, err: "", product_accessories: updatedproduct_accessories });
                                }}
                                type="text"
                                label={"Product Accessories Name*"}
                            />
                            <IconButton
                                color="error"
                                onClick={() => handleDeleteItem(inx)}
                                aria-label="delete"
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    );
                })}
        </Box>
    );
};

export default AddProductAccessories;
