import { Box, Button, CircularProgress, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import CustomInput from "../../components/inputs/CustomInputs"
import { memo } from "react"
import { Close } from "@mui/icons-material"
import SubmitButton from "../../components/button/SubmitButton"
import { getProdutApi } from "../../apis/product.api"
import { ListItem } from "@mui/material"
import { StyledSearchBar } from "../../components/inputs/SearchBar"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import AddProductAccessories from "./AddProductAccessories"

const ProductAccessoriesCreateUI = ({ title, isUpdate, fields, setFields, loading, onSubmit, isModal, handleAreaModalClose }) => {
    return <>
        {!isModal &&
            <CustomDialog
                id={`${isUpdate ? "update" : "Product"}`}
                loading={loading}
                err={fields.err}
                onSubmit={onSubmit}
                title={`${isUpdate ? "Update" : "Create"} ${title}`}
                closeText="Close"
                confirmText={`${isUpdate ? "Update" : "Create"}`}
            >
                {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                    <>
                        {<AsyncDropDown
                            defaultVal={
                                fields.product?._id ? {
                                    _id: fields.product?._id,
                                    name: fields.product?.name,
                                } : null
                            }
                            lazyFun={async (para) => await getProdutApi({ ...para, allStatus: true })}
                            OptionComponent={({ option, ...rest }) => {
                                return <ListItem {...rest}>{option.name}</ListItem >
                            }}
                            onChange={async (changedVal) => {
                                setFields({ ...fields,err:"", product: changedVal._id ? changedVal._id : null, })
                            }}
                            titleKey={'name'}
                            valueKey={"_id"}
                            InputComponent={(params) => <CustomInput placeholder={"Select Product"} {...params} margin="none" />}
                        />}
                        {<Box >
                            <AddProductAccessories fields={fields} setFields={setFields}  />
                        </Box>}
                        {<Box mt={2} width={"100%"} display={"flex"} justifyContent={"center"}>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    setFields((data) => {
                                        let arr = [...data.product_accessories];
                                        arr.push({
                                            name: "",
                                        });
                                        return { ...data, product_accessories: arr };
                                    });
                                }}

                            >
                                <Typography variant="h6">Add Product Aaccessories</Typography>
                            </Button>
                        </Box>}
                    </>
                }
            </CustomDialog>
        }
    </>
}
export default memo(ProductAccessoriesCreateUI)